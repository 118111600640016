import { Middleware } from '@driscollsinc/one-ring';

const reviewFileMapping = [
    Middleware.CreateMapping('FileId', 'FileId'),
    Middleware.CreateMapping('ReviewedBy', 'ReviewedBy'),
    Middleware.CreateMapping('ReviewComment', 'ReviewComment'),
    Middleware.CreateMapping('Status', 'Status')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ReviewFile', 'POST')) {
        Middleware.RegisterMapping('ReviewFile', 'POST', reviewFileMapping);
    }
}

const reviewFile = {
    ConfigureMiddleware
};

export default reviewFile;
