import cloneDeep from 'lodash/cloneDeep';
import { SFVolume, SFAcre, SFDaily, SFWarehouse, SFWeekly } from './dbTableColumns';

const getColumnsForUploadType = (tableName, { notNullColumns, requiredColumns, optionalColumns }, excelColumnNameOverride = null) => {
    const tableColumnsClone = cloneDeep(tableName);
    Object.keys(tableColumnsClone).forEach((currentField) => {
        const notNull = notNullColumns.includes(currentField) ? true : false;
        const isRequired = notNullColumns.includes(currentField) || requiredColumns.includes(currentField) ? true : false;
        const isExcluded = !(
            notNullColumns.includes(currentField) ||
            requiredColumns.includes(currentField) ||
            optionalColumns.includes(currentField)
        )
            ? true
            : false;
        if (excelColumnNameOverride && excelColumnNameOverride.hasOwnProperty(currentField))
            tableColumnsClone[currentField] = {
                ...tableColumnsClone[currentField],
                notNull,
                isRequired,
                isExcluded,
                ...excelColumnNameOverride[currentField]
            };
        else tableColumnsClone[currentField] = { ...tableColumnsClone[currentField], notNull, isRequired, isExcluded };
    });

    return tableColumnsClone;
};

const AnnualBudgetVolume = getColumnsForUploadType(SFVolume, {
    notNullColumns: [
        'year',
        'producingPoolWeek',
        'producingWeekendDate',
        'commodity',
        'fieldType',
        'submissionWeekEndDate',
        'shortPoolWeek',
        'site',
        'planningGroup',
        'dataType'
    ],
    requiredColumns: ['producingAreaCode', 'brand', 'budgetPound', 'budgetCrates', 'kiloCalculation'],
    optionalColumns: [
        'estgroup',
        'producingAreaName',
        'dealSeason',
        'varietyCode',
        'varietyName',
        'mediaType',
        'applicationTreatment',
        'treatmentStartDate',
        'timing',
        'plantType',
        'isInGround',
        'treatmentMonth'
    ]
});
const AnnualBudgetAcres = getColumnsForUploadType(SFAcre, {
    notNullColumns: [
        'year',
        'commodity',
        'fieldType',
        'submissionWeekEndDate',
        'site', //Site in Excelsheet
        'planningGroup',
        'dataType'
    ],
    requiredColumns: ['producingAreaCode', 'brand', 'acreageQuantity', 'hectareCalculation'],
    optionalColumns: [
        'producingAreaName',
        'dealSeason',
        'varietyCode',
        'varietyName',
        'mediaType',
        'applicationTreatment',
        'treatmentStartDate',
        'treatmentMonth',
        'timing',
        'plantType',
        'isInGround',
        'protection'
    ]
});
const QuarterlyPlantedAcres = getColumnsForUploadType(SFAcre, {
    notNullColumns: [
        'year',
        'commodity',
        'fieldType',
        'submissionWeekEndDate',
        'planningGroup',
        'site', //Site in Excelsheet
        'dataType'
    ],
    requiredColumns: ['producingAreaCode', 'acreageQuantity', 'hectareCalculation'],
    optionalColumns: [
        'producingAreaName',
        'dealSeason',
        'varietyCode',
        'varietyName',
        'mediaType',
        'applicationTreatment',
        'timing',
        'plantType',
        'protection',
        'brand',
        'treatmentMonth',
        'isInGround',
        'growerNumber',
        'growerName',
        'ranchNumber',
        'ranchName',
        'microclimate'
    ]
});
const WeeklyRegionalForecast = getColumnsForUploadType(SFVolume, {
    notNullColumns: [
        'year',
        'producingPoolWeek',
        'producingWeekendDate',
        'commodity',
        'fieldType',
        'producingAreaCode',
        'site',
        'planningGroup',
        'dataType'
    ],
    requiredColumns: ['brand', 'budgetPound', 'budgetCrates', 'kiloCalculation', 'submissionWeekEndDate', 'shortPoolWeek', 'offset'],
    optionalColumns: [
        'estgroup',
        'producingAreaName',
        'dealSeason',
        'varietyCode',
        'varietyName',
        'mediaType',
        'applicationTreatment',
        'timing',
        'plantType',
        'plantingYear',
        'plantingMonth',
        'isInGround',
        'treatmentMonth'
    ]
});
const FourteenDayDailyEstimates = getColumnsForUploadType(
    SFDaily,
    {
        notNullColumns: [
            'year',
            'producingPoolWeek',
            'producingWeekendDate',
            'commodity',
            'fieldType',
            'producingAreaCode',
            'site',
            'planningGroup',
            'dataType'
        ],
        requiredColumns: ['brand', 'budgetPound', 'budgetCrates', 'kiloCalculation', 'submissionDay', 'shortPoolWeek', 'producingDay'],
        optionalColumns: ['estgroup', 'producingAreaName', 'dealSeason', 'applicationTreatment', 'mediaType', 'plantType']
    },
    {
        fieldType: { excelColumnName: 'FIELD TYPE' },
        estgroup: { excelColumnName: 'EST GROUP' },
        dealSeason: { excelColumnName: 'DEAL SEASON' },
        brand: { excelColumnName: 'BRAND CATEGORY' },
        producingAreaName: { excelColumnName: 'PRODUCING AREA NAME' },
        producingAreaCode: { excelColumnName: 'PRODUCING AREA NUMBER' },
        budgetPound: { excelColumnName: 'LBS' },
        budgetCrates: { excelColumnName: 'CRATES' }
    }
);
const QuarterlyReforecastVolume = getColumnsForUploadType(SFVolume, {
    notNullColumns: [
        'year',
        'producingPoolWeek',
        'producingWeekendDate',
        'commodity',
        'fieldType',
        'producingAreaCode',
        'submissionWeekEndDate',
        'shortPoolWeek',
        'site',
        'planningGroup',
        'reforecastQuarter',
        'dataType'
    ],
    requiredColumns: ['brand', 'budgetPound', 'budgetCrates', 'kiloCalculation'],
    optionalColumns: [
        'estgroup',
        'producingAreaName',
        'dealSeason',
        'varietyCode',
        'varietyName',
        'mediaType',
        'applicationTreatment',
        'treatmentStartDate',
        'timing',
        'plantType',
        'isInGround',
        'treatmentMonth'
    ]
});
const QuarterlyReforecastAcres = { ...QuarterlyPlantedAcres };
const ThirteenWeekSupplyByItem = getColumnsForUploadType(SFWeekly, {
    notNullColumns: [
        'site',
        'siteName',
        'commodity',
        'labelGroup',
        'item',
        'itemDescription',
        'brand',
        'planningGroup',
        'fieldType',
        'forecastStartDate',
        'packagingForecast',
        'salesForecastDate',
        'model'
    ],
    requiredColumns: [
        'week0',
        'week1',
        'week2',
        'week3',
        'week4',
        'week5',
        'week6',
        'week7',
        'week8',
        'week9',
        'week10',
        'week11',
        'week12',
        'week13',
        'total'
    ],
    optionalColumns: []
});
const ThirteenWeekSupplyByUnit = getColumnsForUploadType(
    SFWeekly,
    {
        notNullColumns: ['model', 'unit', 'commodity', 'fieldType', 'brand', 'site', 'warehouseNbr', 'planningGroup', 'forecastStartDate'],
        requiredColumns: [
            'week0',
            'week1',
            'week2',
            'week3',
            'week4',
            'week5',
            'week6',
            'week7',
            'week8',
            'week9',
            'week10',
            'week11',
            'week12',
            'week13'
        ],
        optionalColumns: []
    },
    {
        brand: {
            dbColumnName: 'Brand'
        }
    }
);
const WarehouseAssignment = getColumnsForUploadType(SFWarehouse, {
    notNullColumns: ['model', 'unit', 'commodity', 'fieldType', 'site', 'warehouseNbr', 'planningGroup', 'item', 'itemDescription'],
    requiredColumns: [
        'forecastStartDate',
        'week0',
        'week1',
        'week2',
        'week3',
        'week4',
        'week5',
        'week6',
        'week7',
        'week8',
        'week9',
        'week10',
        'week11',
        'week12',
        'week13'
    ],
    optionalColumns: []
});

// We can remove these berrytype too but need to make corresponding changes in code files 
const columnNames = {
    Results: {
        AnnualBudgetVolume: {
            BLACK: { ...AnnualBudgetVolume },
            BLUE: { ...AnnualBudgetVolume },
            RASP: { ...AnnualBudgetVolume },
            STRAW: { ...AnnualBudgetVolume }
        },
        AnnualBudgetAcres: {
            BLACK: { ...AnnualBudgetAcres },
            BLUE: { ...AnnualBudgetAcres },
            RASP: { ...AnnualBudgetAcres },
            STRAW: { ...AnnualBudgetAcres }
        },
        QuarterlyPlantedAcres: {
            BLACK: { ...QuarterlyPlantedAcres },
            BLUE: { ...QuarterlyPlantedAcres },
            RASP: { ...QuarterlyPlantedAcres },
            STRAW: { ...QuarterlyPlantedAcres }
        },
        WeeklyRegionalForecast: {
            BLACK: { ...WeeklyRegionalForecast },
            BLUE: { ...WeeklyRegionalForecast },
            RASP: { ...WeeklyRegionalForecast },
            STRAW: { ...WeeklyRegionalForecast }
        },
        FourteenDayDailyEstimates: {
            BLACK: { ...FourteenDayDailyEstimates },
            BLUE: { ...FourteenDayDailyEstimates },
            RASP: { ...FourteenDayDailyEstimates },
            STRAW: { ...FourteenDayDailyEstimates }
        },
        QuarterlyReforecastVolume: {
            BLACK: { ...QuarterlyReforecastVolume },
            BLUE: { ...QuarterlyReforecastVolume },
            RASP: { ...QuarterlyReforecastVolume },
            STRAW: { ...QuarterlyReforecastVolume }
        },
        QuarterlyReforecastAcres: {
            BLACK: { ...QuarterlyReforecastAcres },
            BLUE: { ...QuarterlyReforecastAcres },
            RASP: { ...QuarterlyReforecastAcres },
            STRAW: { ...QuarterlyReforecastAcres }
        },
        ThirteenWeekSupplyByItem: {
            BLACK: { ...ThirteenWeekSupplyByItem },
            BLUE: { ...ThirteenWeekSupplyByItem },
            RASP: { ...ThirteenWeekSupplyByItem },
            STRAW: { ...ThirteenWeekSupplyByItem }
        },
        ThirteenWeekSupplyByUnit: {
            BLACK: { ...ThirteenWeekSupplyByUnit },
            BLUE: { ...ThirteenWeekSupplyByUnit },
            RASP: { ...ThirteenWeekSupplyByUnit },
            STRAW: { ...ThirteenWeekSupplyByUnit }
        },
        WarehouseAssignment: {
            BLACK: { ...WarehouseAssignment },
            BLUE: { ...WarehouseAssignment },
            RASP: { ...WarehouseAssignment },
            STRAW: { ...WarehouseAssignment }
        }
    }
};

export default columnNames;
