import { Middleware } from '@driscollsinc/one-ring';

const emailNotificationMapping = [
    Middleware.CreateMapping('admin', 'admin'),
    Middleware.CreateMapping('masterPlanner', 'masterPlanner'),
    Middleware.CreateMapping('national', 'national'),
    Middleware.CreateMapping('regional', 'regional'),
    Middleware.CreateMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserEmailNotification', 'GET')) {
        Middleware.RegisterMapping('UserEmailNotification', 'GET', emailNotificationMapping);
    }
}

const emailNotificationType = {
    ConfigureMiddleware
};

export default emailNotificationType;
