import { Middleware } from '@driscollsinc/one-ring';

const userProducingAreaPostMapping = [
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('UserId', 'UserId'),
    Middleware.CreateMapping('ProducingAreas', 'ProducingAreas'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const userProducingAreaGetMapping = [
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('UserId', 'UserId'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('ProducingAreaCode', 'ProducingAreaCode'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('data', 'data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserProducingArea', 'GET')) {
        Middleware.RegisterMapping('UserProducingArea', 'GET', userProducingAreaGetMapping);
        Middleware.RegisterMapping('UserProducingArea', 'POST', userProducingAreaPostMapping);
    }
}

const userProducingArea = {
    ConfigureMiddleware
};

export default userProducingArea;
