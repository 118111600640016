import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import SvgIcons from '../components/SvgIcons';

const styles = (theme) => ({
    selectedFileType: {
        color: DuThemeUtilities.DefaultColors.text.white.main + ' !important',
        '&.blue': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.blue + ' !important'
        },
        '&.purple': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.purple + ' !important'
        },
        '&.green': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green + ' !important'
        },
        '&.pink': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.pink + ' !important'
        },
        '&.red': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red + ' !important'
        }
    },
    fileTypeBtn: {
        margin: '0 auto -1px !important',
        display: 'block',
        width: 'calc(100% + 1px) !important',
        height: 143,
        '&.blue': {
            color: DuThemeUtilities.DefaultColors.primary.blue
        },
        '&.purple': {
            color: DuThemeUtilities.DefaultColors.primary.purple
        },
        '&.green': {
            color: DuThemeUtilities.DefaultColors.primary.green
        },
        '&.pink': {
            color: DuThemeUtilities.DefaultColors.primary.pink
        },
        '&.red': {
            color: DuThemeUtilities.DefaultColors.primary.red
        },
        '& .MuiButton-label': {
            fontSize: '1.25rem'
        }
    },
    noBottom: {
        borderBottom: 'none',
        marginTop: 0
    },
    noPadding: {
        margin: 0,
        '& > div': {
            padding: '0 !important'
        }
    }
});

// update this map to change icon or icon color on the upload types
const UPLOAD_TYPES_STYLES_ICON_MAP = {
    ThirteenWeekSupplyByItem: {
        svgIcon: 'WeeklyItem',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.pink,
        buttonTextColor: 'pink',
        text_1: '13-Week Supply',
        text_2: 'Forecast By Item'
    },
    ThirteenWeekSupplyByUnit: {
        svgIcon: 'WeeklyItem',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.pink,
        buttonTextColor: 'pink',
        text_1: '13-Week Supply',
        text_2: 'Forecast By Unit'
    },
    WeeklyRegionalForecast: {
        svgIcon: 'ForecastVolume',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.green,
        buttonTextColor: 'green',
        text_1: 'Weekly Regional',
        text_2: 'Forecast'
    },
    FourteenDayDailyEstimates: {
        svgIcon: 'DailyForecast',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.green,
        buttonTextColor: 'green',
        text_1: '14-Day Daily',
        text_2: 'Estimates'
    },
    QuarterlyPlantedAcres: {
        svgIcon: 'PlantedAcres',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.blue,
        buttonTextColor: 'blue',
        text_1: 'Quarterly Planted',
        text_2: 'Acres'
    },
    QuarterlyReforecastVolume: {
        svgIcon: 'Reforecast',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.blue,
        buttonTextColor: 'blue',
        text_1: 'Quarterly Reforecast',
        text_2: 'Volume'
    },
    QuarterlyReforecastAcres: {
        svgIcon: 'Reforecast',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.blue,
        buttonTextColor: 'blue',
        text_1: 'Quarterly Reforecast',
        text_2: 'Acres'
    },
    WarehouseAssignment: {
        svgIcon: 'WeeklyWarehouse',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.red,
        buttonTextColor: 'red',
        text_1: 'Warehouse',
        text_2: 'Assignment'
    },
    AnnualBudgetVolume: {
        svgIcon: 'BudgetVolume',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.purple,
        buttonTextColor: 'purple',
        text_1: 'Annual Budget',
        text_2: 'Volume'
    },
    AnnualBudgetAcres: {
        svgIcon: 'BudgetAcres',
        svgIconFillColorActive: DuThemeUtilities.DefaultColors.text.white.light,
        svgIconFillColorInActive: DuThemeUtilities.DefaultColors.primary.purple,
        buttonTextColor: 'purple',
        text_1: 'Annual Budget',
        text_2: 'Acres'
    }
};

class UploadType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileType: this.props.enabledUploadType || 0,
            allowedUploadTypes: [],
            allowedUploadTypeStrings: []
        };
    }

    componentDidMount() {
        this.setAllowedTypes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allowedUploadTypes.length !== this.props.allowedUploadTypes.length) {
            this.setAllowedTypes();
        }
        if (this.props.enabledUploadType !== prevProps.enabledUploadType) this.setState({ fileType: 0 });
    }

    setAllowedTypes = () => {
        let allowedUploadTypes = Object.keys(UPLOAD_TYPES_STYLES_ICON_MAP).filter((ItemUploadType) => {
            return this.props.allowedUploadTypes.includes(ItemUploadType);
        });
        let allowedUploadTypeStrings = allowedUploadTypes.map((ItemUploadType) => {
            return UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].svgIcon;
        });

        if (!this.isAllowedUploadTypeListEqual(allowedUploadTypeStrings)) {
            this.setState({
                allowedUploadTypes,
                allowedUploadTypeStrings
            });
        }
    };

    isAllowedUploadTypeListEqual = (allowedUploadTypeStrings) => {
        if (allowedUploadTypeStrings.length !== this.state.allowedUploadTypeStrings.length) {
            return false;
        } else {
            for (var i = 0; i < allowedUploadTypeStrings.length; i++) {
                if (allowedUploadTypeStrings[i] !== this.state.allowedUploadTypeStrings[i]) {
                    return false;
                }

                return true;
            }
        }
    };

    handleFileTypeClick = (fileType) => {
        this.setState({
            fileType
        });

        (
            this.props.onClick ||
            (() => {
                console.error('Upload Type component is not connected!');
            })
        )(fileType);
    };

    isUploadTypeDisabled = (uploadType) => (this.props.enabledUploadType ? uploadType !== this.props.enabledUploadType : false);

    render() {
        let { classes } = this.props;
        let { allowedUploadTypes } = this.state;
        return (
            <div className={`row ${classes.noPadding}`}>
                {allowedUploadTypes.map((ItemUploadType) => (
                    <div
                        className="col-xs-6 col-md-3"
                        key={UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].text_1 + UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].text_2}
                    >
                        <DrcButton
                            isSecondary
                            fullWidth
                            hard
                            className={`${classes.fileTypeBtn} ${this.state.fileType === ItemUploadType ? classes.selectedFileType : ''} ${
                                UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].buttonTextColor
                            }`}
                            onClick={() => {
                                this.handleFileTypeClick(ItemUploadType);
                            }}
                            disabled={this.isUploadTypeDisabled(ItemUploadType)}
                        >
                            <SvgIcons
                                name={UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].svgIcon}
                                height={30}
                                fill={
                                    this.state.fileType !== ItemUploadType
                                        ? UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].svgIconFillColorInActive
                                        : UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].svgIconFillColorActive
                                }
                            />
                            {UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].text_1}
                            <br />
                            {UPLOAD_TYPES_STYLES_ICON_MAP[ItemUploadType].text_2}
                        </DrcButton>
                    </div>
                ))}
            </div>
        );
    }
}

export default withStyles(styles)(UploadType);
