import { Middleware } from '@driscollsinc/one-ring';

const SfDailyFileDataMappings = [
    Middleware.CreateMapping('FileNbr', 'FileNbr'),
    Middleware.CreateMapping('Year', 'Year'),
    Middleware.CreateMapping('ProducingPoolWeek', 'ProducingPoolWeek'),
    Middleware.CreateMapping('ProducingWeekendDate', 'ProducingWeekendDate'),
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('FieldType', 'FieldType'),
    Middleware.CreateMapping('ESTGroup', 'ESTGroup'),
    Middleware.CreateMapping('ProducingAreaName', 'ProducingAreaName'),
    Middleware.CreateMapping('ProducingAreaCode', 'ProducingAreaCode'),
    Middleware.CreateMapping('DealSeason', 'DealSeason'),
    Middleware.CreateMapping('VarietyName', 'VarietyName'),
    Middleware.CreateMapping('VarietyCode', 'VarietyCode'),
    Middleware.CreateMapping('ApplicationTreatment', 'ApplicationTreatment'),
    Middleware.CreateMapping('TreatmentStartDate', 'TreatmentStartDate'),
    Middleware.CreateMapping('Timing', 'Timing'),
    Middleware.CreateMapping('MediaType', 'MediaType'),
    Middleware.CreateMapping('BrandCategory', 'BrandCategory'),
    Middleware.CreateMapping('LBS', 'LBS'),
    Middleware.CreateMapping('Crates', 'Crates'),
    Middleware.CreateMapping('Kilos', 'Kilos'),
    Middleware.CreateMapping('WeekOfYear', 'WeekOfYear'),
    Middleware.CreateMapping('Offset', 'Offset'),
    Middleware.CreateMapping('SubmissionDay', 'SubmissionDay'),
    Middleware.CreateMapping('ShortPoolWeek', 'ShortPoolWeek'),
    Middleware.CreateMapping('PlantType', 'PlantType'),
    Middleware.CreateMapping('GrowingRegion', 'GrowingRegion'),
    Middleware.CreateMapping('Region', 'Region'),
    Middleware.CreateMapping('Site', 'Site'),
    Middleware.CreateMapping('PlanningGroup', 'PlanningGroup'),
    Middleware.CreateMapping('ProducingDay', 'ProducingDay'),
    Middleware.CreateMapping('DataType', 'DataType'),
    // Middleware.CreateMapping('ReviewComments', 'ReviewComments'),
    // Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    // Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    // Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    // Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('Results', 'Results'),
    Middleware.CreateArrayMapping('FileData', 'FileData')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SFDaily', 'GET')) {
        Middleware.RegisterMapping('SFDaily', 'GET', SfDailyFileDataMappings);
    }
}

const SfDailyFileMaster = {
    ConfigureMiddleware
};

export default SfDailyFileMaster;
