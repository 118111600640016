import { Middleware } from '@driscollsinc/one-ring';

const SfWeeklyFileDataMappings = [
    Middleware.CreateMapping('FileNbr', 'FileNbr'),
    Middleware.CreateMapping('Model', 'Model'),
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('Site', 'Site'),
    Middleware.CreateMapping('SiteName', 'SiteName'),
    Middleware.CreateMapping('PlanningGroup', 'PlanningGroup'),
    Middleware.CreateMapping('Unit', 'Unit'),
    Middleware.CreateMapping('FieldType', 'FieldType'),
    Middleware.CreateMapping('Brand', 'Brand'),
    Middleware.CreateMapping('WarehouseNbr', 'WarehouseNbr'),
    Middleware.CreateMapping('LabelGroup', 'LabelGroup'),
    Middleware.CreateMapping('Item', 'Item'),
    Middleware.CreateMapping('ItemDescription', 'ItemDescription'),
    Middleware.CreateMapping('ForecastStartDate', 'ForecastStartDate'),
    Middleware.CreateMapping('PackagingForecast', 'PackagingForecast'),
    Middleware.CreateMapping('SalesForecast', 'SalesForecast'),
    Middleware.CreateMapping('Week1', 'Week1'),
    Middleware.CreateMapping('Week2', 'Week2'),
    Middleware.CreateMapping('Week3', 'Week3'),
    Middleware.CreateMapping('Week4', 'Week4'),
    Middleware.CreateMapping('Week5', 'Week5'),
    Middleware.CreateMapping('Week6', 'Week6'),
    Middleware.CreateMapping('Week7', 'Week7'),
    Middleware.CreateMapping('Week8', 'Week8'),
    Middleware.CreateMapping('Week9', 'Week9'),
    Middleware.CreateMapping('Week10', 'Week10'),
    Middleware.CreateMapping('Week11', 'Week11'),
    Middleware.CreateMapping('Week12', 'Week12'),
    Middleware.CreateMapping('Week13', 'Week13'),
    Middleware.CreateMapping('Week14', 'Week14'),
    Middleware.CreateMapping('Total', 'Total'),
    Middleware.CreateMapping('Results', 'Results'),
    Middleware.CreateArrayMapping('FileData', 'FileData')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('sfWeekly', 'GET')) {
        Middleware.RegisterMapping('sfWeekly', 'GET', SfWeeklyFileDataMappings);
    }
}

const SfWeeklyFileMaster = {
    ConfigureMiddleware
};

export default SfWeeklyFileMaster;
