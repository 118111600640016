import DataValidation from './DrcDataValidation';
const ALLOWED_IN_REQUIRED = [0, 'Null'];

export const zeroAndNullCheck = (input, columnName) => {
    const stingWithZeroRegex = RegExp(/^0*$/);
    if (stingWithZeroRegex.test(input)) input = parseInt(input);

    if (ALLOWED_IN_REQUIRED.includes(input)) {
        return { funcName: 'zeroAndNullCheck', message: `Zero Or Null Not allowed for "${columnName}"` };
    }
    return null;
};

export const berryTypeCheck = (berryType) => (input, columnName) => {
    if (input && input.toLowerCase() !== berryType.toLowerCase()) {
        return { funcName: 'berryTypeCheck', message: `Commodity does not match with berry type selected during Upload. Error in "${columnName}"` };
    }
    return null;
};

export const matchDbLength = (length) => (input, columnName) => {
    //this will match for the length required by a field in db
    if (input && input.length > length) {
        return { funcName: 'matchDbLength', message: `Length is greater than ${length} in "${columnName}"` };
    }
    return null;
};

const dealSeasonValidation = (input = '', columnName, params) => {
    // allow blanks
    if (!input && !input.trim()) {
        return null;
    }

    let inputArr = input.toString().split('-');

    for (let i = 0; i < inputArr.length; i++)
        if (!DataValidation.isValid(inputArr[i].trim(), 'number')) {
            return { funcName: 'Number', message: `Bad number provided in column "${columnName}"` };
        }

    if (params) {
        let min = (params || {}).min || 0;
        let max = (params || {}).max || 255;

        if (input < min) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too small` };
        } else if (input > max) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too large` };
        }
    }

    return null;
};

export const checkValidValues = (input = '', columnName, list) => {
    return DataValidation.ValidateDataInList(input, columnName, list);
};

const validationTypes = {
    siteCode: [(input, columnName) => (!input ? null : DataValidation.ValidateLength(input, columnName, { min: 3, max: 3 }))],
    varietyCode: [
        (input, columnName) => {
            // TODO: Make this chnage in style guide
            if (input !== 0 && input !== 'Null' && !input) return DataValidation.ValidateLength(input, columnName, { min: 3, max: 3 });
        }
    ] /* allowing 0 and Null */,
    year: [(input, columnName) => DataValidation.ValidateNumber(input, columnName, { min: 1000, max: 2999 })], //Validating YEAR from 0 to 2999
    dealSeason: [(input, columnName) => dealSeasonValidation(input, columnName, { min: 2000, max: 2999 })],
    // submissionWeekEndDate: [(input, columnName) => DataValidation.ValidateDate(input, columnName)],
    // treatmentStartDate: [(input, columnName) => DataValidation.ValidateDate(input, columnName)],
    producingPoolWeek: [(input, columnName) => DataValidation.ValidateNumber(input, columnName, { min: 1000, max: 2999 })],
    // producingWeekend: [(input, columnName) => DataValidation.ValidateDate(input, columnName)],
    budgetPound: [(input, columnName) => DataValidation.ValidateDecimal(input, columnName)],
    budgetCrates: [(input, columnName) => DataValidation.ValidateDecimal(input, columnName)],
    kiloCalculation: [(input, columnName) => DataValidation.ValidateDecimal(input, columnName)],
    shortPool: [(input, columnName) => DataValidation.ValidateNumber(input, columnName, { min: 0, max: 53 })],
    site: [
        (input, columnName) => {
            if (input !== 0 && input !== 'Null' && !input) {
                DataValidation.ValidateLength(input, columnName, { min: 0, max: 3 });
            }
        }
    ] //NOTE:Earlier site was having only isRequired validation but as per SF-197 it cannot be "Null" so for now validating 0-3 length, ideally it should only be 3.Please confirm
};

export default validationTypes;