import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction, setErrorsAction, showLoadingScreenAction, hideLoadingScreenAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcPanel, DrcIconButton, DrcCollapsiblePanel, DrcDataGrid } from '@driscollsinc/driscolls-react-components';
import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../services/api';
import getFileDataMapping from '../data/fileDataMapping';
import berryTypes from '../data/berryTypes';
import { notify } from '../actions/NotificationAction';
import { withOktaAuth } from '@okta/okta-react';
import startCase from 'lodash/startCase';
import { Redirect } from 'react-router-dom';
import { getUploadTypeByDataType } from '../data/json/forecastTypes';
import { setUserFileData, clearUserFileData } from '../actions/UserActions';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { formatFileToDisplay, setGridColumns, getAllowedFields, formatDbColumnNameToUiName } from '../data/formater';

const pageTitle = 'View File';

const styles = (theme) => ({
    roundedCorners: {
        borderRadius: '.5rem',
        marginTop: '.5rem',
        boxShadow: '8px 8px 6px -6px #777'
    },
    fileDetails: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fileDetail: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '30%',
        margin: '0.6rem 0.6rem 0.6rem 0'
    },
    fileDetailKey: {
        fontWeight: 'bold',
        marginRight: '.5rem'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flex: 1,
        marginBottom: '1rem'
    },
    actionButton: {
        width: '15rem',
        marginRight: '2rem'
    },
    gridStyles: {
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '8px 8px 6px -6px #777'
        },
        '& .p-datatable-scrollable-body': {
            height: '390px'
        },
        '& .p-inputtext.p-component.p-column-filter': {
            borderRadius: '4px'
        },
        '& .p-dropdown label.p-dropdown-label': {
            textOverflow: 'ellipsis',
            width: '88%'
        },
        '& .p-datatable-tbody tr td': {
            overflow: 'visible'
        }
    }
});

// this has more work then was required.
// for this sprint i am just removing colorcodes and submit button.
// This component was suppose to provide inline edit. thats why submit button. we will break this into seperate edit and view.
class EditViewFile extends React.Component {
    state = {
        allowedFields: [],
        col: [],
        fileDetails: [
            { key: 'Upload Type', value: '' },
            { key: 'BerryType', value: '' },
            { key: 'Status', value: '' },
            { key: 'ReviewedBy', value: '' },
            { key: 'Created Date', value: '' },
            { key: 'Modified Date', value: '' },
            { key: 'ReviewComment', value: '' }
        ],
        pageSize: 200,
        pagesLoaded: []
    };

    pageLoadInprogress = false;

    async componentDidMount() {
        this.loadData(this.state.pageSize);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
        this.uploadType = this.props.match.params.uploadType;
        this.berryType = berryTypes.getCodeByDisplayName(this.props.match.params.berryType);

        if (this.props.uploadedFiles.length && this.props.userFileData) {
            if (this.props.userFileData.FileNbr) {
                this.setFileHeaderDetails();
            }

            if (this.props.userFileData.FileData.length) {
                this.makeDataForGrid();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadedFiles.length && prevProps.userFileData !== this.props.userFileData) {
            if (this.props.userFileData.FileNbr) {
                this.setFileHeaderDetails();
            }

            if (this.props.userFileData.FileData.length) {
                this.makeDataForGrid();
            }
        }
    }

    setFileHeaderDetails = () => {
        let file = this.props.uploadedFiles.find((fileItem) => fileItem.Id.toString() === this.props.userFileData.FileNbr.toString());
        if (!file) {
            return;
        }

        let fileDetails = this.state.fileDetails.map((e) => {
            e.value = startCase((file[e.key] || '').toLowerCase());

            if (e.key === 'Upload Type') {
                e.value = startCase(getUploadTypeByDataType(file.DataType) || file.DataType);
            }

            if (e.key === 'ReviewedBy') {
                e.value = startCase(file.ReviewedBy);
            }

            if (e.key === 'Created Date') {
                e.value = DuDateUtilities.FormatDateFromIso(file.CreatedDateTime);
            }

            if (e.key === 'Modified Date') {
                e.value = DuDateUtilities.FormatDateFromIso(file.ModifiedDateTime);
            }

            return e;
        });

        this.setState({ fileDetails });
    };

    makeDataForGrid = () => {
        let berryName = berryTypes.getDisplayNameByCode(this.berryType).toUpperCase();
        let uploadTypeColumnNames = this.props.columnNames[this.uploadType][berryName];
        let allowedFields = getAllowedFields(uploadTypeColumnNames);
        let columns = setGridColumns(allowedFields);
        this.setState({ col: columns });
    };

    // Paging Function
    loadData = async (pageSize) => {
        if (this.pageLoadInprogress) {
            return;
        }

        this.props.showLoadingScreenAction();

        this.pageLoadInprogress = true;
        let pagesLoaded = [...this.state.pagesLoaded];

        let totalPages = this.props.userFileData.FileData.length ? Math.round(this.props.userFileData.FileData.length / this.state.pageSize) : 0;

        let { fileId, uploadType } = this.props.match.params;

        let mappingName = getFileDataMapping(uploadType);

        try {
            let token = await this.props.authState?.accessToken;

            // why? becasue mule fails to get large numbe rof records
            let numberOfCalls = Math.ceil(pageSize / this.state.pageSize);
            let allcalls = [];
            let data = {
                TotalRowCount: 0,
                Records: []
            };

            for (let i = 0; i < numberOfCalls; i++) {
                // only load pages that are ot loaded yet
                if (!pagesLoaded.includes(totalPages + i + 1)) {
                    pagesLoaded.push(totalPages + i + 1);

                    allcalls.push(
                        Middleware.Send(
                            mappingName,
                            token,
                            APIEndPoints.FILE_BY_ID_PAGED(fileId, totalPages + i + 1, this.state.pageSize),
                            'GET',
                            {},
                            {
                                showLoadingScreen: () => { /* code */ },
                                hideLoadingScreen: () => { /* code */ }
                            }
                        )
                    );
                }
            }

            if (!allcalls.length) {
                this.pageLoadInprogress = false;
                this.props.hideLoadingScreenAction();
                return;
            }

            let responses = await Promise.all(allcalls);

            responses.forEach((response, indx) => {
                if (indx === 0) {
                    data = response;
                } else {
                    data.Records = [...data.Records, ...response.Records];
                }
            });

            if (!data.Records) {
                this.pageLoadInprogress = false;
                this.props.hideLoadingScreenAction();
                return;
            }

            this.setState({ pagesLoaded });
            let berryName = berryTypes.getDisplayNameByCode(this.berryType).toUpperCase();
            let uploadTypeColumnNames = this.props.columnNames[this.uploadType][berryName];
            let formatedHeaderFile = formatDbColumnNameToUiName(data.Records, uploadTypeColumnNames);
            this.props.setUserFileData(formatedHeaderFile, fileId, data.TotalRowCount);
            this.pageLoadInprogress = false;
            this.props.hideLoadingScreenAction();
        } catch (err) {
            console.log(err);
            this.showError('An Error Occurred Getting File Records', err);
            this.pageLoadInprogress = false;
            this.props.hideLoadingScreenAction();
        }
    };

    showError = (title, errors) => this.props.setErrorsAction(title, errors);

    goBack = () => {
        this.props.clearUserFileData();
        this.props.history.push('/MyFiles/');
    };

    getRowCount = () => {
        if (this.props.userFileData.totalRowCount < this.state.pageSize) {
            return Math.ceil(this.props.userFileData.totalRowCount / 2);
        }

        return this.state.pageSize;
    };

    render() {
        let { isMasterDataInitialized, userFileData, uploadedFiles, classes } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (!uploadedFiles.length) {
            return <Redirect to="/MyFiles/" />;
        }

        return (
            <DrcMain>
                <div className="row">
                    <div className={`col-xs-12 ${classes.buttonContainer}`}>
                        <div className={classes.actionButton}>
                            <DrcIconButton style={{ width: '100%' }} onClick={this.goBack} keyBinding="Shift+b">
                                <KeyboardBackspaceIcon style={{ marginRight: '1rem' }} />
                                Back
                            </DrcIconButton>
                        </div>
                    </div>

                    <div className="col-xs-12">
                        <DrcCollapsiblePanel header="File Details">
                            <div className={classes.fileDetails}>
                                {this.state.fileDetails.map((e) => (
                                    <div className={classes.fileDetail}>
                                        <div className={classes.fileDetailKey}>{e.key}:</div>
                                        <div>{e.value}</div>
                                    </div>
                                ))}
                            </div>
                        </DrcCollapsiblePanel>
                    </div>
                    <div className="col-xs-12">
                        {userFileData.FileData.length > 0 && this.state.col.length > 0 && (
                            <DrcDataGrid
                                rows={formatFileToDisplay(userFileData.FileData)}
                                columns={this.state.col}
                                pageSize={this.state.pageSize}
                                rowsToLoadPerScroll={this.getRowCount()}
                                totalRecords={userFileData.totalRowCount}
                                resultCount={userFileData.totalRowCount}
                                loadData={this.loadData}
                                lazy={true}
                                virtualScroll={true}
                                gridStyles={classes.gridStyles}
                                height={Math.max(window.innerHeight - 290, 300)}
                                columnResizeMode={'expand'}
                                resizableColumns={true}
                                virtualRowHeight={70}
                            />
                        )}
                    </div>
                    {/* NOTE: Do Not Delete this commented code */}
                </div>
                {/* )} */}
                {userFileData && userFileData.FileData && userFileData.FileData.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <DrcPanel style={{ width: '100%', maxWidth: '100%' }} className={classes.roundedCorners}>
                            No data To Display
                        </DrcPanel>
                    </div>
                )}
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        gridEditable: state.userReducer.gridEditable,
        mdrData: state.masterReducer.mdrData,
        columnNames: state.masterReducer.columnNames,
        uploadedFiles: state.userReducer.uploadedFiles,
        userFileData: state.userReducer.userFileData
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    notify: (message, isError) => dispatch(notify(message, isError)),
    showLoadingScreenAction: (data) => dispatch(showLoadingScreenAction(data)),
    hideLoadingScreenAction: (data) => dispatch(hideLoadingScreenAction(data)),
    setUserFileData: (data, fileId, totalRowCount) => dispatch(setUserFileData(data, fileId, totalRowCount)),
    clearUserFileData: () => dispatch(clearUserFileData()),
    setErrorsAction: (title, error) => dispatch(setErrorsAction(title, error))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditViewFile)));
