import React from 'react';
import { Redirect } from 'react-router-dom';
import { Middleware } from '@driscollsinc/one-ring';
import LoggingUtilities from './LoggingUtilities';
import APIEndPoints from '../services/api';
import userRole from './roleUtilities';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;

const masterDataCalls = [
    {
        name: 'UserBerryTypeRoles',
        endpoint: APIEndPoints.USER_BERRY_TYPES,
        weight: 2,
        returnFunction: (data, props) => {
            props.setUserBerryRoles(data.BerryTypes);
        },
        method: 'GET'
    },
    {
        name: 'UserUploadTypeRoles',
        endpoint: APIEndPoints.USER_UPLOAD_TYPES,
        weight: 2,
        returnFunction: (data, props) => {
            props.setUserForecastRoles(data.ForecastTypes);
        },
        method: 'GET'
    },
    {
        name: 'ForecastTypes',
        endpoint: '',
        weight: 1,
        returnFunction: (data, props) => {
            props.setUploadTypes(data.Results);
        }
    },
    {
        name: 'ColumnNames',
        endpoint: '',
        weight: 1,
        returnFunction: (data, props) => {
            props.setColumnNames(data.Results);
        }
    },
    {
        name: 'Ranches',
        endpoint: APIEndPoints.RANCHES_LIST,
        weight: 3,
        returnFunction: (data, props) => {
            props.setRanches(data.Data);
        }
    },
    {
        name: 'ProducingAreas',
        endpoint: APIEndPoints.MASTER_DATA_PRODUCING_AREAS,
        weight: 1,
        returnFunction: (data, props) => {
            props.setProducingAreas(data.Data);
        }
    },
    {
        name: 'Variety',
        endpoint: APIEndPoints.VARIETY,
        weight: 3,
        returnFunction: (data, props) => {
            props.setVariety(data.Data);
        }
    },
    {
        name: 'Warehouse',
        endpoint: APIEndPoints.WAREHOUSE,
        weight: 3,
        returnFunction: (data, props) => {
            props.setWarehouse(data.Data);
        }
    },
    {
        name: 'UserEmailNotification',
        endpoint: '',
        weight: 1,
        returnFunction: (data, props) => {
            props.setEmailNotificationRole(data.Data);
        }
    },
    {
        name: 'ReceivingArea',
        endpoint: APIEndPoints.RECEIVING_AREA,
        weight: 3,
        returnFunction: (data, props) => {
            props.setReceivingArea(data.Data);
        }
    },
    {
        name: 'PlanningGroup',
        endpoint: APIEndPoints.PLANNING_GROUP,
        weight: 3,
        returnFunction: (data, props) => {
            props.setPlanningGroup(data.Data);
        }
    },
    {
        name: 'Brand',
        endpoint: APIEndPoints.BRAND,
        weight: 3,
        returnFunction: (data, props) => {
            props.setBrand(data.Data);
        }
    },
    {
        name: 'UserProducingArea',
        endpoint: APIEndPoints.USER_PRODUCING_AREA,
        weight: 2,
        returnFunction: (data, props) => {
            let producingAreas = data.data.Results.filter((e) => e.Active);
            props.setRegionalUserProducingAreas(producingAreas);
        }
    }
];

const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours

let lastMasterDataLoadTime = null;
let hasLoggedMasterDataWarning = false;
let hasLoggedMasterDataInfo = false;
let redirectLocationReducerFunction = () => {
    //code here
};

let getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    // TODO: debug columnNamesMapping
    let apiCalls = [];

    props.setIsAdmin(DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || []));
    props.setUserRoleName(userRole(token));
    masterDataCalls.forEach((call) => {
        let middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);

        middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

let checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

let redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Redirect to="/InitializeApplication/" />;
};

let registerMasterDataReducerVariables = (setMasterDataRedirectLocation, setMasterDataInitialized) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables
};

export default MasterDataUtilities;
