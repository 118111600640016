import fileStatuses from '../data/fileStatuses';

class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static BASE_AWS = window.config.AWS_API_BASE_ADDRESS;
    static MASTER_DATA = '/MasterData/';
    static APPLICATION = '/Application/';

    static MASTER_DATA_APPLICATIONS = this.BASE + this.MASTER_DATA + 'Applications';
    static MASTER_DATA_APPLICATION_TYPES = this.BASE + this.MASTER_DATA + 'ApplicationTypes';
    static MASTER_DATA_APPLICATION_ASSOCIATIONS = this.BASE + this.MASTER_DATA + 'ApplicationAssociations';
    static MASTER_DATA_WORK_STATUSES = this.BASE + this.MASTER_DATA + 'WorkStatuses';
    static MASTER_DATA_WORK_TYPES = this.BASE + this.MASTER_DATA + 'WorkTypes';
    static MASTER_DATA_STATS = this.BASE + this.MASTER_DATA + 'Stats';
    static MASTER_DATA_PRODUCING_AREAS = this.BASE + 'producingareas';
    static ALL_MASTER_DATA = this.BASE + this.MASTER_DATA + 'All';
    static REJECT_FILE = this.BASE + 'files/review';
    static RANCHES_LIST = this.BASE + 'ranches';
    static APPROVE = this.BASE + 'files/review';
    static MY_FILES = this.BASE + 'files';
    static WITHDRAW_FILE = this.BASE + 'files/review';
    static USER_BERRY_TYPES = this.BASE + 'berrytypes';
    static USER_UPLOAD_TYPES = this.BASE + 'forecasttypes';
    static REVIEW_API_PREFIX = this.BASE + 'files/review';
    static VARIETY = this.BASE + 'variety';
    static WAREHOUSE = this.BASE + 'warehouse';
    static RECEIVING_AREA = this.BASE + 'receivingarea';
    static PLANNING_GROUP = this.BASE + 'planninggroup';
    static BRAND = this.BASE + 'brand';
    static ROLE_TO_BERRY = this.BASE + 'users';
    static USER_PRODUCING_AREA = this.BASE + 'users/producingareacodes';
    static ROLE_TO_UPLOAD = this.BASE + 'roleupload?rolename=';
    static APP_ROLES = this.BASE + 'roles';
    static DAX_INTEGRATION_RETRY = this.BASE_AWS + 'Reprocess/';

    static REVIEW_FILES = () => {
        let query = '';
        let statusList = [fileStatuses.inReview, fileStatuses.readyForReview, fileStatuses.reSubmitted];

        statusList.forEach((status) => {
            status = encodeURI(status);

            if (query === '') {
                query = query + `status=${status}`;
            } else {
                query = query + `&status=${status}`;
            }
        });

        return this.BASE + 'files/review?' + query;
    };

    static FILE_BY_ID_NO_PAGE = (fileId) => {
        return `${this.MY_FILES}/${fileId}`;
    };

    static FILE_BY_ID_PAGED = (fileId, startPage, pageSize) => {
        return `${this.MY_FILES}/${fileId}?startPage=${startPage}&pageSize=${pageSize}`;
    };

    static REVIEW_FILE_BY_ID_NO_PAGE = (fileId) => {
        return `${this.REVIEW_API_PREFIX}/${fileId}`;
    };

    static REVIEW_FILE_BY_ID_PAGED = (fileId, startPage, pageSize) => {
        return `${this.REVIEW_API_PREFIX}/${fileId}?startPage=${startPage}&pageSize=${pageSize}&status=In%20Review`;
    };
}

export default APIEndPoints;
