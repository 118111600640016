import { Middleware } from '@driscollsinc/one-ring';

const WarehouseMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Warehouse', 'GET')) {
        Middleware.RegisterMapping('Warehouse', 'GET', WarehouseMapping);
    }
}

const warehouse = {
    ConfigureMiddleware
};

export default warehouse;
