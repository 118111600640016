export const stringRegEx = RegExp(/^(?!.*[.,?/#!@$%^&*;:{}=\-_`'"~()])/);
export const floatRegEx = RegExp(/^[0-9]*[.]?[0-9]{0,2}$/);
export const numberRegEx = RegExp(/^[0-9]*$/);
export const fpCodeRegEx = RegExp(/^.*$/);

export const formatFloat = (value) => {
    if (Number.isNaN(Number(value))) {
        return value;
    }

    return Number(value).toFixed(2);
};

//Footprint Line Details Form validation
const validateAddLine = (isDisabled, lineDetails) => {
    Object.values(lineDetails).forEach((lineValues) => {
        if (
            !lineValues.Length ||
            !lineValues.Width ||
            !lineValues.Height ||
            !lineValues.GrossWeight ||
            !lineValues.NetWeight ||
            !lineValues.UnitQuantity ||
            !lineValues.Threshold ||
            !lineValues.UnitOfMeasure
        ) {
            isDisabled = true;
        }
    });
    return isDisabled;
};

const valdidateSubmit = (lineDetailsEntries) => {
    let isNotAllowed = false;
    let errorArray = [];

    // Must have two detail lines
    if (lineDetailsEntries.length < 2) {
        isNotAllowed = true;
        errorArray.push('Footprints must have a header line and at least two detail lines.');
    }

    let stockCount = 0,
        receiveCount = 0,
        caseCount = 0,
        palletCount = 0;

    lineDetailsEntries.forEach((row) => {
        if (row.IsStockUOM) {
            stockCount++;
        }
        if (row.IsCase) {
            caseCount++;
        }
        if (row.IsReceiveUOM) {
            receiveCount++;
        }
        if (row.IsPallet) {
            palletCount++;
        }
    });

    if (stockCount !== 1) {
        isNotAllowed = true;
        errorArray.push(
            'Stocking UOM flag must be set for the smallest Unit Of Measure in which this item is stored. It can only be set on a single detail line.'
        );
    }
    if (caseCount < 1) {
        isNotAllowed = true;
        errorArray.push('Each footprint must have one detail line with the UOM Type Case set.');
    }
    if (receiveCount < 1) {
        isNotAllowed = true;
        errorArray.push('Each footprint must have one detail line with the Receive UOM flag set.');
    }
    if (palletCount !== 1) {
        isNotAllowed = true;
        errorArray.push('Each footprint must have one, and only one, detail line of UOM Type Pallet.');
    }

    return { isNotAllowed, errorArray, showErrorIcon: true };
};

export const FPFormatter = {
    FRUIT: RegExp(/^(STRAW|RASP|BLACK|BLUE)(_)[0-9]+(X)[0-9]+(OZ|PT|G|LB|BULK)(_)[0-9]+$/),
    PKG: fpCodeRegEx,
    PLANT: fpCodeRegEx,
    MISC: fpCodeRegEx
};

export const FPFormatterTip = {
    FRUIT: 'COMMODITY_CountxWeightUOM_CaseCt',
    PKG: 'Select...',
    PLANT: 'Select...',
    MISC: 'Select...'
};

export const LineDetailsValidation = {
    ValidateAddLine: validateAddLine,
    ValdidateSubmit: valdidateSubmit
};

export const getMissingFields = (data = [], columns, fileType, berryType) => {
    // return same type i.e array
    if (data.length === 0) {
        return [];
    }

    // this stops upload page to fail in case columnNames doesnt have a mapping
    if (!columns[fileType] || !fileType || !berryType) {
        return Object.keys(data[0]) || [];
    }

    let requiredFields = Object.values(columns[fileType][berryType])
        .filter((column) => column.isRequired)
        .map((column) => column.excelColumnName);
    let fieldsInExcel = (Object.keys(data[0]) || []).map((c) => {
        return c;
    });

    let fieldsNotPresent = difference(requiredFields, fieldsInExcel) || [];

    return fieldsNotPresent;
};

const difference = (required, excel) => {
    let missingColumns = [];

    required.forEach((col) => {
        if (!excel.includes(col)) {
            missingColumns.push(col);
        }
    });

    return missingColumns;
};
