import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { setPageTitleAction, setErrorsAction, showLoadingScreenAction, hideLoadingScreenAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcDataGrid, DrcButton, DrcTooltip, DrcDialog } from '@driscollsinc/driscolls-react-components';
import { notify } from '../actions/NotificationAction';
import berryTypes from '../data/berryTypes';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { getInstance, uploadToS3 } from '../data/s3Upload';
import { DuThemeUtilities, DuAuthenticationUtilities, DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import DataValidation from '../data/DrcDataValidation';
import { setColumnNames } from '../actions/MasterActions';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { DATA_TYPE_AND_FREQUENCY_MAPPING } from '../data/json/forecastTypes';
import { formatRowData, getDateFromExcelDateStamp, DECIMAL_FORMAT, booleanColumns } from '../data/formater';
import status from '../data/fileStatuses';
import { berryTypeCheck } from '../data/validationTypes';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import cloneDeep from 'lodash/cloneDeep';

const pageTitle = 'Upload Review';

const styles = (theme) => ({
    stat: {
        marginLeft: '0 !important'
    },
    selected: {
        textDecoration: 'underline'
    },
    floatRight: {
        float: 'right'
    },
    badValue: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    goodValue: {
        color: DuThemeUtilities.DefaultColors.primary.green
    },
    gridStyles: {
        '& .p-paginator-page': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: 3,
            justifyContent: 'center',
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            color: '#222',
            width: 'auto',
            fontSize: '1.2rem',
            lineHeight: 2,
            [theme.darkTheme]: {
                color: '#aaa'
            }
        }
    }
});

class UploadReview extends React.Component {
    state = {
        allowedFields: [],
        optionalFields: [],
        requiredFields: [],
        uploadData: [],
        visibleRows: [],
        RowWiseErrorDetail: [],
        RowWiseErrorList: [],
        errorRow: {
            index: 0,
            total: 0,
            validCount: 0,
            errorRowCount: 0,
            errorTotalCount: 0,
            errorList: []
        },
        type: 'total',
        fileUploaded: false,
        showErrorDialog: false
    };

    DataFormatter = (row, key) => {
        const { classes } = this.props;
        const { errorRow } = this.state;

        let columns = '';
        let errors = '';
        if (errorRow.errorList[row.index]) {
            columns = uniq(errorRow.errorList[row.index].errors.map((e) => e.message.split('"')[1])).join(', ');
            errors = uniq(errorRow.errorList[row.index].errors.map((e) => e.message)).map((e, index) => (
                <li style={{ padding: `5px 0` }} key={index}>
                    {e}
                </li>
            ));
        }

        let tipText =
            errors.length > 0 ? (
                <div>
                    <p>
                        <strong>Errors in Columns </strong> - {columns}
                    </p>
                    <p>
                        <strong>Errors </strong> -{' '}
                    </p>
                    <ul style={{ listStyleType: 'none', paddingLeft: `0` }}>{errors}</ul>
                </div>
            ) : (
                row[key]
            );

        return (
            <DrcTooltip tipText={tipText}>
                <span className={errors.length > 0 ? classes.badValue : classes.goodValue}>{row[key]}</span>
            </DrcTooltip>
        );
    };

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
        this.uploadType = this.props.match.params.uploadType;
        this.fileId = this.props.match.params.fileId;
        this.berryType = berryTypes.getCodeByDisplayName(this.props.match.params.berryType);

        if (!this.props.columnNames[this.uploadType]) {
            try {
                let token = await this.props.authState?.accessToken;
                let columnNames = await Middleware.Send('ColumnNames', token, '', 'GET');
                this.props.setColumnNames(columnNames.Results);
            } catch (err) {
                this.showError('API Error', err);
            }
        }

        if (this.props.columnNames[this.uploadType]) {
            this.startValidationLoader();
            setTimeout(() => this.populateUploadTypeFields(), 0);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columnNames !== this.props.columnNames) {
            // setTimeout is bad but nothing else is worked out.
            // the function was halting the rendering
            this.startValidationLoader();
            setTimeout(() => this.populateUploadTypeFields(), 0);
        }
    }

    startValidationLoader = () => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>Validating File Data</h4>
            </React.Fragment>
        );
    };

    showError = (title, errors) => this.props.setErrorsAction(title, errors);

    columnTooltip = (text, shortenedText) => (
        <DrcTooltip tipText={text}>
            <span>{shortenedText ? shortenedText : text}</span>
        </DrcTooltip>
    );

    populateUploadTypeFields = () => {
        let berryName = this.props.match.params.berryType.toUpperCase();

        let columnNames = cloneDeep(this.props.columnNames[this.uploadType][berryName]);

        let columns = Object.values(columnNames).map((row) => {
            row.name = this.columnTooltip(row.name);

            return row;
        });

        // NOTE: now  validating required and optional fields as well in UI
        let listData = this.props.mdrData;
        let errorRows = [];
        let userRoleName = this.props.roleName || '';
        let isRegionalForecaster = userRoleName.toLowerCase() === 'regional';

        // add a validation to check if selected berrytype and berry type in filerecords is same or not
        columns = columns.map((field) => {
            if (field.excelColumnName === 'Commodity') {
                field.validationType.unshift(berryTypeCheck(this.props.match.params.berryType));
            }

            return field;
        });

        // Adding values coming from our db on what the admin has setup for that regional forecaster
        if (this.uploadType === 'WeeklyRegionalForecast' && isRegionalForecaster) {
            listData = {
                ...listData,
                'Producing Area Code': this.props.userProducingAreas.map((e) => e.ProducingAreaCode)
            };
        }

        errorRows = DataValidation.ValidateData(this.props.uploadData, columns, listData, this.uploadType);

        let i = 0;
        let RowWiseErrorDetail = new Array(errorRows.total);
        let RowWiseErrorList = new Array(errorRows.total);
        errorRows.errorList.forEach((row, index) => {
            if (row) {
                RowWiseErrorDetail[index] = row.errors.map((e) => e.message).join(', ');
                RowWiseErrorList[index] = row.errors.map((e) => e.message.split('"')[1]);
            }
        });

        let data = (this.props.uploadData || []).map((row) => {
            let output = {};
            columns.forEach((field) => {
                let cellValue = formatRowData(row[field.excelColumnName], field.excelColumnName, field.type);
                //NOTE 0||null is null so we were not able to see 0 in cell. Instead it was showing null.
                output[field.key] = typeof cellValue === 'number' ? cellValue : cellValue || null;
            });
            return { ...output, index: i++ };
        });

        this.setState({
            allowedFields: columns
                .filter((col) => !col.isExcluded)
                .map((col) => {
                    col.resizable = true;
                    col.columnTemplate = (row) => this.DataFormatter(row, col.key);
                    return col;
                }),
            allFields: columns, // fields in table
            uploadData: data,
            visibleRows: data,
            errorRow: errorRows,
            RowWiseErrorDetail: RowWiseErrorDetail,
            RowWiseErrorList: RowWiseErrorList,
            errorCount: DECIMAL_FORMAT(errorRows.errorRowCount),
            goodCount: DECIMAL_FORMAT(errorRows.validCount),
            totalCount: DECIMAL_FORMAT(errorRows.total)
        });

        this.props.hideLoadingScreenAction();
    };

    cleanUpUploadColumns = (array, token) => {
        let data = (array || []).map((row) => {
            let output = {};
            this.state.allowedFields.forEach((field) => {
                output[field.key] = row[field.excelColumnName] !== undefined ? row[field.excelColumnName] : '';
            });
            return { ...output };
        });
        let fileData = [];
        let userId = DuAuthenticationUtilities.GetUserId(token);
        data.forEach((element) => {
            let columnData = [];
            Object.values(this.state.allFields).forEach((field) => {
                if (field.key === 'createdBy') {
                    element[field.key] = userId || '';
                } else if (field.excelColumnName.toLowerCase().includes('date') || field.excelColumnName.toLowerCase().includes('day')) {
                    let formattedDate = getDateFromExcelDateStamp(element[field.key], true); // converts date to ISO 'yyyy-mm-dd'
                    element[field.key] = formattedDate === 'N/A' ? '' : formattedDate;
                } else if (booleanColumns.includes(field.dbColumnName)) {
                    element[field.key] = element[field.key] === true ? 1 : 0;
                }

                if (element[field.key] === 'Null' || element[field.key] === 'null') {
                    element[field.key] = ''; //null
                    // This should be "" not null because in db script we have conversion (not for required fields) i.e.
                    // ColumnName= CASE WHEN @var = '' THEN null else @var End, this is for nonRequired fields
                    // ColumnName=  @var , this is for Required fields
                    /* I think js null is different than DB's NULL */
                }

                columnData.push(
                    field.isExcluded && field.key !== 'createdBy'
                        ? ''
                        : typeof element[field.key] === 'string'
                        ? element[field.key].trim()
                        : element[field.key]
                );
            });

            fileData.push(columnData);
        });

        return fileData;
    };

    arrayToCSV = (array) => {
        let strBase = '';

        return array.reduce((str, next) => {
            str += `${Object.values(next)
                .map((value) => `${value === null ? '' : value}`)
                .join('|')}\r\n`;
            return str;
        }, strBase);
    };

    handleDataUpload = async () => {
        this.props.showLoadingScreenAction(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>Uploading File</h4>
                <div>Performing Step 1 of 2</div>
            </React.Fragment>
        );

        try {
            let token = await this.props.authState?.accessToken;
            let userId = DuAuthenticationUtilities.GetUserId(token);

            let uploadFileNameForApi = `${this.uploadType}_${userId}_${new Date().toISOString().replace(':', '-').replace(':', '-')}.csv`;

            let uploadFileName = `forecast/${uploadFileNameForApi}`;

            let { AccessKey, SecretKey, SessionToken } = await Middleware.Send(
                'S3Credentials',
                token,
                window.config.API_BASE_ADDRESS + 'files/token?sessionname=payroll-test', //+ btoa(uploadFileName),
                'GET',
                {},
                {
                    // remove loader as we are using custom loader here
                    showLoadingScreen: () => {
                        /* code */
                    },
                    hideLoadingScreen: () => {
                        /* code */
                    }
                }
            );

            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>Uploading File</h4>
                    <div>Performing Step 2 of 2</div>
                </React.Fragment>
            );

            if (!AccessKey || !SecretKey || !SessionToken) {
                console.error('AWS Token not valid!');
                return;
            }

            let S3 = getInstance(AccessKey, SecretKey, SessionToken);
            let cleanedData = this.cleanUpUploadColumns(this.props.uploadData, token);
            let fileData = this.arrayToCSV(cleanedData);
            await uploadToS3(S3, fileData, uploadFileName);

            const dataTypeMap = DATA_TYPE_AND_FREQUENCY_MAPPING[this.uploadType] || null;

            if (dataTypeMap !== null) {
                let payload = {
                    FileName: uploadFileNameForApi,
                    Datatype: dataTypeMap.DataType,
                    Frequency: dataTypeMap.Frequency,
                    BerryType: this.props.match.params.berryType.toUpperCase(),
                    Status: status.uploaded
                };

                // File Id in url means its a re-upload
                let isReUpload = false;

                if (this.fileId) {
                    isReUpload = true;
                    payload.FileId = this.fileId;
                    payload.Status = status.reUploaded;
                    payload.ModifiedBy = userId;
                    payload.ModifiedDateTime = new Date().toISOString();
                } else {
                    payload.CreatedBy = userId;
                }

                await Middleware.Send(
                    'FileUploadConfirmation',
                    token,
                    window.config.API_BASE_ADDRESS + (isReUpload ? 'resubmissionfile' : 'initialfile'),
                    'POST',
                    payload,
                    {
                        // remove loader as we are using custom loader here
                        showLoadingScreen: () => {
                            /* code */
                        },
                        hideLoadingScreen: () => {
                            /* code */
                        },
                        overrideRequestMapping: true
                    }
                );
            }

            this.setState({ fileUploaded: true });
            this.props.hideLoadingScreenAction();
            this.props.notify('File uploaded successfully; file will be ready for review shortly', false);
        } catch (err) {
            let errorToShow = err;

            if (err.data && err.data.message) {
                errorToShow = err.data.message;

                if (err.data.config.url) {
                    errorToShow += ': ' + err.data.config.url;
                }
            }

            this.showError('An Error Occurred', errorToShow);
            this.props.hideLoadingScreenAction();
            this.setState({ fileUploaded: false });
        }
    };

    makeErrorDetailsDownloadData = (array) => {
        let data = (array || []).map((row, indx) => {
            let output = {};
            this.state.allowedFields.forEach((field) => {
                output[field.excelColumnName] = row[field.excelColumnName] || null;
            });
            output['Error Columns'] = this.state.RowWiseErrorList[indx] ? this.state.RowWiseErrorList[indx].join(', ') : null;
            output['Errors Details'] = this.state.RowWiseErrorList[indx] ? this.state.RowWiseErrorDetail[indx] : null;
            return { ...output };
        });
        return data;
    };

    makeListOfFieldsToFix = () => {
        let missingData = {};

        (this.props.uploadData || []).forEach((row, indx) => {
            let badColumns = this.state.RowWiseErrorList[indx] || [];

            if (badColumns.length <= 0) {
                return;
            }

            this.state.allowedFields.forEach((field) => {
                if (!badColumns.includes(field.excelColumnName)) {
                    return;
                }

                let badData = row[field.excelColumnName] || '';

                if ((missingData[field.excelColumnName] || []).length <= 0) {
                    missingData[field.excelColumnName] = [badData];
                } else if (!missingData[field.excelColumnName].includes(badData)) {
                    missingData[field.excelColumnName].push(badData);
                }
            });
        });

        return missingData;
    };

    handleDownload = () => {
        let fileData = this.makeErrorDetailsDownloadData(this.props.uploadData);
        if (fileData && fileData.length > 0) {
            const file = {
                cols: Object.keys(fileData[0]).map((key) => ({ name: key, key: key })),
                data: fileData
            };

            DuExcelUtilities.Write(`Error_Details-${this.props.fileName}`, file.cols, file.data);
        }
    };
    //using rowRenderer to render each row based on error (mismatched data's row indexes)
    RowRenderer = ({ renderBaseRow, ...props }) => {
        let columns = '';
        let errors = '';
        if (this.state.errorRow.errorList[props.row.index]) {
            columns = uniq(this.state.errorRow.errorList[props.row.index].errors.map((e) => e.message.split('"')[1])).join(', ');
            errors = uniq(this.state.errorRow.errorList[props.row.index].errors.map((e) => e.message)).map((e, index) => (
                <li style={{ padding: `5px 0` }} key={index}>
                    {e}
                </li>
            ));
        }

        let errorMessage = (
            <div>
                <p>
                    <strong>Errors in Columns </strong> - {columns}
                </p>
                <p>
                    <strong>Errors </strong> -{' '}
                </p>
                <ul style={{ listStyleType: 'none', paddingLeft: `0` }}>{errors}</ul>
            </div>
        );

        return (
            <>
                {this.state.errorRow.errorList[props.row.index] ? (
                    <DrcTooltip tipText={errorMessage}>
                        <div style={{ color: 'red' }}>{renderBaseRow(props)}</div>
                    </DrcTooltip>
                ) : (
                    <div style={{ color: 'green' }}>{renderBaseRow(props)}</div>
                )}
            </>
        );
    };

    handleFilterClick = (type) => {
        let data = this.state.uploadData || [];
        if (this.state.type === type) {
            return;
        }

        switch (type) {
            case 'good':
                data = data.filter((d) => {
                    return !this.state.errorRow.errorList[d.index];
                });
                break;
            case 'error':
                data = data.filter((d) => {
                    return this.state.errorRow.errorList[d.index];
                });
                break;
            default:
                break;
        }

        this.setState({ visibleRows: data, type });
    };

    render() {
        let { isMasterDataInitialized, classes } = this.props;
        const { visibleRows, goodCount, errorCount, totalCount, type } = this.state;
        let columnsErrorList = uniq(flatten(this.state.RowWiseErrorList)).filter((e) => e);
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        if (totalCount === '0') {
            return <Redirect to="/Upload/" />;
        }

        return (
            <DrcMain>
                <div className="row">
                    <div className="col-xs-12">
                        <DrcDataGrid
                            pageSize={10}
                            totalRecords={visibleRows.length}
                            hideCount={true}
                            paginator={true}
                            columns={this.state.allowedFields}
                            rows={visibleRows}
                            resizable={true}
                            rowHeight={35}
                            headerRowHeight={35}
                            fullHeightOffset={true}
                            rowRenderer={this.RowRenderer}
                            gridStyles={classes.gridStyles}
                        />
                    </div>
                    <div className={`col-xs-2`}>
                        <DrcButton
                            onClick={() => this.handleFilterClick('good')}
                            className={`${classes.stat} ${type === 'good' ? classes.selected : null}`}
                            style={
                                goodCount !== '0'
                                    ? { color: DuThemeUtilities.DefaultColors.primary.green }
                                    : { color: DuThemeUtilities.DefaultColors.primary.red }
                            }
                        >
                            Good: {goodCount !== '0' ? goodCount : 'None'}
                        </DrcButton>
                    </div>
                    <div className={`col-xs-2`} style={{ display: 'flex', alignItems: 'center' }}>
                        <DrcButton
                            onClick={() => this.handleFilterClick('error')}
                            className={`${classes.stat} ${type === 'error' ? classes.selected : null}`}
                            style={errorCount !== '0' ? { color: DuThemeUtilities.DefaultColors.primary.red } : null}
                        >
                            Errors: {errorCount !== '0' ? errorCount : 'None'}
                        </DrcButton>
                        {errorCount !== '0' && (
                            <ErrorOutlineIcon
                                style={{ fill: 'red', cursor: 'help' }}
                                onClick={() => {
                                    this.setState({ showErrorDialog: true });
                                    this.makeListOfFieldsToFix();
                                }}
                            />
                        )}
                    </div>
                    <div className={`col-xs-2`}>
                        <DrcButton
                            onClick={() => this.handleFilterClick('total')}
                            className={`${classes.stat} ${type === 'total' ? classes.selected : null}`}
                            style={totalCount !== '0' ? null : { color: DuThemeUtilities.DefaultColors.primary.red }}
                        >
                            Total: {totalCount !== '0' ? totalCount : 'None'}
                        </DrcButton>
                    </div>
                    <div className="col-xs-3">
                        <DrcButton fullWidth isSecondary style={{ marginLeft: 0 }} to="/Upload/">
                            Back
                        </DrcButton>
                    </div>
                    <div className="col-xs-3">
                        <DrcButton
                            fullWidth
                            isPrimary
                            style={{ marginLeft: 0 }}
                            onClick={() => {
                                this.handleDataUpload();
                            }}
                            disabled={this.state.errorRow.errorRowCount > 0 || this.state.fileUploaded}
                        >
                            {this.state.fileUploaded ? 'Uploaded' : 'Upload'}
                        </DrcButton>
                    </div>
                </div>
                <DrcDialog
                    open={this.state.showErrorDialog}
                    title="Below columns could not pass validation check"
                    buttons={
                        <>
                            {' '}
                            <DrcButton
                                isPrimary
                                onClick={() => {
                                    this.handleDownload();
                                    this.setState({ showErrorDialog: false });
                                }}
                            >
                                Download Details
                            </DrcButton>
                            <DrcButton isPrimary onClick={() => this.setState({ showErrorDialog: false })}>
                                Cancel
                            </DrcButton>
                        </>
                    }
                >
                    <div>
                        <ul>
                            {columnsErrorList.map((field, key) => (
                                <li key={key}>{field}</li>
                            ))}
                        </ul>
                    </div>
                </DrcDialog>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        uploadData: state.UploadReducer.uploadData,
        fileName: state.UploadReducer.fileName,
        mdrData: state.masterReducer.mdrData,
        columnNames: state.masterReducer.columnNames,
        userProducingAreas: state.userReducer.userProducingAreas,
        roleName: state.userReducer.roles.roleName
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    notify: (message, isError) => dispatch(notify(message, isError)),
    showLoadingScreenAction: (data) => dispatch(showLoadingScreenAction(data)),
    hideLoadingScreenAction: (data) => dispatch(hideLoadingScreenAction(data)),
    setColumnNames: (data) => dispatch(setColumnNames(data)),
    setErrorsAction: (title, error) => dispatch(setErrorsAction(title, error))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadReview)));
