import React from 'react';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcTooltip } from '@driscollsinc/driscolls-react-components';
import { makeBerryTypeCell } from '../components/makeBerryTypeCell';
import { getUploadTypeByDataType } from '../data/json/forecastTypes';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';

export const dateColumns = [
    'TreatmentStartDate',
    'SubmissionWeekEnddate', //While download it wont work as Db field does nto have Date
    'SubmissionWeekend', // so this one added
    'ProducingDay',
    'SubmissionDay',
    'ProducingWeekendDate',
    'ForecastStartDate',
    'ModifiedDateTime',
    'CreatedDateTime'
];

export const floatColumns = [
    'Lbs',
    'Kilos',
    'Crates',
    'Acres',
    'Hectares',
    'Wk0',
    'Wk1',
    'Wk2',
    'Wk3',
    'Wk4',
    'Wk5',
    'Wk6',
    'Wk7',
    'Wk8',
    'Wk9',
    'Wk10',
    'Wk11',
    'Wk12',
    'Wk13'
]; //These are the names in db

export const booleanColumns = ['SalesForecast', 'PackagingForecast'];

//To format all numerical values (1234/"1234" both) and add commas for millions ("1,234")
export const FLOAT_FORMAT = (val) => {
    //NOTE: toLocaleString requires number only
    // so +value will always be a number and it will return a string

    /*  Number is also present in year so that was also getting comma formatted
        // if (!isNaN(val) && !excelColumnName.toLowerCase().includes("year") && !excelColumnName.toLowerCase().includes("deal"))
         */
    return (+val).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    // NOTE: replace "en-US" with undefined to Use the host default language for number formatting
};

export const DECIMAL_FORMAT = (value) => {
    if (Number.isNaN(Number(value))) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//convert date (in time format) from 1900 to mm/dd/yy format or ISO to save in db
export const getDateFromExcelDateStamp = (date, isoFormat = false) => {
    if (date === '') {
        return date;
    }

    //If the date is actually a month
    if (typeof date === 'string' && date.length <= 3) {
        return date;
    }

    /* To explain the terms in the formula, the Javascript timestamp has milliseconds (*1000) which accounts for the 1000. There are 60*60*24 seconds in one day = 86400.
    Finally, Excel dates start on Jan 1 1900, and Javascript starts on Jan 1 1970. There are 25569 days between Jan 1 1900 and Jan 1 1970.*/
    const getDate = isNaN(date) && typeof date === 'string' ? new Date(date) : new Date(Math.round((date - 25568) * 86400 * 1000));

    if (!DuDateUtilities.IsValidDate(getDate)) {
        return 'Null';
    }

    return isoFormat ? DuDateUtilities.ToIsoDate(getDate).split('T')[0] : (DuDateUtilities.ToString(getDate) || '').split('T')[0];
};

//Creating a function where we can format all columns of a row and also apply multiple formatters  alltogether
export const formatRowData = (value, excelColumnName, type) => {
    let cellValue = value;

    //checking if it is number & decimal
    if (type === 'float' && isNaN(value)) {
        cellValue = 'Null';
    } else if (excelColumnName.includes('Date') || excelColumnName.includes('date') || type === 'date') {
        //if cellvalue=0 then it was getting displayed as ""
        cellValue = cellValue && cellValue !== '' ? getDateFromExcelDateStamp(cellValue) : cellValue === 0 ? 0 : '';
    } else if (excelColumnName.includes('Packaging Forecast') || excelColumnName.includes('Sales Forecast')) {
        if (typeof cellValue === 'boolean') cellValue = cellValue ? 'True' : 'False';
    } else if (!isNaN(value) && floatColumns.includes(excelColumnName)) {
        cellValue = FLOAT_FORMAT(cellValue);
    }
    return cellValue;
};

// Format Data of file to display properly
export const formatFileToDisplay = (file, classes) => {
    // make copy to not chnage incoming data
    let formatted = cloneDeep(file);
    return formatted.map((row, index) => {
        Object.keys(row).forEach((key) => {
            if (key === 'BerryType') {
                row.BerryType = makeBerryTypeCell(row.BerryType, index);
            } else if (key === 'Datatype') {
                row.Datatype = <p className={classes.fileLink}>{startCase(getUploadTypeByDataType(row.Datatype) || '')}</p>;
            } else if (key === 'Commodity') {
                row.Commodity = makeBerryTypeCell(row.Commodity, index);
            } else {
                const value = row[key];
                if (dateColumns.includes(key.split(' ').join(''))) {
                    row[key] = DuDateUtilities.FormatDateFromIso(value, '');
                } else if (floatColumns.includes(key)) {
                    if (!isNaN(value)) row[key] = FLOAT_FORMAT(value);
                } else if (booleanColumns.includes(key.split(' ').join(''))) {
                    row[key] = value === true ? 'True' : 'False';
                }
            }
        });
        return row;
    });
};

export const columnTooltip = (text, shortenedText) => (
    <DrcTooltip tipText={text}>
        <span>{shortenedText ? shortenedText : text}</span>
    </DrcTooltip>
);

//Format Grid Columns for PrimeReact
export const setGridColumns = (allowedFields = []) => {
    const allowedFieldsCopy = cloneDeep(allowedFields);
    const defaultColumnProperties = {
        resizable: true,
        editable: true,
        width: '100px'
    };

    let formattedColumn = allowedFieldsCopy
        //  .filter((e) => this.props.userFileData.FileData[0][e] !== null && this.props.userFileData.FileData[0][e] !== '')
        //  .filter((e) => !columnsToRemove.includes(e.toLowerCase()))
        .map((e) => {
            let name =
                e.toLowerCase() === 'commodity'
                    ? columnTooltip(startCase('Berry Type'))
                    : e.toLowerCase() === 'estgroup'
                    ? columnTooltip(startCase('EST Group'))
                    : columnTooltip(startCase(e));

            return {
                key: e,
                name,
                ...defaultColumnProperties
            };
        });

    if (!formattedColumn.length) {
        formattedColumn = [
            {
                key: 'No Fields Matched the Allowed field list for the Upload Type',
                name: 'No Fields Matched the Allowed field list for the Upload Type',
                ...defaultColumnProperties
            }
        ];
    }

    return formattedColumn;
};

/*  input: Expecting object [{year: {excelColumnName}} ..]
    output: dbColumnName as this is we get while fetching files
 */
export const getAllowedFields = (fields) => {
    const entries = Object.entries(fields);
    let optionalFields = getOptionalFields(entries);
    let requiredFields = getRequiredFields(entries);
    return [...requiredFields, ...optionalFields];
};

const getRequiredFields = (data, uploadType) => {
    // key is entry[0]  - budgetCrates
    // excel column name - Variety Name , we need in startcase
    return data
        .map((entry) => {
            if (entry[1].isRequired) {
                return entry[1].name;
            }
            return null;
        })
        .filter((e) => e);
};

const getOptionalFields = (data) => {
    return data
        .map((entry) => {
            if (!entry[1].isRequired && !entry[1].isExcluded) {
                return entry[1].name;
            }
            return null;
        })
        .filter((e) => e);
};

// Downloaded-File get allowed columns
export const allowedColumnsInDownloadedFile = (data, uploadTypeColumnNames) => {
    let allowedFields = getAllowedFields(uploadTypeColumnNames);

    return Object.keys(data[0])
        .map((key) => {
            return allowedFields.includes(key) ? { name: key, key: key } : null;
        })
        .filter((element) => {
            return element;
        });
};

export const getColumnsForDownloadingFile = (data, uploadTypeColumnNames) => {
    let allowedFields = getAllowedFields(uploadTypeColumnNames);

    let fileKeys = Object.keys(data[0]);

    return allowedFields
        .map((key) => {
            if (fileKeys.includes(key)) {
                return { name: key, key: key };
            }
            return null;
        })
        .filter((e) => e);
};

// Downloaded-File get record formatted
export const formatValuesInDownloadedFile = (fileData) => {
    let file = cloneDeep(fileData);
    return file.map((e) => {
        e.CreatedBy = e.CreatedBy.replace(/(\r\n|\n|\r)/gm, '');
        e.ModifiedBy = e.ModifiedBy.replace(/(\r\n|\n|\r)/gm, '');
        const dbFieldName = Object.keys(e);
        dbFieldName.forEach((field) => {
            if (dateColumns.includes(field.split(' ').join(''))) {
                //in case of review file we need this split join
                e[field] = DuDateUtilities.FormatDateFromIso(e[field], '');
            }
        });
        return e;
    });
};

//Replace columnHeader from dbColumnName to Excel Column Name
export const formatDbColumnNameToUiName = (fileData, columns) => {
    let file = cloneDeep(fileData);
    let columnMap = {};
    Object.keys(columns).forEach((name) => {
        let col = columns[name].dbColumnName;
        columnMap[col] = columns[name];
    });
    file.forEach((row) => {
        Object.keys(row).forEach((fieldName) => {
            if (columnMap[fieldName]) {
                let val = row[fieldName];
                delete row[fieldName];
                Object.assign(row, { [columnMap[fieldName].name]: val });
            }
        });
    });
    return file;
};
