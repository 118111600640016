import { Middleware } from '@driscollsinc/one-ring';

const emptyFunc = () => {
    return {};
};

const defaultUrlFunc = (url, currentPage, pageSize, currentPageParameter = 'startPage', pageSizeParameter = 'pageSize') => {
    return `${url}${url.includes('?') ? '&' : '?'}${currentPageParameter}=${currentPage}&${pageSizeParameter}=${pageSize}`;
};

const setStatusFunc = (pagesLoaded, pageSize, totalResults = 0) => {
    let totalPages = 0;

    if (totalResults > 0) {
        totalPages = Math.ceil(totalResults / pageSize);
    }

    let percentValue = 0;
    let percentText = '0%';

    if (totalPages > 0) {
        percentValue = pagesLoaded / totalPages;
        percentText = `${(percentValue * 100).toFixed(2)}%`;
    }

    return {
        pagesLoaded,
        pageSize,
        totalResults,
        totalPages,
        percentValue,
        percentText
    };
};

//Requires Data to come back in Results
const getAllData = async (
    middlewareName,
    token,
    url,
    statusFunc,
    resultParameter = 'Results',
    totalResultsParameter = 'total',
    currentPageQueryParameter = 'startPage',
    pageSizeQueryParameter = 'pageSize',
    firstPageNumber = 1,
    pageSize = 1000,
    urlFunc = defaultUrlFunc,
    method = 'GET',
    requestObjFunc = emptyFunc,
    props = {},
    args = {}
) => {
    return new Promise(async function (resolve, reject) {
        statusFunc(setStatusFunc(0, pageSize));

        let allData = [];
        let totalResults = 0;

        //Need to call the api to get the first page to see how many results there are
        Middleware.Send(
            middlewareName,
            token,
            urlFunc(url, firstPageNumber, pageSize, currentPageQueryParameter, pageSizeQueryParameter),
            method,
            requestObjFunc(firstPageNumber, pageSize),
            props,
            args
        )
            .then(async (data) => {
                let tempTotalResults = Number(data[totalResultsParameter]);

                if (Array.isArray(data[resultParameter]) && Number.isInteger(tempTotalResults)) {
                    allData = allData.concat(data[resultParameter]);
                    totalResults = tempTotalResults;

                    statusFunc(1, pageSize, totalResults);

                    if (totalResults < pageSize) {
                        resolve(allData);
                        return;
                    }

                    let totalPages = Math.ceil(totalResults / pageSize);

                    for (var i = 1; i < totalPages; i++) {
                        let pageData = await Middleware.Send(
                            middlewareName,
                            token,
                            urlFunc(url, firstPageNumber + i, pageSize, currentPageQueryParameter, pageSizeQueryParameter),
                            method,
                            requestObjFunc(firstPageNumber + i, pageSize),
                            props,
                            args
                        );

                        if (Array.isArray(pageData[resultParameter])) {
                            allData = allData.concat(pageData[resultParameter]);

                            statusFunc(i + 1, pageSize, totalResults);
                        } else {
                            reject(`Failure to retrieve page ${i + 1}, cannot continue download`);
                        }
                    }

                    resolve(allData);
                } else {
                    reject(`Did not find ${resultParameter} in the returned result`);
                }
            })
            .catch((error) => {
                reject(`Something bad happened trying to get the first chunk of the file. Error: ${error}`);
            });
        //If there is a failure show its bad.
        // If there is no more pages return success
        //If there are more create a send multiple array
    });
};

const DownloadUtilities = {
    GetAllData: getAllData
};

export default DownloadUtilities;
