import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import SFUserFiles from './sfUserFiles';
import SFUserRole from './sfUserRole';
import S3Credentials from './s3Credentials';
import ForecastTypes from './forecastTypes';
import RoleForecastUploadTypes from './roleForecastUploadTypes.js';
import SFVolumeFile from './sfVolumeFileData';
import SFWeeklyFile from './sfWeeklyFileData';
import SFAcreFile from './sfAcreFileData';
import SFDailyFile from './sfDailyFileData';
import columnNames from './columnNames';
import sfReviewFiles from './sfReviewFiles';
import ReviewFile from './reviewFile';
import Ranches from './ranches';
import ProducingAreas from './producingAreas';
import UserBerryRoles from './userBerryTypeRole';
import UserUploadTypeRoles from './userUploadTypeRole';
import UserNotification from './userNotification';
import Variety from './variety';
import Warehouse from './warehouse';
import EmailNotificationType from './emailNotification';
import UserProducingArea from './userToProducingArea';
import ReceivingArea from './receivingArea';
import PlanningGroup from './planningGroup';
import Brand from './brand';
import AppRoles from './appRoles';

function ConfigureAllMiddleware() {
    SFUserFiles.ConfigureMiddleware();
    SFUserRole.ConfigureMiddleware();
    S3Credentials.ConfigureMiddleware();
    ForecastTypes.ConfigureMiddleware();
    RoleForecastUploadTypes.ConfigureMiddleware();
    SFVolumeFile.ConfigureMiddleware();
    SFWeeklyFile.ConfigureMiddleware();
    SFAcreFile.ConfigureMiddleware();
    SFDailyFile.ConfigureMiddleware();
    columnNames.ConfigureMiddleware();
    sfReviewFiles.ConfigureMiddleware();
    ReviewFile.ConfigureMiddleware();
    Ranches.ConfigureMiddleware();
    ProducingAreas.ConfigureMiddleware();
    UserBerryRoles.ConfigureMiddleware();
    UserUploadTypeRoles.ConfigureMiddleware();
    UserNotification.ConfigureMiddleware();
    Variety.ConfigureMiddleware();
    Warehouse.ConfigureMiddleware();
    EmailNotificationType.ConfigureMiddleware();
    UserProducingArea.ConfigureMiddleware();
    ReceivingArea.ConfigureMiddleware();
    PlanningGroup.ConfigureMiddleware();
    Brand.ConfigureMiddleware();
    AppRoles.ConfigureMiddleware();

    Middleware.RegisterDefaultPayloadHandler((token) => {
        let email = DuAuthenticationUtilities.GetEmail(token);
        return { LastUpdatedBy: email || 'Bad Token', LastUpdatedDateTime: new Date().toISOString() };
    });
}

const setup = {
    ConfigureAllMiddleware
};

export default setup;
