import { Middleware } from '@driscollsinc/one-ring';

const columnNameMappings = [Middleware.CreateMapping('Results', 'Results')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ColumnNames', 'GET')) {
        Middleware.RegisterMapping('ColumnNames', 'GET', columnNameMappings);
    }
}

const ColumnNamesMaster = {
    ConfigureMiddleware
};

export default ColumnNamesMaster;
