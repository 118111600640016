import { Middleware } from '@driscollsinc/one-ring';

const userBerryRolesMapping = [Middleware.CreateMapping('BerryTypes', 'BerryTypes')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserBerryTypeRoles', 'GET')) {
        Middleware.RegisterMapping('UserBerryTypeRoles', 'GET', userBerryRolesMapping);
    }
}

const UserBerryRoles = {
    ConfigureMiddleware
};

export default UserBerryRoles;
