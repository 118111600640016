//userNotification
import { Middleware } from '@driscollsinc/one-ring';
import userNotification from '../json/userNotification';

const UserNotificationFunction = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('UserNotification')) {
        Middleware.RegisterFakeDataHandler('UserNotification', userNotification, UserNotificationFunction);
    }
}

const UserNotificationFake = {
    ConfigureFakeData
};

export default UserNotificationFake;
