import { Middleware } from '@driscollsinc/one-ring';

const SfAcreFileDataMappings = [
    Middleware.CreateMapping('FileNbr', 'FileNbr'),
    Middleware.CreateMapping('Year', 'Year'),
    Middleware.CreateMapping('Commodity', 'Commodity'),
    Middleware.CreateMapping('FieldType', 'FieldType'),
    Middleware.CreateMapping('ProducingAreaName', 'ProducingAreaName'),
    Middleware.CreateMapping('ProducingAreaCode', 'ProducingAreaCode'),
    Middleware.CreateMapping('DealSeason', 'DealSeason'),
    Middleware.CreateMapping('VarietyCode', 'VarietyCode'),
    Middleware.CreateMapping('VarietyName', 'VarietyName'),
    Middleware.CreateMapping('MediaType', 'MediaType'),
    Middleware.CreateMapping('AcreageQuantity', 'AcreageQuantity'),
    Middleware.CreateMapping('HectareCalculation', 'HectareCalculation'),
    Middleware.CreateMapping('SubmissionWeekend', 'SubmissionWeekend'),
    Middleware.CreateMapping('ApplicationTreatment', 'ApplicationTreatment'),
    Middleware.CreateMapping('TreatmentStartDate', 'TreatmentStartDate'),
    Middleware.CreateMapping('Timing', 'Timing'),
    Middleware.CreateMapping('PlantType', 'PlantType'),
    Middleware.CreateMapping('Protection', 'Protection'),
    // Middleware.CreateMapping('ReviewComments', 'ReviewComments'),
    // Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    // Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    // Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    // Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('Records', 'Records')
    // Middleware.CreateArrayMapping('FileData', 'FileData'),
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SFAcre', 'GET')) {
        Middleware.RegisterMapping('SFAcre', 'GET', SfAcreFileDataMappings);
    }
}

const SfAcreFileMaster = {
    ConfigureMiddleware
};

export default SfAcreFileMaster;
