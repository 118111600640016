import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../actions/actions';
import { setUploadData } from '../actions/UploadActions';
import { notify } from '../actions/NotificationAction';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcButton, DrcLegend, DrcDialog, DrcTooltip, DrcPanel, DrcBerryList } from '@driscollsinc/driscolls-react-components';
import UploadType from '../components/UploadType';
import FileUploader from '../components/FileUploader';
import { getMissingFields } from '../data/validation';
import berryTypes from '../data/berryTypes';
import Error from '@material-ui/icons/Error';

const pageTitle = 'Upload';

const styles = (theme) => ({
    noBottom: {
        borderBottom: 'none',
        marginTop: 0
    },
    dragArea: {
        width: '100%',
        height: 100,
        border: '2px dashed #888',
        padding: 35,
        margin: '40px 0 10px',
        borderRadius: 10,
        textAlign: 'center'
    },
    uploadBtn: {
        textTransform: 'none'
    },
    errorIcon: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    submitBtnContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    submitBtn: {
        width: 150,
        textAlign: 'center'
    },
    missingFieldsBackdrop: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    missingFieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    backdropListItem: {
        fontSize: '1.5rem',
        color: theme.palette.primary.contrastText
    },
    backdropHeading: {
        fontSize: '2.5rem',
        color: theme.palette.primary.contrastText
    }
});

class Upload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fileName: '',
            isReviewing: false,
            isAbleToReview: false,
            fileType: this.props.match.params.fileType || 0,
            berryType: berryTypes.getCodeByDisplayName(this.props.match.params.berryType) || 0,
            disabledBerries: [1, 2, 3, 4],
            allowedUploadTypes: [],
            missingFieldList: [],
            showMissingFieldsWarning: false
        };
    }

    BERRY_CODES = [1, 2, 3, 4];

    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
        if (this.props.userRoles.allowedUploadTypes) {
            this.initState();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ disabledBerries: this.getDisabledBerries(), fileType: 0, berryType: 0 });
        }
        if (
            prevProps.userRoles.allowedUploadTypes.length === 0 &&
            this.props.userRoles.allowedUploadTypes &&
            this.props.userRoles.hasAccessToUpload
        ) {
            this.initState();
        }
    }

    initState = () => {
        let disabledBerries;

        if (this.state.berryType) {
            disabledBerries = this.state.disabledBerries.filter((e) => e !== parseInt(this.state.berryType, 10));
        } else {
            disabledBerries = this.getDisabledBerries();
        }

        this.setState({
            data: [],
            fileName: '',
            allowedUploadTypes: this.props.userRoles.allowedUploadTypes,
            disabledBerries
        });
    };

    isReadyForReview = (fileType, berryType, data) => {
        if (fileType === 0 || berryType === 0 || data.length <= 0) {
            return false;
        }

        if (fileType !== 0 && berryType !== 0 && data.length > 0) {
            return this.hasRequiredFields(data, fileType, berryType);
        }

        return true;
    };

    getDisabledBerries = () => {
        if (this.props.match.params.berryType) {
            return this.state.disabledBerries;
        } else if (this.props.userRoles.allowedBerryTypes) {
            let userBerryTypesCodes = this.props.userRoles.allowedBerryTypes.map((berry) => berryTypes[berry]);
            return this.BERRY_CODES.filter((berryCode) => !userBerryTypesCodes.includes(berryCode));
        }
    };

    handleFileTypeClick = (fileType) => {
        this.setState(
            {
                fileType
            },
            () => this.hasRequiredFields(this.state.data, fileType, this.state.berryType)
        );
    };

    chooseBerry = (berryType) => {
        this.setState(
            {
                berryType
            },
            () => this.hasRequiredFields(this.state.data, this.state.fileType, berryType)
        );
    };

    handleFile = (file) => {
        //NOTE: there are large values in JIRA sample excel i.e 0.000000000000125000004587928 which are getting converted to
        // exponential i.e. 1.25000004587928e-13 and Decimal regex was failing, so for now we are adding precision to 6 decimal places
        // to accumulated this edge case.

        //These are the columns having decimal values
        let columnsToFormat = ['lbs', 'kilos', 'crates', 'acres', 'hectares'];
        // let nullTOZero = ['lbs', 'kilos', 'kilo calculation', 'crates', 'short pool pw', 'acres', 'hectares'];
        //NOTE: this are the columns for which Null and 0 is allowed
        let preciseData = file.data.map((row) => {
            Object.keys(row).forEach((col) => {
                let columnName = col.trim();
                if (columnName !== col) {
                    row[columnName] = row[col];
                }

                if (typeof row[columnName] === 'string') row[columnName] = row[columnName].trim();
                const ALLOWED_IN_REQUIRED = [0, 'Null'];
                if (row[columnName] !== '' && !ALLOWED_IN_REQUIRED.includes(row[columnName])) {
                    if (columnsToFormat.includes(columnName.toLowerCase())) {
                        row[columnName] = Math.round((row[columnName] + Number.EPSILON) * 1000000) / 1000000;
                    } else if (columnName.toLowerCase() === 'producing area code') {
                        let zerosNeeded = 4 - row[columnName].toString().length;

                        if (zerosNeeded > 0) {
                            row[columnName] = '0'.repeat(zerosNeeded) + row[col];
                        }
                    } else if (columnName.toLowerCase() === 'variety name' || columnName.toLowerCase() === 'commodity') {
                        row[columnName] = typeof row[columnName] === 'string' ? row[columnName].toUpperCase() : row[columnName];
                    }
                }
            });
            return row;
        });
        this.setState(
            {
                fileName: file.fileName,
                data: preciseData,
                isAbleToReview: this.isReadyForReview(this.state.fileType, this.state.berryType, preciseData)
            },
            () =>
                this.props.setUploadData(
                    { fileName: file.fileName, data: preciseData },
                    this.hasRequiredFields(preciseData, this.state.fileType, this.state.berryType)
                )
        );
    };

    hasRequiredFields = (excelData = [], fileType, berryType) => {
        if (!berryType || !fileType || !excelData.length) {
            return false;
        }

        let berryName = berryTypes.getDisplayNameByCode(berryType).toUpperCase();
        let missingFieldList = getMissingFields(excelData, this.props.columnNames, fileType, berryName);

        // Fixed getMissingFields to always return an array.
        if (missingFieldList.length) {
            this.setState({ isAbleToReview: false, missingFieldList, showMissingFieldsWarning: true });
        } else {
            this.setState({ missingFieldList: [], showMissingFieldsWarning: false, isAbleToReview: true });
        }

        return !missingFieldList.length;
    };

    getReviewLink = () => {
        let fileType = this.state.fileType ? this.state.fileType : 0;
        let berryType = this.state.berryType ? berryTypes.getDisplayNameByCode(this.state.berryType) : 0;
        let fileId = this.props.match.params.fileId;

        if (fileId) {
            return `/Upload/Review/${fileType}/${berryType}/${fileId}`;
        } else {
            return `/Upload/Review/${fileType}/${berryType}`;
        }
    };

    handleCancel = () => {
        this.removeUploadedFile();
        this.props.setUploadData({ fileName: '', data: [] });
        this.setState({ missingFieldList: [], isAbleToReview: false, disabledBerries: this.getDisabledBerries(), data: [], fileName: '' });
    };
    //Calls child's method to remove uploaded file
    removeExcelDrop(removeUploadedFile) {
        this.removeUploadedFile = removeUploadedFile;
    }

    clearExcelFileView = () => {
        this.removeUploadedFile();
        this.setState({ missingFieldList: [], isAbleToReview: false, disabledBerries: this.getDisabledBerries(), data: [], fileName: '' });
    };

    render() {
        let { isMasterDataInitialized, classes } = this.props;
        const { isAbleToReview, isReviewing } = this.state;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcMain>
                {this.props.userRoles.hasAccessToUpload ? (
                    <div style={isReviewing ? { display: 'none' } : {}}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-8">
                                <DrcLegend className={classes.noBottom}>Select an upload type</DrcLegend>
                                <UploadType
                                    enabledUploadType={this.props.match.params.fileType}
                                    onClick={this.handleFileTypeClick}
                                    allowedUploadTypes={this.state.allowedUploadTypes}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <DrcLegend className={classes.noBottom}>Select a berry type</DrcLegend>
                                <DrcBerryList
                                    onClick={this.chooseBerry}
                                    disabledBerries={this.state.disabledBerries}
                                    selectedBerry={+this.state.berryType}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <FileUploader parsed={this.handleFile} removeUpload={this.removeExcelDrop.bind(this)} />
                            </div>
                            <div className={`col-xs-12 ${classes.submitBtnContainer}`}>
                                <DrcButton
                                    isPrimary
                                    poly
                                    noStyle
                                    disabled={!isAbleToReview}
                                    className={classes.submitBtn}
                                    onClick={this.clearExcelFileView}
                                    to={this.getReviewLink()}
                                >
                                    Submit
                                </DrcButton>
                                {this.state.missingFieldList.length > 0 && (
                                    <div className={classes.errorIcon}>
                                        <DrcTooltip tipText={'Missing columns ' + this.state.missingFieldList.join(' , ')}>
                                            <Error style={{ color: 'red' }} />
                                        </DrcTooltip>
                                    </div>
                                )}
                                <DrcButton
                                    isPrimary
                                    poly
                                    noStyle
                                    disabled={!(this.state.missingFieldList.length || this.state.data.length)}
                                    className={classes.submitBtn}
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </DrcButton>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <DrcPanel style={{ width: '100%', maxWidth: '100%', textAlignLast: 'center' }} className={classes.roundedCorners}>
                            You don't have any access to upload.
                        </DrcPanel>
                    </div>
                )}

                <DrcDialog
                    open={this.state.showMissingFieldsWarning}
                    title="Below columns are missing/empty in the upload"
                    buttons={
                        <DrcButton isPrimary onClick={() => this.setState({ showMissingFieldsWarning: false })}>
                            Okay
                        </DrcButton>
                    }
                >
                    <div>
                        <ul>
                            {this.state.missingFieldList.map((field) => (
                                <li>{field}</li>
                            ))}
                        </ul>
                    </div>
                </DrcDialog>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        userRoles: state.userReducer.roles,
        fileName: state.UploadReducer.fileName,
        columnNames: state.masterReducer.columnNames
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setUploadData: (uploadData) => dispatch(setUploadData(uploadData)),
    notify: (message, isError) => dispatch(notify(message, isError))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Upload));
