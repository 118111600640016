import ForecastTypes from './forecastTypes';
import columnNames from './columnNames';
import userNotification from './userNotification';
import EmailNotifications from './emailNotification';

function ConfigureAllFakeData() {
    ForecastTypes.ConfigureFakeData();
    columnNames.ConfigureFakeData();
    userNotification.ConfigureFakeData();
    EmailNotifications.ConfigureFakeData();
}

const Fake = {
    ConfigureAllFakeData
};

export default Fake;
