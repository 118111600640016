import React from 'react';
import { connect } from 'react-redux';
import { setPageTitleAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcPanel } from '@driscollsinc/driscolls-react-components';

const pageTitle = 'Dashboard';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        let workAdded = {};
        let releasesAdded = {};

        for (var i = -30; i < 0; i++) {
            let date = new Date();
            date.setDate(date.getDate() + i);
            let dateString = date.toISOString();
            dateString = dateString.substring(0, dateString.indexOf('T'));

            workAdded[dateString] = Math.round(Math.random() * 20);
            releasesAdded[dateString] = Math.round(Math.random() * 4);
        }

        this.state = { workAdded, releasesAdded };
    }

    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    render() {
        let { isMasterDataInitialized } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcMain transparent>
                <DrcPanel>
                    <p>Look at my new application</p>
                </DrcPanel>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
