import dbField from './dbFieldDetails';

// acres,
//berryType,
//budgetYear,
//estGroup
//isingroup
//media
//producingWeekendDateDate
//siteName,
//treatmentType,
// weekEndDate,

const {
    applicationTreatment,
    acreageQuantity,
    brand,
    budgetCrates,
    budgetPound,
    commodity,
    dataType,
    dealSeason,
    estgroup,
    fieldType,
    forecastStartDate,
    growingRegion,
    growerName,
    growerNumber,
    hectareCalculation,
    isInGround,
    item,
    itemDescription,
    kiloCalculation,
    labelGroup,
    mediaType,
    microclimate,
    model,
    offset,
    packagingForecast,
    plantType,
    plantingMonth,
    plantingYear,
    planningGroup,
    producingAreaCode,
    producingAreaName,
    producingDay,
    producingWeekendDate,
    producingPoolWeek,
    protection,
    ranchName,
    ranchNumber,
    reforecastQuarter,
    region,
    reviewComments,
    salesForecastDate,
    shortPoolWeek,
    site,
    siteName,
    submissionDay,
    submissionWeekEndDate,
    treatmentStartDate,
    treatmentMonth,
    timing,
    total,
    varietyCode,
    varietyName,
    warehouseNbr,
    week0,
    week1,
    week2,
    week3,
    week4,
    week5,
    week6,
    week7,
    week8,
    week9,
    week10,
    week11,
    week12,
    week13,
    weekOfyear,
    unit,
    year,
    createdBy
} = dbField;

const SFVolume = {
    year,
    producingPoolWeek,
    producingWeekendDate,
    commodity,
    fieldType,
    estgroup,
    producingAreaName,
    producingAreaCode,
    dealSeason,
    varietyCode,
    varietyName,
    mediaType,
    brand,
    budgetPound,
    budgetCrates,
    kiloCalculation,
    submissionWeekEndDate,
    shortPoolWeek,
    site,
    planningGroup,
    applicationTreatment,
    treatmentStartDate,
    timing,
    plantType,
    reforecastQuarter,
    weekOfyear,
    offset,
    dataType,
    reviewComments,
    plantingYear,
    plantingMonth,
    isInGround,
    treatmentMonth,
    createdBy
};

const SFAcre = {
    year,
    commodity,
    fieldType,
    producingAreaName,
    producingAreaCode,
    dealSeason,
    varietyName,
    varietyCode,
    mediaType,
    acreageQuantity,
    hectareCalculation,
    submissionWeekEndDate,
    applicationTreatment,
    timing,
    plantType,
    protection,
    brand,
    treatmentStartDate,
    treatmentMonth,
    isInGround,
    planningGroup,
    site,
    dataType,
    /// plantingYear,
    /// plantingMonth,
    growerName,
    growerNumber,
    ranchName,
    ranchNumber,
    microclimate,
    createdBy
};

const SFDaily = {
    year,
    producingPoolWeek,
    producingWeekendDate,
    commodity,
    fieldType,
    estgroup: {
        ...estgroup,
        dbColumnName: 'ESTGroup'
    },
    producingAreaName,
    producingAreaCode,
    dealSeason,
    varietyName,
    varietyCode,
    applicationTreatment,
    treatmentStartDate,
    timing,
    mediaType,
    brand,
    budgetPound,
    budgetCrates,
    kiloCalculation,
    weekOfyear,
    offset,
    submissionDay,
    shortPoolWeek, //db this is int not string
    plantType,
    growingRegion,
    region,
    site,
    planningGroup,
    producingDay,
    dataType,
    reviewComments,
    treatmentMonth,
    isInGround,
    createdBy
};

const SFWarehouse = {
    model,
    unit,
    commodity,
    fieldType,
    site,
    warehouseNbr,
    planningGroup,
    item: {
        ...item,
        dbColumnName: 'ItemNumber'
    },
    itemDescription,
    forecastStartDate,
    // packagingForecast,
    // salesForecastDate,
    week0,
    week1,
    week2,
    week3,
    week4,
    week5,
    week6,
    week7,
    week8,
    week9,
    week10,
    week11,
    week12,
    week13,
    createdBy
};

const SFWeekly = {
    model,
    commodity,
    site,
    siteName,
    planningGroup,
    unit,
    fieldType,
    brand: {
        ...brand,
        dbColumnName: 'Brand'
    },
    warehouseNbr,
    labelGroup,
    item,
    itemDescription,
    forecastStartDate,
    packagingForecast,
    salesForecastDate,
    week0,
    week1,
    week2,
    week3,
    week4,
    week5,
    week6,
    week7,
    week8,
    week9,
    week10,
    week11,
    week12,
    week13,
    total,
    createdBy
};

export { SFVolume, SFAcre, SFDaily, SFWarehouse, SFWeekly };
