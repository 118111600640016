export default {
    applicationTreatment: {
        dbColumnName: 'ApplicationTreatment',
        excelColumnName: 'Application Treatment',
        name: 'Application Treatment',
        type: 'string',
        length: 45
    },
    acreageQuantity: {
        dbColumnName: 'AcreageQuantity',
        excelColumnName: 'ACRES',
        name: 'Acres',
        type: 'float'
    },
    brand: {
        dbColumnName: 'BrandCategory',
        excelColumnName: 'Brand',
        name: 'Brand',
        type: 'string',
        length: 45
    },
    budgetCrates: {
        dbColumnName: 'Crates',
        excelColumnName: 'Crates',
        name: 'Crates',
        type: 'float'
    },
    budgetPound: {
        dbColumnName: 'LBS',
        excelColumnName: 'Lbs',
        name: 'Lbs',
        type: 'float'
    },
    commodity: {
        dbColumnName: 'Commodity',
        excelColumnName: 'Commodity',
        name: 'Commodity',
        type: 'string',
        length: 5
    },
    dataType: {
        dbColumnName: 'DataType',
        excelColumnName: 'Data Type',
        name: 'Data Type',
        type: 'string',
        length: 150
    },
    dealSeason: {
        dbColumnName: 'DealSeason',
        excelColumnName: 'Deal Season',
        name: 'Deal Season',
        type: 'string',
        length: 45
    },
    estgroup: {
        dbColumnName: 'ESTgroup',
        excelColumnName: 'Est Group',
        name: 'Est Group',
        type: 'string',
        length: 20
    },
    fieldType: {
        dbColumnName: 'FieldType',
        excelColumnName: 'Field Type',
        name: 'Field Type',
        type: 'string',
        length: 20
    },
    forecastStartDate: {
        dbColumnName: 'ForecastStartDate',
        excelColumnName: 'Forecast Start Date',
        name: 'Forecast Start Date',
        type: 'date'
    },
    growingRegion: {
        dbColumnName: 'GrowingRegion',
        excelColumnName: 'Growing Region',
        name: 'Growing Region',
        type: 'string',
        length: 45
    },
    growerName: {
        dbColumnName: 'GrowerName',
        excelColumnName: 'Grower Name',
        name: 'Grower Name',
        type: 'string'
    },
    growerNumber: {
        dbColumnName: 'GrowerNumber',
        excelColumnName: 'Grower Number',
        name: 'Grower Number',
        type: 'number'
    },
    hectareCalculation: {
        dbColumnName: 'HectareCalculation',
        excelColumnName: 'HECTARES',
        name: 'Hectares',
        type: 'float'
    },
    item: {
        dbColumnName: 'Item',
        excelColumnName: 'SKU',
        name: 'SKU',
        type: 'number'
    },
    itemDescription: {
        dbColumnName: 'ItemDescription',
        excelColumnName: 'SKU Description',
        name: 'SKU Description',
        type: 'string',
        length: 100
    },
    isInGround: {
        dbColumnName: 'IsInGround',
        excelColumnName: 'Is In Ground',
        name: 'Is In Ground',
        type: 'string',
        length: 3
    },
    kiloCalculation: {
        dbColumnName: 'Kilos',
        excelColumnName: 'Kilos',
        name: 'Kilos',
        type: 'float'
    },
    labelGroup: {
        dbColumnName: 'LabelGroup',
        excelColumnName: 'LabelGroup',
        name: 'Label Group',
        type: 'string',
        length: 45
    },
    microclimate: {
        dbColumnName: 'Microclimate',
        excelColumnName: 'Microclimate',
        name: 'Microclimate',
        type: 'string'
    },
    mediaType: {
        dbColumnName: 'MediaType',
        excelColumnName: 'Media Type',
        name: 'Media Type',
        type: 'string',
        length: 45
    },
    model: {
        dbColumnName: 'Model',
        excelColumnName: 'Model',
        name: 'Model',
        type: 'string',
        length: 100
    },
    offset: {
        dbColumnName: 'Offset',
        excelColumnName: 'Offset',
        name: 'Offset',
        type: 'number'
    },
    packagingForecast: {
        dbColumnName: 'PackagingForecast',
        excelColumnName: 'Packaging Forecast',
        name: 'Packaging Forecast',
        type: 'number'
    },
    plantType: {
        dbColumnName: 'PlantType',
        excelColumnName: 'Plant Type',
        name: 'Plant Type',
        type: 'string',
        length: 20
    },
    plantingMonth: {
        dbColumnName: 'PlantingMonth',
        excelColumnName: 'Planting Month',
        name: 'Planting Month',
        type: 'string',
        length: 3
    },
    plantingYear: {
        dbColumnName: 'PlantingYear',
        excelColumnName: 'Planting Year',
        name: 'Planting Year',
        type: 'number'
    },
    planningGroup: {
        dbColumnName: 'PlanningGroup',
        excelColumnName: 'Planning Group',
        name: 'Planning Group',
        type: 'string',
        length: 100
    },
    producingAreaCode: {
        dbColumnName: 'ProducingAreaCode',
        excelColumnName: 'Producing Area Code',
        name: 'Producing Area Code',
        type: 'string',
        length: 5
    },
    producingAreaName: {
        dbColumnName: 'ProducingAreaName',
        excelColumnName: 'Producing Area Name',
        name: 'Producing Area Name',
        type: 'string',
        length: 45
    },
    producingDay: {
        dbColumnName: 'ProducingDay',
        excelColumnName: 'Producing Day',
        name: 'Producing Day',
        type: 'date'
    },
    producingWeekendDate: {
        dbColumnName: 'ProducingWeekendDate',
        excelColumnName: 'Producing Weekend Date',
        name: 'Producing Weekend Date',
        type: 'date'
    },
    producingPoolWeek: {
        dbColumnName: 'ProducingPoolWeek',
        excelColumnName: 'Producing Pool Week',
        name: 'Producing Pool Week',
        type: 'number'
    },
    protection: {
        dbColumnName: 'Protection',
        excelColumnName: 'Protection',
        name: 'Protection',
        type: 'string',
        length: 45
    },
    reforecastQuarter: {
        dbColumnName: 'ReforecastQuarter',
        excelColumnName: 'Reforecast Quarter',
        name: 'Reforecast Quarter',
        type: 'string',
        length: 10
    },
    ranchName: {
        dbColumnName: 'RanchName',
        excelColumnName: 'Ranch Name',
        name: 'Ranch Name',
        type: 'string'
    },
    ranchNumber: {
        dbColumnName: 'RanchNumber',
        excelColumnName: 'Ranch Number',
        name: 'Ranch Number',
        type: 'number'
    },
    region: {
        dbColumnName: 'Region',
        excelColumnName: 'Region',
        name: 'Region',
        type: 'string',
        length: 45
    },
    reviewComments: {
        dbColumnName: 'ReviewComments',
        excelColumnName: 'Review Comments',
        name: 'Review Comments',
        type: 'string',
        length: 150
    },
    salesForecastDate: {
        dbColumnName: 'SalesForecast',
        excelColumnName: 'Sales Forecast',
        name: 'Sales Forecast',
        type: 'number'
    },
    shortPoolWeek: {
        dbColumnName: 'ShortPoolWeek',
        excelColumnName: 'Short Pool Week',
        name: 'Short Pool Week',
        type: 'string',
        length: 45
    },
    site: {
        dbColumnName: 'Site',
        excelColumnName: 'Site',
        name: 'Site',
        type: 'string',
        length: 3 //Acre
    },
    siteName: {
        dbColumnName: 'SiteName',
        excelColumnName: 'Site Name',
        name: 'Site Name',
        type: 'string',
        length: 45
    },
    submissionDay: {
        dbColumnName: 'SubmissionDay',
        excelColumnName: 'Submission Day',
        name: 'Submission Day',
        type: 'date'
    },
    submissionWeekEndDate: {
        dbColumnName: 'SubmissionWeekend',
        excelColumnName: 'Submission Week End date',
        name: 'Submission Week End date',
        type: 'date'
    },
    treatmentStartDate: {
        dbColumnName: 'TreatmentStartDate',
        excelColumnName: 'Treatment Start Date',
        name: 'Treatment Start Date',
        type: 'date'
    },
    treatmentMonth: {
        dbColumnName: 'TreatmentMonth',
        excelColumnName: 'Treatment Month',
        name: 'Treatment Month',
        type: 'string',
        length: 3
    },
    timing: {
        dbColumnName: 'Timing',
        excelColumnName: 'Timing',
        name: 'Timing',
        type: 'string',
        length: 20
    },
    total: {
        dbColumnName: 'Total',
        excelColumnName: 'Total',
        name: 'Total',
        type: 'number'
    },
    varietyCode: {
        dbColumnName: 'VarietyCode',
        excelColumnName: 'Variety Code',
        name: 'Variety Code',
        type: 'string',
        length: 10
    },
    varietyName: {
        dbColumnName: 'VarietyName',
        excelColumnName: 'Variety Name',
        name: 'Variety Name',
        type: 'string',
        length: 45
    },
    warehouseNbr: {
        dbColumnName: 'WarehouseNbr',
        excelColumnName: 'Warehouse',
        name: 'Warehouse',
        type: 'string',
        length: 4
    },
    week0: {
        dbColumnName: 'Week0',
        excelColumnName: 'Wk0',
        name: 'Wk0',
        type: 'number'
    },
    week1: {
        dbColumnName: 'Week1',
        excelColumnName: 'Wk1',
        name: 'Wk1',
        type: 'number'
    },
    week2: {
        dbColumnName: 'Week2',
        excelColumnName: 'Wk2',
        name: 'Wk2',
        type: 'number'
    },
    week3: {
        dbColumnName: 'Week3',
        excelColumnName: 'Wk3',
        name: 'Wk3',
        type: 'number'
    },
    week4: {
        dbColumnName: 'Week4',
        excelColumnName: 'Wk4',
        name: 'Wk4',
        type: 'number'
    },
    week5: {
        dbColumnName: 'Week5',
        excelColumnName: 'Wk5',
        name: 'Wk5',
        type: 'number'
    },
    week6: {
        dbColumnName: 'Week6',
        excelColumnName: 'Wk6',
        name: 'Wk6',
        type: 'number'
    },
    week7: {
        dbColumnName: 'Week7',
        excelColumnName: 'Wk7',
        name: 'Wk7',
        type: 'number'
    },
    week8: {
        dbColumnName: 'Week8',
        excelColumnName: 'Wk8',
        name: 'Wk8',
        type: 'number'
    },
    week9: {
        dbColumnName: 'Week9',
        excelColumnName: 'Wk9',
        name: 'Wk9',
        type: 'number'
    },
    week10: {
        dbColumnName: 'Week10',
        excelColumnName: 'Wk10',
        name: 'Wk10',
        type: 'number'
    },
    week11: {
        dbColumnName: 'Week11',
        excelColumnName: 'Wk11',
        name: 'Wk11',
        type: 'number'
    },
    week12: {
        dbColumnName: 'Week12',
        excelColumnName: 'Wk12',
        name: 'Wk12',
        type: 'number'
    },
    week13: {
        dbColumnName: 'Week13',
        excelColumnName: 'Wk13',
        name: 'Wk13',
        type: 'number'
    },
    weekOfyear: {
        dbColumnName: 'WeekOfYear',
        excelColumnName: 'Week Of Year',
        name: 'Week Of Year',
        type: 'number'
    },
    unit: {
        dbColumnName: 'Unit',
        excelColumnName: 'Unit',
        name: 'Unit',
        type: 'string',
        length: 20
    },
    year: {
        dbColumnName: 'Year',
        excelColumnName: 'YEAR',
        name: 'Year',
        type: 'number'
    },
    createdBy: {
        dbColumnName: 'CreatedBy',
        excelColumnName: 'Created By',
        name: 'CreatedBy',
        type: 'string',
        length: 3,
        isExcluded: true
    }
};
