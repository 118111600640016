import { Middleware } from '@driscollsinc/one-ring';
import EmailNotifications from '../json/emailNotification';

const EmailFunctions = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('UserEmailNotification')) {
        Middleware.RegisterFakeDataHandler('UserEmailNotification', EmailNotifications, EmailFunctions);
    }
}

const EmailNotificationFake = {
    ConfigureFakeData
};

export default EmailNotificationFake;
