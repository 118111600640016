import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcImage,
    DrcPageNotFound,
    DrcBackdrop,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcLoading,
    DrcMain,
    DrcPanel,
    DrcMediaQueries,
    Helmet,
    DrcPageWarning,
    DrcVersionInfo,
    DrcPageLogin,
    DrcImplicitCallback,
    DrcTranslations
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities, DuAuthenticationUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import { hideErrorDialogAction, showLoadingScreenAction, hideLoadingScreenAction, addInfo, setErrorsAction } from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import { createHashHistory } from 'history';
import MasterDataUtilities from './data/MasterDataUtilities';
import LoggingUtilities from './data/LoggingUtilities';
import RoleName from './components/RoleName';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import Dashboard from './pages/Dashboard';
import Upload from './pages/Upload';
import UploadReview from './pages/UploadReview';
import UploadedFiles from './pages/UploadedFiles';
import ReviewFilesList from './pages/ReviewFilesList';
import ReviewFile from './pages/ReviewFile';
import EditViewFile from './pages/EditViewFile';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import UploadIcon from '@material-ui/icons/CloudUpload';
import EventIcon from '@material-ui/icons/Event';
import Folder from '@material-ui/icons/Folder';
import Settings from '@material-ui/icons/Settings';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import LogoPng from './Images/Logo_Small_Transparent.png';
import LogoWebP from './Images/Logo_Small_Transparent.webp';
import BackgroundPng from './Images/Wood_Blue_Full_Hd.png';
import BackgroundWebP from './Images/Wood_Blue_Full_Hd.webp';

const ConfigManagement = lazy(() => import('./pages/ConfigManagement'));
const Releases = lazy(() => import('./pages/Releases'));
const HealthCheck = lazy(() => import('./pages/HealthCheck'));

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const superAdminGroups = adminGroups.filter((g) => g.toLowerCase().includes('super') || g.toLowerCase().includes('app'));

const allLinks = [
    { title: 'Upload', url: '/Upload/', icon: <UploadIcon /> },
    // { title: 'Profile', url: '/Profile/', icon: <PersonIcon /> },
    { title: 'My Files', url: '/MyFiles/', icon: <Folder /> },
    { title: 'Review Files', url: '/Review/Files/', icon: <AssignmentTurnedIn /> },
    { title: 'Admin Panel', url: '/Config/Management', icon: <Settings />, requiresGroups: adminGroups },
    { title: 'Releases', url: '/Releases/', icon: <EventIcon />, requiresGroups: superAdminGroups },
    { title: 'LogOut', url: '/LogOut/', icon: <DirectionsRun /> }
];

const allGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || [], window.config.OKTA_READ_ONLY_GROUPS || []);

const reviewGroups = window.config.OKTA_REVIEW_GROUPS || [];

const SITE_NAME = 'Forecast';

const styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        }
    },
    logo: {
        height: theme.spacing(5),
        float: 'right',
        width: '100vw',
        left: 0,
        margin: 0,
        position: 'absolute',
        objectFit: 'contain',
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        background: 'transparent',
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 97),
        '& .title': {
            textShadow: 'none',
            transform: 'translateY(0.5rem)'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsl(197, 100%, 11%, 1)',
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        }
    },
    loader: {
        '& > div': {
            width: 320,
            left: 'calc(50vw - 160px)'
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.blue)
        };

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: DuAuthenticationUtilities.OnAuthRequired,
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        //This will be needed in the next major version of Okta-Auth
        //this.oktaAuth.start();

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: {
                ...DuCommonTranslations.COMMON_TRANSLATIONS,
                ...DrcTranslations.DRC_PAGE_DATA_MAINTENANCE_TRANSLATIONS
            },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Upload/');

        this.closeErrorDialog = this.closeErrorDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setErrorsAction, false);
    }

    closeErrorDialog() {
        this.props.hideErrorDialog();
    }

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    render() {
        let { classes } = this.props;

        return (
            <DrcThemeProvider theme={this.state.theme}>
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <DrcSecureHeader
                            title={this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}
                            allLinks={allLinks}
                            fullWidth={true}
                            logo={<DrcImage src={LogoPng} webp={LogoWebP} className={classes.logo} alt="Driscoll's Logo" />}
                            className={this.props.classes.header}
                        >
                            <RoleName />
                        </DrcSecureHeader>
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <DrcPageLogin
                                            {...props}
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                        />
                                    )}
                                />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/Upload/Review/:uploadType/:berryType/"
                                    exact
                                    component={UploadReview}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute
                                    path="/Upload/Review/:uploadType/:berryType/:fileId"
                                    exact
                                    component={UploadReview}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Upload/" exact component={Upload} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Upload/:berryType/:fileType/:fileId" exact component={Upload} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                {/* <DrcSecureGroupRoute path="/Profile/" exact component={Profile} groupsAllowed={allGroups} /> */}
                                <DrcSecureGroupRoute path="/HealthCheck/" exact component={HealthCheck} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/MyFiles/" exact component={UploadedFiles} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Review/Files/" exact component={ReviewFilesList} groupsAllowed={reviewGroups} />
                                <DrcSecureGroupRoute path="/Review/Files/:fileId/" exact component={ReviewFile} groupsAllowed={reviewGroups} />
                                <DrcSecureGroupRoute
                                    path="/EditViewFile/:uploadType/:berryType/:fileId"
                                    exact
                                    component={EditViewFile}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Config/Management" component={ConfigManagement} groupsAllowed={adminGroups} />
                                <Route
                                    path="/implicit/callback"
                                    render={(props) => (
                                        <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
                                    )}
                                />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={this.props.isAdmin} />
                    <DrcBackdrop
                        className={this.props.classes.loader}
                        isLoading
                        loadingMessage={this.props.loadingScreenMessage}
                        show={this.props.showLoadingScreen}
                    />
                    <DrcDialog
                        isError
                        title={this.props.errorDialog.title}
                        open={this.props.errorDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeErrorDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.errorDialog.content}
                    </DrcDialog>
                </Router>
                {/* doesnt have an option to close, need to implement */}
                {this.props.notification && (
                    <DrcPageWarning isError={this.props.isNotificationError} anchorVertical="top" message={this.props.notification} />
                )}
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        errorDialog: state.rootReducer.errorDialog,
        pageTitle: state.rootReducer.pageTitle,
        notification: state.notificationReducer.message,
        isNotificationError: state.notificationReducer.isError,
        isAdmin: state.masterReducer.isAdmin
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideErrorDialog: () => dispatch(hideErrorDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setErrorsAction: (title, errors) => dispatch(setErrorsAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
