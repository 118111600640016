import { Middleware } from '@driscollsinc/one-ring';

const BrandMapping = [Middleware.CreateMapping('Descript', 'Descript'), Middleware.CreateArrayMapping('Data', 'Data')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Brand', 'GET')) {
        Middleware.RegisterMapping('Brand', 'GET', BrandMapping);
    }
}

const brand = {
    ConfigureMiddleware
};

export default brand;
