import { Middleware } from '@driscollsinc/one-ring';

const sfUserFileMappings = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Name', 'Name'),
    Middleware.CreateMapping('DataType', 'Datatype'),
    Middleware.CreateMapping('Frequency', 'Frequency'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('Version', 'Version'),
    Middleware.CreateMapping('ReviewComment', 'ReviewComment'),
    Middleware.CreateMapping('ReviewedBy', 'ReviewedBy'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('Action', 'Action'),
    Middleware.CreateArrayMapping('FileMasterDetails', 'FileMasterDetails')
];

const sfUserFileWithdrawMapping = [
    Middleware.CreateMapping('FileNbr', 'FileNbr'),
    Middleware.CreateMapping('Status', 'Status'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ReviewComment', 'ReviewComment')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SFUserFiles', 'GET')) {
        Middleware.RegisterMapping('SFUserFiles', 'GET', sfUserFileMappings);
        Middleware.RegisterMapping('SFUserFiles', 'POST', sfUserFileWithdrawMapping);
    }
}

const sfUserFile = {
    ConfigureMiddleware
};

export default sfUserFile;
