// This would eventually be api
export const SET_USER_BERRY_ROLES_SUCCESS = 'SET_USER_BERRY_ROLES_SUCCESS';
export const setUserBerryRoles = (berries) => ({
    type: SET_USER_BERRY_ROLES_SUCCESS,
    payload: berries
});
export const SET_USER_FORECAST_ROLES_SUCCESS = 'SET_USER_FORECAST_ROLES_SUCCESS';
export const setUserForecastRoles = (uploadTypes) => {
    return {
        type: SET_USER_FORECAST_ROLES_SUCCESS,
        payload: uploadTypes
    };
};

export const GET_USER_UPLOADED_FILES_SUCCESS = 'GET_USER_UPLOADED_FILES_SUCCESS';
export const setUploadedUserFiles = (userFiles) => {
    return {
        type: GET_USER_UPLOADED_FILES_SUCCESS,
        payload: userFiles
    };
};

export const WITHDRAW_FILE_SUCCESS = 'WITHDRAW_FILE_SUCCESS';
export const withdrawFile = (data) => ({
    type: WITHDRAW_FILE_SUCCESS,
    payload: data
});

// This is not to be used
export const SET_WITHDRAWN_FILE_DATA = 'SET_WITHDRAWN_FILE_DATA';
export const setUserWithdrawnFileData = (data) => ({
    type: SET_WITHDRAWN_FILE_DATA,
    payload: data
});

export const SET_VIEW_EDIT_FILE_DATA = 'SET_VIEW_EDIT_FILE_DATA';
export const setUserFileData = (data, fileId, totalRowCount) => ({
    type: SET_VIEW_EDIT_FILE_DATA,
    payload: { data, fileId, totalRowCount }
});

export const CLEAR_VIEW_EDIT_FILE_DATA = 'CLEAR_VIEW_EDIT_FILE_DATA';
export const clearUserFileData = () => ({
    type: CLEAR_VIEW_EDIT_FILE_DATA,
    payload: null
});

export const SET_GRID_EDITABLE = 'SET_GRID_EDITABLE';
export const setGridEditable = (value) => ({
    type: SET_GRID_EDITABLE,
    payload: value
});

export const SET_EMAIL_NOTIFICATION_ROLES = 'SET_EMAIL_NOTIFICATION_ROLES';
export const setEmailNotificationRole = (value) => ({
    type: SET_EMAIL_NOTIFICATION_ROLES,
    payload: value
});

export const SET_REGIONAL_FORECASTER_PRODUCING_AREA = 'SET_REGIONAL_FORECASTER_PRODUCING_AREA';
export const setRegionalUserProducingAreas = (value) => ({
    type: SET_REGIONAL_FORECASTER_PRODUCING_AREA,
    payload: value
});

export const SET_USER_ROLE_NAME = 'SET_USER_ROLE_NAME';
export const setUserRoleName = (value) => ({
    type: SET_USER_ROLE_NAME,
    payload: value
});
