import AWS from 'aws-sdk';

// AccessKey, SecretKey, SessionToken will be from mule
export const getInstance = (AccessKey, SecretKey, SessionToken) => {
    AWS.config.update({
        region: window.config.AWS_REGION,
        credentials: new AWS.Credentials(AccessKey, SecretKey, SessionToken)
    });

    return new AWS.S3();
};

export const uploadToS3 = (s3Instance, file, filename) => {
    return new Promise((resolve, reject) => {
        const params = {
            Body: file,
            Bucket: window.config.S3_BUCKET,
            Key: filename,
            ACL: 'private' // need to check on this
        };

        let options = { partSize: 100 * 1024 * 1024, queueSize: 1 }; //allow 100MB uploads (this is the recommended size by AWS now)
        s3Instance.upload(params, options, (err, res) => {
            if (err) {
                return reject(err);
            }

            return resolve(res); // res.key stores the object name that will be sent to ule
        });
    });
};
