import { SET_FILE_IN_REVIEW, SET_REVIEW_LIST, CLEAR_REVIEW_FILE_DATA } from '../actions/FileActions';
import { parseISO } from 'date-fns';

const initialState = {
    fileInReview: {
        FileData: []
    },
    reviewFilesList: []
};

const parseDate = (date) => {
    let dateFixed = date.toString().trim();

    //Fixing date to have a T instead of a space that are correct in some ISO 8601
    if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
        dateFixed = dateFixed.replace(' ', 'T');
    }

    //All dates should be coming in as UTC (Zulu)
    if (!dateFixed.includes('Z') && dateFixed.includes('T')) {
        dateFixed += 'Z';
    }

    return parseISO(dateFixed);
};

const FileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILE_IN_REVIEW:
            let fileData = [];
            if (state.fileInReview.FileData.length >= state.fileInReview.totalRowCount) {
                fileData = [...action.payload.data];
            } else {
                fileData = [...state.fileInReview.FileData, ...action.payload.data];
            }

            return Object.assign({}, state, {
                fileInReview: {
                    FileData: fileData,
                    totalRowCount: action.payload.totalRowCount,
                    FileNbr: action.payload.fileId
                }
            });
        case SET_REVIEW_LIST:
            let filteredPayload = action.payload.sort((a, b) => {
                let aDateToFilter = a.CreatedDateTime ? parseDate(a.CreatedDateTime) : new Date(a.ID);
                let bDateToFilter = b.CreatedDateTime ? parseDate(b.CreatedDateTime) : new Date(b.ID);

                if (aDateToFilter > bDateToFilter) {
                    return -1;
                }

                if (bDateToFilter > aDateToFilter) {
                    return 1;
                }

                return 0;
            });

            return Object.assign({}, state, {
                reviewFilesList: filteredPayload
            });
        case CLEAR_REVIEW_FILE_DATA:
            return {
                ...state,
                fileInReview: {
                    FileData: [],
                    totalRowCount: 0,
                    FileNbr: null
                }
            };
        default:
            return state;
    }
};

export default FileReducer;
