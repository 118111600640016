export const makeForecastReviewFilter = (rows) => {
  let optionsArray = [];
  // To make filter dropdown options for forecastType column
  rows.forEach((row, index) => {
    let forecastType = row.Datatype.props.children;
    if (!optionsArray.some((option) => forecastType !== null && option.value.toLowerCase() === forecastType.toLowerCase())) {
      optionsArray.push({
        label: forecastType,
        value: forecastType
      });
    }
  });
  return optionsArray;
};