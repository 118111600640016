// this is the mapping for reportColumnsMap

import { Middleware } from '@driscollsinc/one-ring';

const producingAreasMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ProducingAreas', 'GET')) {
        Middleware.RegisterMapping('ProducingAreas', 'GET', producingAreasMapping);
    }
}

const producingAreas = {
    ConfigureMiddleware
};

export default producingAreas;
