const uploaded = 'Uploaded'; //When first uploaded this will be the status
const readyForReview = 'Ready for Review'; //When API is done processing file it will set this
const withdrawn = 'Withdrawn'; //When user withdraws their own file
const inReview = 'In Review'; //When a reviewer first opens a file to review
const approved = 'Approved'; //When a reviewer approves a file
const notApproved = 'Not Approved'; //When a reviewer rejects a file
const reUploaded = 'ReUploaded'; // when a file is withdrawn and reuploaded
const reSubmitted = 'ReSubmitted'; //When file is reuploaded

export default {
    uploaded,
    readyForReview,
    withdrawn,
    inReview,
    approved,
    notApproved,
    reUploaded,
    reSubmitted
};
