import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED,
    SET_IS_ADMIN,
    GET_MDR_COLUMN_DATA,
    SET_UPLOAD_TYPES,
    SET_COLUMN_NAMES,
    SET_RANCHES,
    SET_VARIETY,
    SET_WAREHOUSE,
    SET_PRODUCING_AREAS,
    SET_RECEIVING_AREA,
    SET_PLANNING_GROUP,
    SET_BRAND
} from '../actions/MasterActions';
import DataValidation from '../data/DrcDataValidation';
import validationTypes, { matchDbLength, checkValidValues } from '../data/validationTypes';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/Upload/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    isInitialized: false,
    redirectUrl: REDIRECT_URL,
    isAdmin: false,
    mdrData: {},
    columnNames: {},
    dbToExcelColumnMap: {},
    ranches: [],
    variety: [],
    warehouse: [],
    producingAreas: [],
    producingAreaMapping: {},
    producingAreaNumberArray: [],
    planningGroup: [],
    receivingArea: [] //This includes site(code) and siteName
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            };
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });
        case GET_MDR_COLUMN_DATA:
            return Object.assign({}, state, {
                mdrData: action.payload
            });
        case SET_UPLOAD_TYPES:
            return Object.assign({}, state, {
                uploadTypes: action.payload
            });
        case SET_RANCHES:
            return Object.assign({}, state, {
                ranches: action.payload
            });
        case SET_VARIETY:
            return Object.assign({}, state, {
                variety: action.payload
            });
        case SET_WAREHOUSE:
            return Object.assign({}, state, {
                warehouse: action.payload
            });
        case SET_RECEIVING_AREA: //This includes site(code) and siteName
            return Object.assign({}, state, {
                receivingArea: action.payload
            });
        case SET_PLANNING_GROUP:
            return Object.assign({}, state, {
                planningGroup: action.payload
            });
        case SET_PRODUCING_AREAS:
            let producingAreaMapping = {};

            let producingAreaNameArray = [];
            let producingAreaNumberArray = [];

            (action.payload || []).forEach((producingArea) => {
                producingAreaMapping[producingArea.Code] = producingArea.Descript;
                producingAreaNameArray.push(producingArea.Descript);
                producingAreaNumberArray.push(Number(producingArea.Code));
            });
            return Object.assign({}, state, {
                producingAreas: action.payload,
                producingAreaMapping,
                producingAreaNameArray,
                producingAreaNumberArray
            });
        case SET_COLUMN_NAMES:
            let ApiResponse = action.payload;
            let dbToExcelColumnMap = {};
            Object.keys(ApiResponse).forEach((uploadType) => {
                Object.keys(ApiResponse[uploadType]).forEach((commodity) => {
                    Object.keys(ApiResponse[uploadType][commodity]).forEach((columnName) => {
                        let column = ApiResponse[uploadType][commodity][columnName];
                        //This will ensure that only require field get all default validations and we can apply other validation
                        //on option fileds so that they do not break in backend

                        let validationList = column.isRequired && validationTypes[columnName] ? [...validationTypes[columnName]] : [];

                        if (column.isRequired) validationList.unshift(checkValidValues);

                        if (column.type === 'string' && column.length) validationList.push(matchDbLength(column.length));
                        if (column.type === 'date') validationList.push(DataValidation.ValidateDate);
                        if (!dbToExcelColumnMap[columnName]) {
                            const field = ApiResponse[uploadType][commodity][columnName];
                            dbToExcelColumnMap[columnName] = {
                                excelName: field.excelColumnName,
                                isRequired: field.isRequired,
                                notNull: field.notNull
                            };
                        }

                        ApiResponse[uploadType][commodity][columnName] = {
                            ...column,
                            key: columnName,
                            validationType: [...validationList]
                        };
                    });
                });
            });

            return Object.assign({}, state, {
                columnNames: ApiResponse,
                dbToExcelColumnMap: dbToExcelColumnMap
            });
        case SET_BRAND:
            return Object.assign({}, state, {
                brand: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
