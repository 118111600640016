const getFileDataMapping = (fileType) => {
    switch (fileType) {
        case 'AnnualBudgetVolume':
            return 'sfVolume';

        case 'QuarterlyReforecastVolume':
            return 'sfVolume';

        case 'WeeklyRegionalForecast':
            return 'sfVolume';

        case 'AnnualBudgetAcres':
            return 'SFAcre';

        case 'QuarterlyPlantedAcres':
            return 'SFAcre';

        case 'QuarterlyReforecastAcres':
            return 'SFAcre';

        case 'FourteenDayDailyEstimates':
            return 'SFDaily';

        case 'ThirteenWeekSupplyByItem':
            return 'sfWeekly';

        case 'ThirteenWeekSupplyByUnit':
            return 'sfWeekly';
            
        case 'WarehouseAssignment':
            return 'sfWarehouse';

        default:
            //TODO:  On no match throw an error and return
            return;
    }
};
export default getFileDataMapping;
