import { Middleware } from '@driscollsinc/one-ring';

const s3CredentialsMapping = [
    Middleware.CreateMapping('AccessKey', 'AccessKey'),
    Middleware.CreateMapping('SecretKey', 'SecretKey'),
    Middleware.CreateArrayMapping('SessionToken', 'SessionToken')
];

const s3FileNameToMule = [Middleware.CreateMapping('FileName', 'FileName')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('S3Credentials', 'GET')) {
        Middleware.RegisterMapping('S3Credentials', 'GET', s3CredentialsMapping);
        Middleware.RegisterMapping('S3Credentials', 'POST', s3FileNameToMule);
    }
}

const s3Credentials = {
    ConfigureMiddleware
};

export default s3Credentials;
