//Will be getting this from API later on
// See reportColumnsMap.js for comments on change in usage.
// Format is the same but strings are now used for column validation.
const commodity = 'Commodity';
const berryType = 'BERRY TYPE';
const fieldType = 'Field Type';
const dataType = 'Data Type';
const estGroup = 'Est Group';
const producingAreaName = 'Producing Area Name';
const producingAreaCode = 'Producing Area Code'; //'Producing Area Number in template'
const producingWeekendDate = 'Producing Weekend Date';
const varietyName = 'Variety Name';
const varietyCode = 'Variety Code'; //"Variety Number in template"
const varietyKey = 'VARIETY KEY';
const treatmentType = 'Treatment Type';
const applicationTreatment = 'Application Treatment';
const treatmentMonth = 'Treatment Month';
const media = 'Media Type';
const brand = 'Brand';
const siteName = 'Site Name';
const siteCode = 'Site';
const planningGroup = 'Planning Group';
const timing = 'Timing';
const ground = 'Is In Ground';
const plantType = 'Plant Type';
const growingRegion = 'Growing Region';
const isInGroup = ' Is In Group';

const budgetYear = 'BUDGET YEAR';
const plantingYear = 'Planting Year';
const producingPoolWeek = 'Producing Pool Week';
const shortPool = 'Short Pool Week';
const budgetPound = 'Lbs';
const budgetCrates = 'Crates';
const kiloCalculation = 'Kilos';
const year = 'YEAR';
const dealSeason = 'Deal Season';
const submissionWeekEndDate = 'Submission Week End date';
const treatmentStartDate = 'Treatment Start Date';
const weekEndDate = 'Week End Date';
const acreageQuantity = 'ACRES';
const hectareCalculation = 'HECTARE CALCULATION';
const acres = 'ACRES';
const hectares = 'HECTARES';
const protection = 'Protection';

export default {
    commodity,
    berryType,
    fieldType,
    dataType,
    estGroup,
    planningGroup,
    producingAreaCode,
    producingAreaName,
    producingWeekendDate,
    varietyCode,
    varietyKey,
    varietyName,
    treatmentType,
    applicationTreatment,
    treatmentMonth,
    media,
    brand,
    siteCode,
    siteName,
    timing,
    ground,
    plantType,
    growingRegion,
    isInGroup,

    budgetYear,
    plantingYear,
    year,
    producingPoolWeek,
    shortPool,
    budgetPound,
    kiloCalculation,
    budgetCrates,
    dealSeason,
    submissionWeekEndDate,
    treatmentStartDate,
    weekEndDate,
    acreageQuantity,
    hectareCalculation,
    acres,
    hectares,
    protection
};
