import { Middleware } from '@driscollsinc/one-ring';

const ranchesMapping = [
    Middleware.CreateMapping('RanchNbr', 'RanchNbr'),
    Middleware.CreateMapping('OverallStatus', 'OverallStatus'),
    Middleware.CreateMapping('BerryType', 'BerryType'),
    Middleware.CreateMapping('GrowerNbr', 'GrowerNbr'),
    Middleware.CreateMapping('GrowerName', 'GrowerName'),
    Middleware.CreateMapping('CommodityId', 'CommodityId'),
    Middleware.CreateMapping('FieldType', 'FieldType'),
    Middleware.CreateMapping('GrowingDistrict', 'GrowingDistrict'),
    Middleware.CreateMapping('GrowerGroup', 'GrowerGroup'),
    Middleware.CreateMapping('CustomerAcctNbr', 'CustomerAcctNbr'),
    Middleware.CreateMapping('ProducingArea', 'ProducingArea'),
    Middleware.CreateMapping('LastModified', 'LastModified'),
    Middleware.CreateMapping('ProducingAcres', 'ProducingAcres'),
    Middleware.CreateMapping('Name', 'Name'),
    Middleware.CreateMapping('VendorAcctNbr', 'VendorAcctNbr'),
    Middleware.CreateMapping('Acres', 'Acres'),
    Middleware.CreateMapping('GrowingRegion', 'GrowingRegion'),
    Middleware.CreateMapping('ReceivingArea', 'ReceivingArea'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Ranches', 'GET')) {
        Middleware.RegisterMapping('Ranches', 'GET', ranchesMapping);
    }
}

const ranchList = {
    ConfigureMiddleware
};

export default ranchList;
