import { Middleware } from '@driscollsinc/one-ring';

const userForecastRolesMapping = [Middleware.CreateMapping('ForecastTypes', 'ForecastTypes')];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserUploadTypeRoles', 'GET')) {
        Middleware.RegisterMapping('UserUploadTypeRoles', 'GET', userForecastRolesMapping);
    }
}

const UserUploadTypeRoles = {
    ConfigureMiddleware
};

export default UserUploadTypeRoles;
