export const SET_FILE_IN_REVIEW = 'SET_FILE_IN_REVIEW';
export const setFileInReview = (data, fileId, totalRowCount ) => (dispatch) => {
    dispatch({
        type: SET_FILE_IN_REVIEW,
        payload: { data, fileId, totalRowCount }
    });
};

// Saves ReviewFilesList data in reducer
export const SET_REVIEW_LIST = 'SET_REVIEW_LIST';
export const setReviewList = (data) => ({
    type: SET_REVIEW_LIST,
    payload: data
});

export const CLEAR_REVIEW_FILE_DATA = 'CLEAR_REVIEW_FILE_DATA';
export const clearUserReviewFileData = () => ({
    type: CLEAR_REVIEW_FILE_DATA,
    payload: null
});