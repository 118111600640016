import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import userRole from '../data/roleUtilities';
const styles = (theme) => ({
    role: {
        float: 'right',
        position: 'relative',
        top: -34,
        right: 10,
        fontSize: 16,
        color: theme.palette.primary.light,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.primary.contrastText
        }
    }
});

class RoleName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInAs: ''
        };
    }

    componentDidUpdate() {
        this.getGroup();
    }

    getGroup = async () => {
        let token = await this.props.authState?.accessToken;

        if (!token || token.length <= 0) {
            if (this.state.loggedInAs !== '') {
                this.setState({ loggedInAs: '' });
            }

            return;
        }
        let name = userRole(token);
        if (this.state.loggedInAs !== name) {
            this.setState({ loggedInAs: name });
        }
    };

    render() {
        let { classes } = this.props;

        return <div className={classes.role}>{this.state.loggedInAs}</div>;
    }
}

export default withOktaAuth(withStyles(styles)(RoleName));
