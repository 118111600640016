import { Middleware } from '@driscollsinc/one-ring';

const appRoleMapping = [
    Middleware.CreateMapping('Upload', 'Upload'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('RoleName', 'RoleName'),
    Middleware.CreateMapping('Review', 'Review'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('Description', 'Description'),
    Middleware.CreateMapping('OKTAUserGroup', 'OKTAUserGroup'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('AppRoles', 'GET')) {
        Middleware.RegisterMapping('AppRoles', 'GET', appRoleMapping);
    }
}

const appRole = {
    ConfigureMiddleware
};

export default appRole;
