import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import masterReducer from './MasterReducer';
import UploadReducer from './UploadReducer';
import userReducer from './UserReducer';
import notificationReducer from './NotificationReducer';
import FileReducer from './FileReducer';

import { SHOW_LOADING_SCREEN, HIDE_LOADING_SCREEN, SET_ERRORS, HIDE_ERROR_DIALOG, SET_PAGE_TITLE } from '../actions/actions';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    errorDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    }
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: 'Forecast: ' + action.payload
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_ERRORS:
            return {
                ...state,
                errorDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                errorDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    locale: localizeReducer,
    masterReducer,
    UploadReducer,
    userReducer,
    notificationReducer,
    FileReducer
});
