import { Middleware } from '@driscollsinc/one-ring';

const VarietyMapping = [
    Middleware.CreateMapping('Code', 'Code'),
    Middleware.CreateMapping('Descript', 'Descript'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('Variety', 'GET')) {
        Middleware.RegisterMapping('Variety', 'GET', VarietyMapping);
    }
}

const variety = {
    ConfigureMiddleware
};

export default variety;
