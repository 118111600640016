const EmailNotifications = {
    Data: {
        admin: [
            {
                Id: 1,
                Name: 'UploadSucceeded',
                Description: 'File uploads successfully',
            },
            {
                Id: 2,
                Name: 'UploadFailed',
                Description: 'File upload fails',
            },
            {
                Id: 3,
                Name: 'FileWithdrawn',
                Description: 'I withdraw my own file',
            },
            {
                Id: 4,
                Name: 'NewFileReadyForReview',
                Description: 'A new file is ready for review and I can review it',
            },
            {
                Id: 5,
                Name: 'ReviewStarted',
                Description: 'A review has started on one of my files',
            },
            {
                Id: 6,
                Name: 'ReviewAccepted',
                Description: 'My file was accepted by a reviewer',
            },
            {
                Id: 7,
                Name: 'ReviewRejected',
                Description: 'My file was rejected by a reviewer',
            }
        ],
        masterPlanner: [
            {
                Id: 1,
                Name: 'UploadSucceeded',
                Description: 'File uploads successfully',
            },
            {
                Id: 2,
                Name: 'UploadFailed',
                Description: 'File upload fails',

            },
            {
                Id: 3,
                Name: 'FileWithdrawn',
                Description: 'I withdraw my own file',
            },
            {
                Id: 5,
                Name: 'ReviewStarted',
                Description: 'A review has started on one of my files',
            },
            {
                Id: 6,
                Name: 'ReviewAccepted',
                Description: 'My file was accepted by a reviewer',
            },
            {
                Id: 7,
                Name: 'ReviewRejected',
                Description: 'My file was rejected by a reviewer',
            }
        ],
        national: [
            {
                Id: 1,
                Name: 'UploadSucceeded',
                Description: 'File uploads successfully',
            },
            {
                Id: 2,
                Name: 'UploadFailed',
                Description: 'File upload fails',
            },
            {
                Id: 3,
                Name: 'FileWithdrawn',
                Description: 'I withdraw my own file',
            },
            {
                Id: 5,
                Name: 'ReviewStarted',
                Description: 'A review has started on one of my files',
            }
        ],
        regional: [
            {
                Id: 1,
                Name: 'UploadSucceeded',
                Description: 'File uploads successfully',
            },
            {
                Id: 2,
                Name: 'UploadFailed',
                Description: 'File upload fails',
            },
            {
                Id: 5,
                Name: 'ReviewStarted',
                Description: 'A review has started on one of my files',
            },
            {
                Id: 6,
                Name: 'ReviewAccepted',
                Description: 'My file was accepted by a reviewer',
            },
            {
                Id: 7,
                Name: 'ReviewRejected',
                Description: 'My file was rejected by a reviewer',

            }
        ]
    }
};
export default EmailNotifications;
