import {
    SET_USER_BERRY_ROLES_SUCCESS,
    SET_USER_FORECAST_ROLES_SUCCESS,
    GET_USER_UPLOADED_FILES_SUCCESS,
    WITHDRAW_FILE_SUCCESS,
    SET_WITHDRAWN_FILE_DATA,
    SET_GRID_EDITABLE,
    SET_VIEW_EDIT_FILE_DATA,
    CLEAR_VIEW_EDIT_FILE_DATA,
    SET_EMAIL_NOTIFICATION_ROLES,
    SET_REGIONAL_FORECASTER_PRODUCING_AREA,
    SET_USER_ROLE_NAME
} from '../actions/UserActions';
import { getUploadTypeByDataType } from '../data/json/forecastTypes';
import camelCase from 'lodash/camelCase';
import { parseISO } from 'date-fns';

const initialState = {
    roles: {
        allowedUploadTypes: [],
        allowedBerryTypes: [],
        hasAccessToUpload: false,
        roleName: ''
    },
    allowedEmailNotifications: [],
    userNotifications: [],
    uploadedFiles: [],
    filesToReview: [],
    withdrawnFileData: [],
    gridEditable: false,
    userFileData: {
        FileData: []
    },
    userProducingAreas: []
};

const parseDate = (date) => {
    let dateFixed = date.toString().trim();

    //Fixing date to have a T instead of a space that are correct in some ISO 8601
    if (!dateFixed.includes('T') && dateFixed.includes(' ')) {
        dateFixed = dateFixed.replace(' ', 'T');
    }

    //All dates should be coming in as UTC (Zulu)
    if (!dateFixed.includes('Z') && dateFixed.includes('T')) {
        dateFixed += 'Z';
    }

    return parseISO(dateFixed);
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_BERRY_ROLES_SUCCESS:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    allowedBerryTypes: action.payload
                }
            };
        case SET_USER_FORECAST_ROLES_SUCCESS:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    allowedUploadTypes: action.payload.map((uploadType) => getUploadTypeByDataType(uploadType)),
                    hasAccessToUpload: action.payload.map((uploadType) => getUploadTypeByDataType(uploadType)).length ? true : false
                }
            };
        case SET_EMAIL_NOTIFICATION_ROLES:
            //From US what I got is, these are the allowed notification type for different user groups.Please correct them if I misunderstood.
            // We have groups like *_SF-* for this project

            let allowedNotificationsByRole = action.payload;
            let userGroup = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.groups.filter((group) => group.includes('_SF-'))[0];
            let userNotificationTypesByRole = allowedNotificationsByRole[camelCase(userGroup.split('_SF-')[1])];

            return {
                ...state,
                allowedEmailNotifications: userNotificationTypesByRole
            };
        case GET_USER_UPLOADED_FILES_SUCCESS:
            let filteredPayload = action.payload.sort((a, b) => {
                let aDateToFilter = a.ModifiedDateTime ? parseDate(a.ModifiedDateTime) : new Date(a.Id);
                let bDateToFilter = b.ModifiedDateTime ? parseDate(b.ModifiedDateTime) : new Date(b.Id);

                if (aDateToFilter > bDateToFilter) {
                    return -1;
                }

                if (bDateToFilter > aDateToFilter) {
                    return 1;
                }

                return 0;
            });

            return {
                ...state,
                uploadedFiles: filteredPayload
            };
        case WITHDRAW_FILE_SUCCESS:
            let updatedFileList = state.uploadedFiles.map((e) => {
                if (e.Id === action.payload.Id) {
                    e.Status = action.payload.Status;
                    e.ModifiedBy = action.payload.ModifiedBy;
                    e.ModifiedDateTime = action.payload.ModifiedDateTime;
                }
                return e;
            });

            return {
                ...state,
                uploadedFiles: updatedFileList
            };
        case SET_WITHDRAWN_FILE_DATA:
            return {
                ...state,
                withdrawnFileData: action.payload
            };
        case SET_VIEW_EDIT_FILE_DATA:
            let fileData = [];
            if (state.userFileData.FileData.length >= state.userFileData.totalRowCount) {
                fileData = [...action.payload.data];
            } else {
                fileData = [...state.userFileData.FileData, ...action.payload.data];
            }

            return {
                ...state,
                userFileData: {
                    FileData: fileData,
                    totalRowCount: action.payload.totalRowCount,
                    FileNbr: action.payload.fileId
                }
            };
        case CLEAR_VIEW_EDIT_FILE_DATA:
            return {
                ...state,
                userFileData: {
                    FileData: [],
                    totalRowCount: 0,
                    FileNbr: null
                }
            };
        case SET_GRID_EDITABLE:
            return {
                ...state,
                gridEditable: action.payload
            };
        case SET_REGIONAL_FORECASTER_PRODUCING_AREA:
            return {
                ...state,
                userProducingAreas: action.payload
            };
        case SET_USER_ROLE_NAME:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    roleName: action.payload
                }
            };
        default:
            return state;
    }
};

export default userReducer;
