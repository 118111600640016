import { Middleware } from '@driscollsinc/one-ring';
import forecastTypes from '../json/forecastTypes';

const ForecastFunctions = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('ForecastTypes')) {
        Middleware.RegisterFakeDataHandler('ForecastTypes', forecastTypes, ForecastFunctions);
    }
}

const ForecastTypesFake = {
    ConfigureFakeData
};

export default ForecastTypesFake;
