import { Middleware } from '@driscollsinc/one-ring';

const PlanningGroupMapping = [
    Middleware.CreateMapping('PlanningGroupId', 'PlanningGroupId'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('CommodityCode', 'CommodityCode'),
    Middleware.CreateMapping('PlanningGroup', 'PlanningGroup'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('PlanningGroup', 'GET')) {
        Middleware.RegisterMapping('PlanningGroup', 'GET', PlanningGroupMapping);
    }
}

const planningGroup = {
    ConfigureMiddleware
};

export default planningGroup;
