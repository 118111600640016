const userNotification = {
  Results: {
    BLUE: [
      {
        NotificationId: 1,
        Name:'UploadSucceeded',
        Description: 'File uploads successfully',
        Active: true,
      },
      {
        NotificationId: 2,
        Name:'UploadFailed',
        Description: 'File upload fails',
        Active: true,
      },
      {
        NotificationId: 3,
        Name:'FileWithdrawn',
        Description: 'I withdraw my own file',
        Active: true,
      },
      {
        NotificationId: 6,
        Name:'ReviewAccepted',
        Description: 'My file was accepted by a reviewer',
        Active: true,
      }
    ],
    BLACK: [
      {
        NotificationId: 3,
        Name:'FileWithdrawn',
        Description: 'I withdraw my own file',
        Active: true,
      },
      {
        NotificationId: 4,
        Name:'NewFileReadyForReview',
        Description: 'A new file is ready for review and I can review it',
        Active: true,
      },
      {
        NotificationId: 5,
        Name:'ReviewStarted',
        Description: 'A review has started on one of my files',
        Active: true,
      },
      {
        NotificationId: 6,
        Name:'ReviewAccepted',
        Description: 'My file was accepted by a reviewer',
        Active: true,
      },
      {
        NotificationId: 7,
        Name:'ReviewRejected',
        Description: 'My file was rejected by a reviewer',
        Active: true,
      }
    ],
    RASP:[
      {
        NotificationId: 1,
        Name:'UploadSucceeded',
        Description: 'File uploads successfully',
        Active: true,
      },
      {
        NotificationId: 2,
        Name:'UploadFailed',
        Description: 'File upload fails',
        Active: true,
      },
      {
        NotificationId: 7,
        Name:'ReviewRejected',
        Description: 'My file was rejected by a reviewer',
        Active: true,
      }
    ],
    STRAW:[
      {
        NotificationId: 1,
        Name:'UploadSucceeded',
        Description: 'File uploads successfully',
        Active: true,
      },
      {
        NotificationId: 2,
        Name:'UploadFailed',
        Description: 'File upload fails',
        Active: true,
      },
      {
        NotificationId: 3,
        Name:'FileWithdrawn',
        Description: 'I withdraw my own file',
        Active: true,
      },
      {
        NotificationId: 4,
        Name:'NewFileReadyForReview',
        Description: 'A new file is ready for review and I can review it',
        Active: true,
      },
      {
        NotificationId: 5,
        Name:'ReviewStarted',
        Description: 'A review has started on one of my files',
        Active: true,
      },
      {
        NotificationId: 6,
        Name:'ReviewAccepted',
        Description: 'My file was accepted by a reviewer',
        Active: true,
      },
      {
        NotificationId: 7,
        Name:'ReviewRejected',
        Description: 'My file was rejected by a reviewer',
        Active: true,
      }
    ],
  }
};

export default userNotification;


// {
//   'UploadSucceeded': true,
//   'UploadFailed': true,
//   'FileWithdrawn': true,
//   'ReviewStarted': true,
//   'ReviewAccepted': true,
//   'ReviewRejected': true,
//   'NewFileReadyForReview':true
// },