import customColumnName from './columnNames';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { zeroAndNullCheck } from './validationTypes';
const allowedStringValuesRegex = RegExp(/[.,?/#!$%^&*;:{}=\-_`'"~()]/);
const allowedNumberValuesRegex = RegExp(/^[0-9]+$/);
const allowedIntegerValuesRegex = RegExp(/^[-]?[0-9]*[.]?[0-9]{0,2}$/);
//NOTE: there are large values in JIRA sample excel i.e 0.000000000000125000004587928 which are getting converted to exponential i.e. 1.25000004587928e-13 and Decimal regex was failing, so I have added regex in comments to accumulated this edge case.
const allowedDecimalValuesRegex = RegExp(/^[-]?[0-9]*[.]?[0-9]*$/); //RegExp(/^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/));
const allowedAlphaNumericValuesRegex = RegExp(/[a-zA-Z0-9]+$/);
const emailRegex = RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/);
const stingWithZeroRegex = RegExp(/^0*$/);

const validationResultStatus = (index, total, errorList, timeStarted) => {
    let errorRowCount = ((errorList || []).filter((error) => error) || []).length;
    let errorTotalCount = 0;

    if (errorRowCount > 0) {
        let errorCountList = errorList.map((l) => {
            return l.errorCount;
        });
        errorTotalCount = errorCountList.reduce((a, b) => a + b, 0);
    }

    return {
        index,
        total,
        validCount: total - errorRowCount,
        errorRowCount,
        errorTotalCount,
        errorList: errorList || [],
        elapsedTime: DuDateUtilities.TimeSpanToString(timeStarted, new Date())
    };
};

const validationAddError = (errorList, index, funcName, message) => {
    if (errorList[index]) {
        errorList[index].errorCount++;
        errorList[index].errors.push({ funcName, message });
    } else {
        errorList[index] = { errorCount: 1, errors: [{ funcName, message }] };
    }
};

const validateData = (data, columns, allowedValues, uploadType, statusCallback = () => { /* code here */ }, verbose = false, statusUpdateEvery = 100) => {
    const validateRow = (index, row, columns_Item, errorList, allData, uploadType) => {
        for (let i = 0; i < columns_Item.length; i++) {
            if (columns_Item[i].key) {
                const columnName = customColumnName[columns_Item[i].key];
                const cellValue = row[columns_Item[i].excelColumnName];
                validateRowField(index, cellValue, columns_Item[i], errorList, allowedValues[columnName]);
            }
        }

        validateNoDuplicates(uploadType, index, row, allData, errorList);
    };

    if (!data || data.length <= 0 || !columns || columns.length <= 0) {
        return validationResultStatus(0, 0, [], new Date());
    }

    let startTime = new Date();
    let errorList = [];

    for (let i = 0; i < data.length; i++) {
        validateRow(i, data[i], columns, errorList, data, uploadType);

        if (i % statusUpdateEvery === 0) {
            statusCallback(validationResultStatus(i, data.length, verbose ? errorList : [], startTime));
        }
    }

    return validationResultStatus(data.length, data.length, errorList, startTime);
};

const validateRowField = (index, cellValue, column, errorList, allowedColumnValues = []) => {
    const validateAllFunctions = () => {
        for (let i = 0; i < validationFuncList.length; i++) {
            let result = validationFuncList[i](
                cellValue,
                column.label || column.excelColumnName,
                column.params || column.regex || allowedColumnValues
            );

            if (result !== null && typeof result === 'object') {
                validationAddError(errorList, index, result.funcName || 'Unknown Function', result.message || 'Unknown Error');
            }
        }
    };

    let validationFuncList = [],
        ALLOWED_IN_REQUIRED = [0, 'Null'];

    if (typeof column.validationType === 'string') {
        //validationType; regex
        //TODO: Handle this case like Maintenance Page
    } else if (typeof column.validationType === 'function') {
        validationFuncList.push(column.validationType);
        // UPDATED: chnaged typeof to instance of as typeof [] is object
    } else if (column.validationType instanceof Array && typeof column.validationType[0] === 'function') {
        validationFuncList = column.validationType;
    }

    if (column.isRequired && cellValue === '') {
        validationAddError(errorList, index, 'Required Field', `Please provide value in "${column.label || column.excelColumnName}"`);
    } else if (column.isRequired && column.notNull) {
        const result = zeroAndNullCheck(cellValue, column.label || column.excelColumnName);
        if (result !== null && typeof result === 'object')
            validationAddError(errorList, index, result.funcName || 'Unknown Function', result.message || 'Unknown Error');
        else validateAllFunctions();
    } else if (column.isRequired && !column.notNull && column.type === 'number' && cellValue !== 0) {
        if (ALLOWED_IN_REQUIRED.includes(cellValue) || isNaN(cellValue)) {
            validationAddError(errorList, index, 'NullCheck', `"${column.label || column.excelColumnName}" accepts numeric values only`);
        } else if (cellValue < 0) {
            validationAddError(errorList, index, 'Notnegative', `"${column.label || column.excelColumnName}" can not be negative`);
        } else validateAllFunctions();
    } else if (
        (column.isRequired && !column.notNull && column.type !== 'number' && !ALLOWED_IN_REQUIRED.includes(cellValue)) ||
        !(column.isRequired || ['', undefined, null, 'Null', 0].includes(cellValue))
    ) {
        /*  2 cases 
                Column is required and notNull is FALSE and field type is not number , we will validate only for values not in ALLOWED_IN_REQUIRED ) 
                Column is Optional but value is valid
        */
        validateAllFunctions();
    }
};

const validateNoDuplicates = (type, index, row, allData, errorList) => {
    let allDataReduced = [];
    let rowReduced = {};

    switch (type) {
        case 'ThirteenWeekSupplyByUnit':
            allDataReduced = [...allData];
            allDataReduced.splice(index, 1);

            allDataReduced = allDataReduced.map((data) => {
                return {
                    Brand: data.Brand,
                    Commodity: data.Commodity,
                    Model: data.Model,
                    'Planning Group': data['Planning Group'],
                    Site: data.Site,
                    Unit: data.Unit,
                    Warehouse: data.Warehouse
                };
            });

            rowReduced = {
                Brand: row.Brand,
                Commodity: row.Commodity,
                Model: row.Model,
                'Planning Group': row['Planning Group'],
                Site: row.Site,
                Unit: row.Unit,
                Warehouse: row.Warehouse
            };

            break;
        case 'ThirteenWeekSupplyByItem':
            allDataReduced = [...allData];
            allDataReduced.splice(index, 1);

            allDataReduced = allDataReduced.map((data) => {
                return {
                    Commodity: data.Commodity,
                    'Planning Group': data['Planning Group'],
                    Site: data.Site,
                    SKU: data.SKU
                };
            });

            rowReduced = {
                Commodity: row.Commodity,
                'Planning Group': row['Planning Group'],
                Site: row.Site,
                SKU: row.SKU
            };
            break;
        default:
            break;
    }

    if (allDataReduced.length > 0) {
        allDataReduced.forEach((data, searchIndex) => {
            if (shallowDuplicateCheck(rowReduced, data)) {
                validationAddError(errorList, index, 'Duplicate Found', 'Found duplicate at index ' + (searchIndex + 1));
            }
        });
    }
};

const shallowDuplicateCheck = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

const isValid = (element, type) => {
    switch (type) {
        case 'number': {
            if (!allowedNumberValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'text': {
            if (!allowedStringValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'decimal': {
            if (!allowedDecimalValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'alphaNumeric': {
            if (!allowedAlphaNumericValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'integer': {
            if (!allowedIntegerValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'email': {
            if (!emailRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'required': {
            if (element === '') {
                return false;
            } else return true;
        }
        default:
            return false;
    }
};

const validateText = (input, columnName) => {
    if (!isValid(input, 'text')) {
        return { funcName: 'Text', message: `Bad characters provided in column "${columnName}"` };
    }
    return null;
};

// UPDATED: Added new function
const validateAlphaNumeric = (input, columnName) => {
    if (!isValid(input, 'alphaNumeric')) {
        return { funcName: 'AlphaNumeric', message: `Bad characters provided in column "${columnName}"` };
    }
    return null;
};

const validateNumber = (input, columnName, params) => {
    if (!isValid(input, 'number')) {
        return { funcName: 'Number', message: `Bad number provided in column "${columnName}"` };
    }

    if (params) {
        let min = (params || {}).min || 0;
        let max = (params || {}).max || 255;

        if (input < min) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too small` };
        } else if (input > max) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too large` };
        }
    }

    return null;
};

const validateLength = (input, columnName, params) => {
    let min = (params || {}).min || 0;
    let max = (params || {}).max || 255;
    let length = (input || '').toString().length;

    if (length < min) {
        return { funcName: 'Column Length', message: `Data in column "${columnName}" is too short` };
    } else if (length > max) {
        return { funcName: 'Column Length', message: `Data in column "${columnName}" is too long` };
    }
    return null;
};

const validateDecimal = (input, columnName) => {
    if (!isValid(input, 'decimal')) {
        return { funcName: 'Decimal', message: `Bad decimal in column "${columnName}"` };
    }
    return null;
};

const validateRegex = (input, columnName, params) => {
    let regex = params || '';

    if (!regex.test(input)) {
        return { funcName: 'Regex', message: `Data in column "${columnName}" is not valid` };
    }
    return null;
};

const validateEmail = (input, columnName) => {
    if (!isValid(input, 'email')) {
        return { funcName: 'Email', message: `Bad email in column "${columnName}"` };
    }
    return null;
};

//input is in number format not date. Parsing excel return number not date.
const validateDate = (input, columnName, params = {}) => {
    let inputDate = typeof input === 'string' ? new Date(input) : isNaN(input) ? input : new Date(Math.round((input - 25568) * 86400 * 1000));

    if (!(inputDate instanceof Date)) {
        return { funcName: 'Date', message: `Bad date in column "${columnName}"` };
    }

    let minDate = params.minDate || null;
    let maxDate = params.maxDate || null;

    if (minDate !== null && inputDate < minDate) {
        return { funcName: 'Date', message: `Date in column "${columnName}" is too far in the past` };
    } else if (maxDate !== null && inputDate > maxDate) {
        return { funcName: 'Date', message: `Date in column "${columnName}" is too far in the future` };
    }
    return null;
};

const validateDataInList = (input, columnName, list = [], ALLOWED_IN_REQUIRED = []) => {
    let allowedValues = [...list, ...ALLOWED_IN_REQUIRED];
    if (!list.length) return null;
    if (stingWithZeroRegex.test(input)) input = parseInt(input);

    if (!allowedValues.includes(input)) {
        if (typeof input === 'string' && list.includes(input.toUpperCase())) {
            return { funcName: 'List', message: `"${columnName}" does not allow - '${input}'. Try uppercase.` };
        } else {
            return { funcName: 'List', message: `"${columnName}" does not allow - '${input}'` };
        }
    }
    return null;
};

// function that fixes sf-372, have not yet wired this up
const validateDataInListWithoutCase = (input, columnName, list = [], ALLOWED_IN_REQUIRED = []) => {
    let allowedValues = [...list, ...ALLOWED_IN_REQUIRED];
    if (!list.length) return null;
    if (stingWithZeroRegex.test(input)) input = parseInt(input);

    let formattedList = list.map((e) => String(e).toLowerCase());

    if (!allowedValues.includes(input)) {
        if (typeof input === 'string' && formattedList.includes(input.toLowerCase())) {
            return null;
        } else {
            return { funcName: 'List', message: `"${columnName}" does not allow - '${input}'` };
        }
    }
    return null;
};

const isRequired = (input, columnName) => {
    if (input === null || input === undefined || input === '') return { funcName: 'List', message: `Column "${columnName}" is required.` };
    return null;
};

const validateRange = (input = '', columnName, params) => {
    let inputArr = input.toString().split('-');
    for (let i = 0; i < inputArr.length; i++)
        if (!isValid(inputArr[i].trim(), 'number')) {
            return { funcName: 'Number', message: `Bad number provided in column "${columnName}"` };
        }

    if (params) {
        let min = (params || {}).min || 0;
        let max = (params || {}).max || 255;

        if (input < min) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too small` };
        } else if (input > max) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too large` };
        }
    }

    return null;
};
const DuValidationUtilities = {
    STRING_REGEXP: allowedStringValuesRegex,
    NUMBER_REGEXP: allowedNumberValuesRegex,
    INTEGER_REGEXP: allowedIntegerValuesRegex,
    DECIMAL_REGEXP: allowedDecimalValuesRegex,
    ALPHA_NUMERIC_REGEXP: allowedAlphaNumericValuesRegex,
    EMAIL_REGEXP: emailRegex,
    isValid: isValid,
    IsRequired: isRequired,
    ValidateData: validateData,
    ValidateText: validateText,
    ValidateNumber: validateNumber,
    ValidateLength: validateLength,
    ValidateDecimal: validateDecimal,
    ValidateRegex: validateRegex,
    ValidateEmail: validateEmail,
    ValidateDate: validateDate,
    ValidateDataInList: validateDataInList,
    ValidateAlphaNumeric: validateAlphaNumeric,
    ValidateRange: validateRange,
    ValidateDataInListWithoutCase: validateDataInListWithoutCase
};

export default DuValidationUtilities;
