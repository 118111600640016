import { Middleware } from '@driscollsinc/one-ring';

const forecastTypesMapping = [
    Middleware.CreateMapping('Id', 'Id'),
    Middleware.CreateMapping('Name', 'Name'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ForecastTypes', 'GET')) {
        Middleware.RegisterMapping('ForecastTypes', 'GET', forecastTypesMapping);
    }
}

const forecastType = {
    ConfigureMiddleware
};

export default forecastType;
