import { SHOW_NOTIFICATION, CLEAR_NOTIFICATION } from '../actions/NotificationAction';

const initialState = {
    message: '',
    isError: true
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                message: action.payload.notification,
                isError: action.payload.isError
            };
        case CLEAR_NOTIFICATION:
            return {
                message: '',
                isError: true
            };
        default:
            return state;
    }
};

export default notificationReducer;
