import { DATA_VALIDATOR } from '../data/dataValidationMap';
export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});

export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const setIsAdmin = (data) => ({
    type: SET_IS_ADMIN,
    payload: data
});

export const GET_MDR_COLUMN_DATA = 'GET_MDR_COLUMN_DATA';
export const setMdrConfigData = (data) => ({
    //Fetch data from API and then dispatch action
    type: GET_MDR_COLUMN_DATA,
    payload: { ...DATA_VALIDATOR, ...data } //TODO: Remove the fake data from being used
});

// this will eventually come from API. or will be already part of master data api
export const SET_UPLOAD_TYPES = 'SET_UPLOAD_TYPES';
export const setUploadTypes = (data) => ({
    //Fetch data from API and then dispatch action
    type: SET_UPLOAD_TYPES,
    payload: data
});

export const SET_COLUMN_NAMES = 'SET_COLUMN_NAMES';
export const setColumnNames = (data) => ({
    type: SET_COLUMN_NAMES,
    payload: data
});

export const SET_RANCHES = 'SET_RANCHES';
export const setRanches = (data) => ({
    type: SET_RANCHES,
    payload: data
});

export const SET_PRODUCING_AREAS = 'SET_PRODUCING_AREAS';
export const setProducingAreas = (data) => ({
    type: SET_PRODUCING_AREAS,
    payload: data
});

export const SET_VARIETY = 'SET_VARIETY';
export const setVariety = (data) => ({
    type: SET_VARIETY,
    payload: data
});

export const SET_WAREHOUSE = 'SET_WAREHOUSE';
export const setWarehouse = (data) => ({
    type: SET_WAREHOUSE,
    payload: data
});

export const SET_RECEIVING_AREA = 'SET_RECEIVING_AREA';
export const setReceivingArea = (data) => ({
    type: SET_RECEIVING_AREA,
    payload: data
});

export const SET_PLANNING_GROUP = 'SET_PLANNING_GROUP';
export const setPlanningGroup = (data) => ({
    type: SET_PLANNING_GROUP,
    payload: data
});

export const SET_BRAND = 'SET_BRAND';
export const setBrand = (data) => ({
    type: SET_BRAND,
    payload: data
});
