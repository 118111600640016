import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../actions/actions';
import { setReviewList, clearUserReviewFileData } from '../actions/FileActions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcDataGrid } from '@driscollsinc/driscolls-react-components';
import { Middleware } from '@driscollsinc/one-ring';
import { makeBerryFilterOptions, berryFilterTemplate } from '../components/makeBerryTypeCell';
import { makeForecastReviewFilter } from '../components/makeForecastReviewFilter';
import { formatFileToDisplay } from '../data/formater';
import APIEndPoints from '../services/api';
import { withOktaAuth } from '@okta/okta-react';
import { notify } from '../actions/NotificationAction';

const pageTitle = 'File Review';

const styles = (theme) => ({
    noBottom: {
        borderBottom: 'none',
        marginTop: 0
    },
    overrideDisabled: {
        '& .Mui-disabled': {
            backgroundColor: 'red !important'
        }
    },
    fileLink: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#008bc1'
    },
    gridStyles: {
        '& .p-datatable-scrollable': {
            height: 'calc(100vh - 150px) !important'
        }
    }
});

class ReviewFilesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterType: { value: 'All', label: 'All' }
        };
    }

    columns = [
        { key: 'Datatype', name: 'Forecast Type', filter: true, filterElement: 'customSelectFilter', filterProps: { placeholder: 'Forecast...' } }, //NOTE: passing filterElement: 'customSelectFilter' will by create a custom select type filter in DrcDataGrid component and we don't
        //have to each time change that component in style guide for adding new custom select filter
        { key: 'Status', name: 'File Status', filter: true, filterElement: 'select', filterProps: {} },
        {
            key: 'BerryType',
            name: 'Berry Type',
            filter: true,
            filterElement: 'berryType',
            filterProps: { placeholder: 'Berry...' }
        },
        { key: 'CreatedBy', name: 'Uploaded By', filter: true, filterElement: 'select', filterProps: {} },
        { key: 'CreatedDateTime', name: 'Uploaded On', filter: true, filterElement: 'dateRange', filterProps: {} }
    ];

    async componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        this.props.clearUserReviewFileData();
        this.getFileData();
    }

    getFileData = async () => {
        const { setReviewList } = this.props;
        let filter = {};

        try {
            let token = await this.props.authState?.accessToken;
            let data = await Middleware.Send('SFReviewFiles', token, APIEndPoints.REVIEW_FILES(), 'GET', filter, null);

            if (!data) {
                data = {};
            }

            if (!data.ReviewFilesDetails) {
                data.ReviewFilesDetails = [];
            }

            if (data.Message) {
                return this.props.notify(data.Message, false);
            }

            setReviewList(data.ReviewFilesDetails);
        } catch (error) {
            this.props.notify('Error while fetching files', true);
        }
    };

    canReview = (row) => {
        // a file can be reviewed in 2 csaes
        // 1) Its in ready for review status
        // 2) In Review by logged in user

        //As of right now the business doesn't want this restricted. One reason is what if someone starts a review and gets sick, someone else should be able to take over.
        return true;
    };

    reviewClicked = (event) => {
        let row = event.data;

        if (this.canReview(row)) {
            this.props.history.push(`/Review/Files/${row.ID}/`);
        }
    };

    makeRows = () => {
        return formatFileToDisplay(this.props.reviewFilesList, this.props.classes);
    };

    customBerryTypeFilter = (e, args) => {
        if (e.props.berrytype === args) {
            return true;
        }
        return false;
    };
    //adding filter functionality for forecastType filter
    customForecastTypeFilter = (e, args) => {
        if (e.props.children.toLowerCase() === args.toLowerCase()) {
            return true;
        }
        return false;
    };

    updateColumns = (updatedRows) => {
        if (!updatedRows.length) {
            return this.columns;
        }

        return this.columns.map((e) => {
            if (e.key === 'BerryType') {
                //update filter options for berrytype
                e.filterProps.options = makeBerryFilterOptions(updatedRows);
                e.filterProps.itemTemplate = berryFilterTemplate;
                e.filterProps.customFilterFunction = this.customBerryTypeFilter;
            } else if (e.key === 'Datatype') {
                //update filter options for forecastType
                e.filterProps.options = makeForecastReviewFilter(updatedRows);
                e.filterProps.customFilterFunction = this.customForecastTypeFilter;
            }
            return e;
        });
    };

    render() {
        let { isMasterDataInitialized, classes } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        let updatedRows = this.makeRows();
        this.columns = this.updateColumns(updatedRows);

        return (
            <DrcMain>
                <div className="row">
                    <div className="col-xs-12">
                        <DrcDataGrid
                            columns={this.columns}
                            // this is not required. user will be accepting and rejecting the files from
                            // file review page and not directly from this page
                            // contextMenu={[
                            //     { label: 'Accept', icon: 'pi pi-check', command: () => {} },
                            //     { label: 'Reject', icon: 'pi pi-times', command: () => {} }
                            // ]}
                            rows={updatedRows}
                            onRowClick={this.reviewClicked}
                            className={classes.gridStyles}
                        />
                    </div>
                </div>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        filesToReview: state.userReducer.filesToReview,
        reviewFilesList: state.FileReducer.reviewFilesList
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setReviewList: (data) => dispatch(setReviewList(data)),
    notify: (msg, isError) => dispatch(notify(msg, isError)),
    clearUserReviewFileData: () => dispatch(clearUserReviewFileData())
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReviewFilesList)));
