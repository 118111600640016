import { SET_UPLOAD_DATA } from '../actions/UploadActions';

const initialState = {
    uploadData: [],
    fileName: ''
};

const UploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPLOAD_DATA:
            return Object.assign({}, state, {
                uploadData: action.payload.data,
                fileName: action.payload.fileName
            });
        default:
            return state;
    }
};

export default UploadReducer;
