import { Middleware } from '@driscollsinc/one-ring';

const ReceivingAreaMapping = [
    Middleware.CreateMapping('ReceivingAreaName', 'ReceivingAreaName'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateMapping('ReceivingAreaId', 'ReceivingAreaId'),
    Middleware.CreateMapping('IsActive', 'IsActive'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ReceivingAreaCode', 'ReceivingAreaCode'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateArrayMapping('Data', 'Data')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('ReceivingArea', 'GET')) {
        Middleware.RegisterMapping('ReceivingArea', 'GET', ReceivingAreaMapping);
    }
}

const receivingArea = {
    ConfigureMiddleware
};

export default receivingArea;
