const forecastTypes = {
    Results: [
        {
            Id: 1,
            Name: 'AnnualBudgetVolume'
        },
        {
            Id: 2,
            Name: 'AnnualBudgetAcres'
        },
        {
            Id: 3,
            Name: 'QuarterlyReforecastVolume'
        },
        {
            Id: 4,
            Name: 'QuarterlyPlantedAcres'
        },
        {
            Id: 5,
            Name: 'ThirteenWeekSupplyByItem'
        },
        {
            Id: 6,
            Name: 'ThirteenWeekSupplyByUnit'
        },
        {
            Id: 7,
            Name: 'WeeklyRegionalForecast'
        },
        {
            Id: 8,
            Name: 'FourteenDayDailyEstimates'
        },
        {
            Id: 9,
            Name: 'WarehouseAssignment'
        },
        {
            Id: 10,
            Name: 'QuarterlyReforecastAcres'
        }
    ]
};

const FREQUENCY_ANNUAL = 'Annual';
const FREQUENCY_QUARTERLY = 'Quarterly';
const FREQUENCY_WEEKLY = 'Weekly';
const FREQUENCY_Bi_WEEKLY = '14-Day';
const FREQUENCY_THIRTEEN_WEEK = '13-Week';
const FREQUENCY_NOT_APPLICABLE = 'N/A';

const DATA_TYPE_AND_FREQUENCY_MAPPING = {
    AnnualBudgetVolume: { DataType: 'Budget Volume', Frequency: FREQUENCY_ANNUAL, DisplayName: 'Annual Budget Volume' },
    QuarterlyReforecastVolume: { DataType: 'Reforecast Volume', Frequency: FREQUENCY_QUARTERLY, DisplayName: 'Quarterly Reforecast Volume' },
    WeeklyRegionalForecast: { DataType: 'Weekly Forecast', Frequency: FREQUENCY_WEEKLY, DisplayName: 'Weekly Regional Forecast' },
    AnnualBudgetAcres: { DataType: 'Budget Acres', Frequency: FREQUENCY_ANNUAL, DisplayName: 'Annual Budget Acres' },
    QuarterlyPlantedAcres: { DataType: 'Quarterly Planted Acres', Frequency: FREQUENCY_QUARTERLY, DisplayName: 'Quarterly Planted Acres' },
    FourteenDayDailyEstimates: { DataType: '14 Day Daily Estimates', Frequency: FREQUENCY_Bi_WEEKLY, DisplayName: '14-Day Daily Estimates' },
    ThirteenWeekSupplyByItem: { DataType: '13 Week Sf By Item', Frequency: FREQUENCY_THIRTEEN_WEEK, DisplayName: '13-Week Supply Forecast By Item' },
    ThirteenWeekSupplyByUnit: { DataType: '13 Week Sf By Unit', Frequency: FREQUENCY_THIRTEEN_WEEK, DisplayName: '13-Week Supply Forecast By Unit' },
    WarehouseAssignment: { DataType: 'Warehouse Assignment', Frequency: FREQUENCY_NOT_APPLICABLE, DisplayName: 'Warehouse Assignment' },
    QuarterlyReforecastAcres: { DataType: 'Planted Reforecast Acres', Frequency: FREQUENCY_QUARTERLY, DisplayName: 'Quarterly Reforecast Acres' }
};

const getUploadTypeByDataType = (dataType) => {
    return Object.keys(DATA_TYPE_AND_FREQUENCY_MAPPING).find(
        (uploadType) => DATA_TYPE_AND_FREQUENCY_MAPPING[uploadType].DataType.toLowerCase() === dataType.toLowerCase()
    );
};

export default forecastTypes;
export { DATA_TYPE_AND_FREQUENCY_MAPPING, getUploadTypeByDataType };
