import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction, setErrorsAction, showLoadingScreenAction, hideLoadingScreenAction } from '../actions/actions';
import { setFileInReview, setReviewList, clearUserReviewFileData } from '../actions/FileActions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import {
    DrcMain,
    DrcButton,
    DrcInput,
    DrcDialog,
    DrcIconButton,
    DrcPanel,
    DrcCollapsiblePanel,
    DrcDataGrid
} from '@driscollsinc/driscolls-react-components';
import { DuExcelUtilities, DuAuthenticationUtilities, DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import { withOktaAuth } from '@okta/okta-react';
import { getUploadTypeByDataType } from '../data/json/forecastTypes';
import APIEndPoints from '../services/api';
import dateFormat from '../components/dateFormat';
import getFileDataMapping from '../data/fileDataMapping';
import { notify } from '../actions/NotificationAction';
import STATUS from '../data/fileStatuses';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import debounce from 'lodash/debounce';
import { handleFileDownload } from '../data/downloadFile';
import startCase from 'lodash/startCase';
import {
    formatFileToDisplay,
    setGridColumns,
    getAllowedFields,
    getColumnsForDownloadingFile,
    formatValuesInDownloadedFile,
    formatDbColumnNameToUiName
} from '../data/formater';
import LoadingGif from '../Images/loading.gif';

const pageTitle = 'File Review';

const styles = (theme) => ({
    roundedCorners: {
        borderRadius: '.5rem',
        marginTop: '.5rem',
        boxShadow: '8px 8px 6px -6px #777'
    },
    noBottom: {
        borderBottom: 'none',
        marginTop: 0
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flex: 1,
        marginBottom: '1rem'
    },
    actionButton: {
        width: '15rem',
        marginRight: '2rem'
    },
    gridStyles: {
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '8px 8px 6px -6px #777'
        },
        '& .p-inputtext.p-component.p-column-filter': {
            borderRadius: '4px'
        },
        '& .p-dropdown label.p-dropdown-label': {
            textOverflow: 'ellipsis',
            width: '88%'
        },
        '& .p-datatable-tbody tr td': {
            overflow: 'visible',
            whiteSpace: 'normal'
        },
        '& .p-datatable-scrollable .p-datatable-scrollable-body': {
            height: 'calc(100vh - 350px) !important'
        },
        '& .p-paginator': {
            display: 'none'
        }
    },
    fileDetails: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fileDetail: {
        display: 'flex',
        flexDirection: 'row',
        flexBasis: '30%',
        margin: '0.6rem 0.6rem 0.6rem 0'
    },
    fileDetailKey: {
        fontWeight: 'bold',
        marginRight: '.5rem'
    },
    rejectReason: {
        width: '30rem',
        height: '15rem',
        color: theme.palette.primary.light,
        '@media (prefers-color-scheme: dark)': {
            color: theme.palette.primary.light
        }
    },
    label: {
        float: 'right',
        [theme.darkTheme]: {
            color: '#aaa'
        },
        fontSize: '14px'
    }
});

class ReviewFile extends React.Component {
    state = {
        warningTitle: null,
        warningContent: null,
        showWarning: false,
        rejectReason: null,
        allowedColumns: [],
        pageSize: 200,
        pagesLoaded: [],
        fileDetails: [
            { key: 'Upload Type', value: '' },
            { key: 'BerryType', value: '' },
            { key: 'Status', value: '' },
            { key: 'ReviewedBy', value: '' },
            { key: 'Created Date', value: '' },
            { key: 'Modified Date', value: '' },
            { key: 'ReviewComment', value: '' }
        ],
        acceptingFile: false
    };

    pageLoadInprogress = false;

    async componentDidMount() {
        this.token = await this.props.authState?.accessToken;
        this.fileId = this.props.match.params.fileId;

        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        let formattedDate = [];

        if (!this.props.reviewFilesList.length) {
            let filter = {};
            try {
                let token = await this.props.authState?.accessToken;
                let data = await Middleware.Send(
                    'SFReviewFiles',
                    token,
                    APIEndPoints.REVIEW_FILES(['In Review', 'Ready For Review']), //TODO: We have these set in a single place for a reason, there were wrong.
                    'GET',
                    filter,
                    null
                );
                if (!data.ReviewFilesDetails) {
                    data.ReviewFilesDetails = [];
                }
                formattedDate = data.ReviewFilesDetails[0].CreatedDateTime.includes(':')
                    ? dateFormat(data.ReviewFilesDetails)
                    : data.ReviewFilesDetails;
                this.props.setReviewList(formattedDate);

                let file = formattedDate.find((fileItem) => fileItem.ID === +this.fileId);

                this.uploadType = getUploadTypeByDataType(file.Datatype);
                this.loadData(this.state.pageSize);
            } catch (error) {
                console.log(error);
                this.props.notify('An error occurred while fetching Files', true);
            }
        } else {
            let file = this.props.reviewFilesList.find((fileItem) => fileItem.ID === +this.fileId);

            if (this.props.reviewFilesList.length && this.props.fileInReview) {
                if (this.props.fileInReview.FileNbr) {
                    this.setFileHeaderDetails();
                }
            }

            this.uploadType = getUploadTypeByDataType(file.Datatype);
            this.loadData(this.state.pageSize);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.reviewFilesList.length && this.props.fileInReview) {
            if (this.props.fileInReview.FileNbr !== prevProps.fileInReview.FileNbr && this.props.fileInReview.FileNbr) {
                this.setFileHeaderDetails();
            }
        }
    }

    setFileHeaderDetails = () => {
        let file = this.props.reviewFilesList.find((fileItem) => fileItem.ID.toString() === this.props.fileInReview.FileNbr.toString());
        if (!file) {
            return;
        }

        let fileDetails = this.state.fileDetails.map((e) => {
            e.value = startCase((file[e.key] || '').toLowerCase());

            if (e.key === 'Upload Type') {
                e.value = startCase(getUploadTypeByDataType(file.Datatype) || file.Datatype);
            }

            if (e.key === 'ReviewedBy') {
                e.value = startCase(file.ReviewedBy);
            }

            if (e.key === 'Created Date') {
                e.value = DuDateUtilities.FormatDateFromIso(file.CreatedDateTime);
            }

            if (e.key === 'Modified Date') {
                e.value = DuDateUtilities.FormatDateFromIso(file.ModifiedDateTime);
            }

            return e;
        });

        this.setState({ fileDetails });
    };

    makeColumns() {
        // Note : This was an issue, the key names we get in data fro mDb is different from
        // what we have in column names map. we need to bring them in sync
        // On ofthe example would be db has SubmissionDay and out columnnames has SubmissionWeekendDate
        const firstRecord = this.props.fileInReview && this.props.fileInReview.FileData.length ? this.props.fileInReview.FileData[0] : null;
        if (firstRecord) {
            this.berryName = firstRecord['Commodity'];
            const uploadTypeColumnNames = this.props.columnNames[this.uploadType][this.berryName];
            const allowedFields = getAllowedFields(uploadTypeColumnNames);
            const columns = setGridColumns(allowedFields);
            this.setState({ allowedColumns: columns });
        }
    }

    loadData = async (pageSize) => {
        if (this.pageLoadInprogress) {
            return;
        }
        this.props.showLoadingScreenAction();

        this.pageLoadInprogress = true;
        let pagesLoaded = [...this.state.pagesLoaded];

        let totalPages = this.props.fileInReview.FileData.length ? Math.round(this.props.fileInReview.FileData.length / this.state.pageSize) : 0;

        let mappingName = getFileDataMapping(this.uploadType);
        try {
            let token = await this.props.authState?.accessToken;

            let numberOfCalls = Math.ceil(pageSize / this.state.pageSize);
            let allCalls = [];
            let data = {
                TotalRowCount: 0,
                Records: []
            };

            for (let i = 0; i < numberOfCalls; i++) {
                // only load pages that are not loaded yet
                if (!pagesLoaded.includes(totalPages + i + 1)) {
                    pagesLoaded.push(totalPages + i + 1);

                    allCalls.push(
                        Middleware.Send(
                            mappingName,
                            token,
                            APIEndPoints.REVIEW_FILE_BY_ID_PAGED(this.fileId, totalPages + i + 1, this.state.pageSize),
                            'GET',
                            {},
                            {
                                showLoadingScreen: () => { /* code */ },
                                hideLoadingScreen: () => { /* code */ }
                            }
                        )
                    );
                }
            }

            if (!allCalls.length) {
                this.pageLoadInprogress = false;
                this.props.hideLoadingScreenAction();
                return;
            }

            let responses = await Promise.all(allCalls);

            responses.forEach((response, indx) => {
                if (indx === 0) {
                    data = response;
                } else {
                    if (response && Array.isArray(response.Records)) {
                        data.Records = [...data.Records, ...response.Records];
                    }
                }
            });

            if (!data.Records) {
                this.pageLoadInprogress = false;
                this.props.hideLoadingScreenAction();
                return;
            }

            this.setState({ pagesLoaded });

            let commodity = data.Records[0]['Commodity'];
            if (!this.props.columnNames[this.uploadType] || !this.props.columnNames[this.uploadType][commodity]) {
                return;
            }

            let uploadTypeColumnNames = this.uploadType ? this.props.columnNames[this.uploadType][commodity] : [];
            let formattedHeaderFile = formatDbColumnNameToUiName(data.Records, uploadTypeColumnNames);
            this.props.setFileInReview(formattedHeaderFile, this.fileId, data.TotalRowCount);

            if (this.props.columnNames[this.uploadType] && data.Records.length) {
                this.makeColumns(data.Records);
            }

            this.pageLoadInprogress = false;
            this.props.hideLoadingScreenAction();
        } catch (err) {
            console.error(err);
            this.showError('Error', 'An Error Occurred');
            this.pageLoadInprogress = false;
            this.props.hideLoadingScreenAction();
        }
    };

    showError = (title, errors) => this.props.setErrorsAction(title, errors);

    handleFileReject = () => this.setState({ warningTitle: 'Reject Upload', showWarning: true });

    handleFileAccept = () =>
        this.setState({
            warningTitle: 'Accept Upload',
            showWarning: true,
            acceptingFile: true,
            warningContent: 'Are you sure you want to Accept the file'
        });

    abort = () => this.setState({ warningTitle: null, showWarning: false, rejectReason: null, acceptingFile: false, warningContent: '' });

    accept = async () => {
        let ReviewComment, Status;
        if (this.state.acceptingFile) {
            ReviewComment = '';
            Status = STATUS.approved;
        } else {
            ReviewComment = this.state.rejectReason;
            Status = STATUS.notApproved;
        }

        this.setState({ warningTitle: null, showWarning: false, warningContent: '', acceptingFile: false });

        try {
            await Middleware.Send('ReviewFile', this.token, `${APIEndPoints.APPROVE}/${this.fileId}`, 'POST', {
                FileNbr: this.fileId,
                ModifiedBy: DuAuthenticationUtilities.GetUserId(this.token),
                ReviewedBy: DuAuthenticationUtilities.GetUserId(this.token),
                ModifiedDateTime: new Date().toISOString(),
                ReviewComment,
                Status
            });
            //Redirecting to review list
            this.props.history.push('/Review/Files/');
        } catch (error) {
            console.log(error);
            this.props.notify('An error occurred while accepting/rejecting File', true);
        }
    };

    handleRejectComment = debounce((value) => {
        if (value.trim() !== this.state.rejectReason) {
            this.setState({ rejectReason: value.trim() });
        }
    }, 1000);

    handleDownload = async () => {
        let fileMetadata = this.props.reviewFilesList.find((file) => file.ID === +this.fileId);
        if (this.props.fileInReview.FileData && this.props.fileInReview.FileData.length === this.props.fileInReview.totalRowCount) {
            //just a note for now, but we probably want to reverse the mapping, so the column name in the UI would go to what we expect from the import now export. i.e. if the excel file comes with YEAR and we have Year in the UI, we would remap that to YEAR on this download as well.
            const uploadTypeColumnNames = this.props.columnNames[this.uploadType][this.berryName];
            const data = formatValuesInDownloadedFile(this.props.fileInReview.FileData);

            const file = {
                cols: getColumnsForDownloadingFile(data, uploadTypeColumnNames),
                data
            };
            DuExcelUtilities.Write(fileMetadata.Name, file.cols, file.data);
        } else {
            let token = await this.props.authState?.accessToken;
            fileMetadata['DataType'] = startCase(this.uploadType);
            // fileMetadata['Id'] = fileMetadata.ID
            await handleFileDownload(fileMetadata, token);
        }
    };

    goBack = () => {
        this.props.clearUserReviewFileData();
        this.props.history.push('/Review/Files/');
    };

    render() {
        let { isMasterDataInitialized, fileInReview, classes } = this.props;
        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        const disableConfirm = this.state.acceptingFile
            ? false
            : (this.state.rejectReason || '').length < 1 || (this.state.rejectReason || '').length > 250;
        return (
            <DrcMain>
                <div className="row">
                    <div className={`col-xs-12 ${classes.buttonContainer}`}>
                        <div className={classes.actionButton}>
                            <DrcIconButton isSecondary style={{ width: '100%' }} onClick={this.goBack} keyBinding="Shift+b">
                                <KeyboardBackspaceIcon style={{ marginRight: '1rem' }} />
                                Back
                            </DrcIconButton>
                        </div>
                        <div className={classes.actionButton}>
                            <DrcIconButton
                                style={{ width: '100%' }}
                                isPrimary
                                onClick={() => {
                                    this.handleDownload();
                                }}
                                keyBinding="Shift+d"
                                disabled={!this.props.fileInReview.FileData.length}
                            >
                                <CloudDownload style={{ marginRight: '1rem' }} />
                                Download
                            </DrcIconButton>
                        </div>
                        <div className={classes.actionButton}>
                            <DrcIconButton
                                style={{ width: '100%' }}
                                isPrimary
                                onClick={() => {
                                    this.handleFileAccept();
                                }}
                                keyBinding="Shift+a"
                                disabled={!this.props.fileInReview.FileData.length}
                            >
                                <ThumbUp style={{ marginRight: '1rem' }} />
                                Accept
                            </DrcIconButton>
                        </div>
                        <div className={classes.actionButton}>
                            <DrcIconButton
                                style={{ width: '100%' }}
                                isPrimary
                                onClick={() => {
                                    this.handleFileReject();
                                }}
                                keyBinding="Shift+r"
                                disabled={!this.props.fileInReview.FileData.length}
                            >
                                <ThumbDown style={{ marginRight: '1rem' }} />
                                Reject
                            </DrcIconButton>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <DrcCollapsiblePanel header="File Details">
                            <div className={classes.fileDetails}>
                                {this.state.fileDetails.map((e) => (
                                    <div className={classes.fileDetail} key={e.key}>
                                        <div className={classes.fileDetailKey}>{e.key}:</div>
                                        <div>{e.value}</div>
                                    </div>
                                ))}
                            </div>
                        </DrcCollapsiblePanel>
                    </div>
                    <div className="col-xs-12">
                        {this.props.fileInReview.FileData.length && this.state.allowedColumns.length ? (
                            <>
                                <DrcDataGrid
                                    rows={formatFileToDisplay(fileInReview.FileData)}
                                    columns={this.state.allowedColumns}
                                    pageSize={this.state.pageSize}
                                    totalRecords={fileInReview.totalRowCount}
                                    resultCount={fileInReview.totalRowCount}
                                    hideCount={true}
                                    loadData={this.loadData}
                                    lazy={true}
                                    paginator={true}
                                    virtualScroll={true}
                                    gridStyles={classes.gridStyles}
                                    resizableColumns={true}
                                    virtualRowHeight={70}
                                    loadingFunc={() => <img style={{ width: '100%', height: '2rem' }} src={LoadingGif} alt="Loading..." />}
                                />
                                <br />
                                <span className={`gridCount ${classes.label}`}>
                                    {fileInReview.totalRowCount > 0 ? `Total : ${fileInReview.totalRowCount}` : 'No items found'}
                                </span>
                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <DrcPanel style={{ width: '100%', maxWidth: '100%' }} className={classes.roundedCorners}>
                                    No data To Display
                                </DrcPanel>
                            </div>
                        )}
                    </div>
                </div>
                <DrcDialog
                    title={this.state.warningTitle}
                    open={this.state.showWarning}
                    fullActionWrapper={true}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton isSecondary onClick={() => this.abort()}>
                                Oops, No Thanks
                            </DrcButton>
                            <DrcButton isPrimary onClick={() => this.accept()} disabled={disableConfirm}>
                                Confirm
                            </DrcButton>
                        </div>
                    }
                >
                    {this.state.acceptingFile ? (
                        this.state.warningContent
                    ) : (
                        <DrcInput
                            label="Rejection Reason"
                            placeholder="comments"
                            error={true}
                            helperText={disableConfirm ? 'Minimum 1 and maximum 250 characters allowed!' : null}
                            className={classes.rejectReason}
                            onChange={(e) => this.handleRejectComment(e.target.value)}
                        />
                    )}
                </DrcDialog>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        fileInReview: state.FileReducer.fileInReview,
        columnNames: state.masterReducer.columnNames,
        reviewFilesList: state.FileReducer.reviewFilesList
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setFileInReview: (dataList, fileId, totalRecs) => dispatch(setFileInReview(dataList, fileId, totalRecs)),
    setReviewList: (data) => dispatch(setReviewList(data)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    clearUserReviewFileData: () => dispatch(clearUserReviewFileData()),
    showLoadingScreenAction: (data) => dispatch(showLoadingScreenAction(data)),
    hideLoadingScreenAction: (data) => dispatch(hideLoadingScreenAction(data)),
    setErrorsAction: (title, error) => dispatch(setErrorsAction(title, error))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReviewFile)));
