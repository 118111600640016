const BLACK = 1;
const BLUE = 2;
const RASP = 3;
const STRAW = 4;

// for readable routes
const CODE_TO_NAME_MAP = {
    1: 'BLACK',
    2: 'BLUE',
    3: 'RASP',
    4: 'STRAW'
};

const TYPE_TO_DISPLAY_NAME_MAP = {
    BLACK: 'Black',
    BLUE: 'Blue',
    RASP: 'Rasp',
    STRAW: 'Straw'
};

const getDisplayNameByCode = (code) => TYPE_TO_DISPLAY_NAME_MAP[CODE_TO_NAME_MAP[code]];

const getDisplayNameByType = (type) => TYPE_TO_DISPLAY_NAME_MAP[type];

const getTypeByDisplayName = (label) => {
    let type = null;

    Object.keys(TYPE_TO_DISPLAY_NAME_MAP).forEach((key) => {
        if (TYPE_TO_DISPLAY_NAME_MAP[key] === label) {
            type = key;
        }
    });

    return type;
};

const getCodeByDisplayName = (label) => {
    let code = null;
    let type = getTypeByDisplayName(label);

    Object.keys(CODE_TO_NAME_MAP).forEach((key) => {
        if (CODE_TO_NAME_MAP[key] === type) {
            code = key;
        }
    });

    return code;
};

export default {
    BLACK,
    BLUE,
    RASP,
    STRAW,
    CODE_TO_NAME_MAP,
    getDisplayNameByCode,
    getDisplayNameByType,
    getTypeByDisplayName,
    getCodeByDisplayName
};
