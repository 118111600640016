import { Middleware } from '@driscollsinc/one-ring';
import columnNames from '../json/columnNames';

const ColumnNamesHandler = { ...Middleware.CreateHandler('GET', Middleware.NoOp) };

function ConfigureFakeData() {
    if (!Middleware.CheckRegisteredFakeDataHandlers('ColumnNames')) {
        Middleware.RegisterFakeDataHandler('ColumnNames', columnNames, ColumnNamesHandler);
    }
}

const ColumnNamesFake = {
    ConfigureFakeData
};

export default ColumnNamesFake;
