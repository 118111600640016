import columnNames from './columnNames';
export const DATA_VALIDATOR = {
    [columnNames.commodity]: ['BLACK', 'BLUE', 'STRAW', 'RASP'],
    [columnNames.berryType]: ['BLACK', 'BLUE', 'STRAW', 'RASP'],
    [columnNames.fieldType]: ['CONVENTIONAL', 'ORGANIC'],
    [columnNames.estGroup]: [
        'Export',
        'ExportPF',
        'Organic',
        'OrgJumbo',
        'PROCESS',
        'RASPG',
        'RegJumbo',
        'REGULAR',
        'SEASONSFINEST',
        'STEMS',
        'SUNSHINE'
    ],
    [columnNames.treatmentType]: [
        'CUT BACK',
        'DEFOLIATION',
        'GCB I',
        'GCD II',
        'GROW THROUGH',
        'LONG CANE',
        'LOW DOWN',
        'MOW DOWN',
        'NEW PLANTING',
        'NON-HARVEST',
        'PRUNE',
        'RE-DEFOLIATION',
        'REMOVAL',
        'SECOND YEAR'
    ],
    [columnNames.media]: ['Coco Fiber', 'General Mix', 'Other', 'Soil', 'Substrate'],
    [columnNames.timing]: ['late', 'early'],
    [columnNames.ground]: ['Yes', 'No'],
    [columnNames.plantType]: [
        'Floracane',
        'Floricane',
        'Longcane',
        'Primocane',
        'Trim',
        'Green',
        'GreenMT',
        '2nd Year',
        'Frigo',
        'Frigo Frozen MT',
        'Frigo Fresh MT',
        'Trim'
    ],
    [columnNames.growingRegion]: [
        'CMEX',
        'EAST',
        'PNW',
        'BAJA',
        'CALIF',
        'CAN',
        'SOAMR'
        // "South",
        // "North",
    ]
};
