import React from 'react';
import { DrcIcons, DrcIconLabel, DrcImage } from '@driscollsinc/driscolls-react-components';
import Black_Medium_Png from '../Images/Black_Medium.png';
import Black_Medium_Webp from '../Images/Black_Medium.webp';
import Blue_Medium_Png from '../Images/Blue_Medium.png';
import Blue_Medium_Webp from '../Images/Blue_Medium.webp';
import Rasp_Medium_Png from '../Images/Rasp_Medium.png';
import Rasp_Medium_Webp from '../Images/Rasp_Medium.webp';
import Straw_Medium_Png from '../Images/Straw_Medium.png';
import Straw_Medium_Webp from '../Images/Straw_Medium.webp';

// Do Not Remove the prop berryType. its used to make filter
export const makeBerryTypeCell = (berry, index) => (
    <div key={'BerryCell-' + index} berrytype={berry}>
        <DrcImage key={'Black-' + index} alt="Black" src={Black_Medium_Png} webp={Black_Medium_Webp} style={berryIconStyle(berry, 'BLACK')} />
        <DrcImage key={'Blue-' + index} alt="Blue" src={Blue_Medium_Png} webp={Blue_Medium_Webp} style={berryIconStyle(berry, 'BLUE')} />
        <DrcImage key={'Rasp-' + index} alt="Rasp" src={Rasp_Medium_Png} webp={Rasp_Medium_Webp} style={berryIconStyle(berry, 'RASP')} />
        <DrcImage key={'Straw-' + index} alt="Straw" src={Straw_Medium_Png} webp={Straw_Medium_Webp} style={berryIconStyle(berry, 'STRAW')} />
    </div>
);

export const berryIconStyle = (rowBerry, berryIcon) => {
    if (rowBerry === berryIcon)
        return {
            height: '23px',
            opacity: 1
        };
    return {
        height: '20px',
        opacity: 0.2
    };
};

export const makeBerryFilterOptions = (rows, berryKey = 'BerryType') => {
    let optionsArray = [];

    rows.forEach((row, index) => {
        let berryType = row[berryKey].props.berrytype;
        if (!optionsArray.some((option) => option.value === berryType) && berryType !== null) {
            optionsArray.push({
                label: berryType,
                value: berryType
            });
        }
    });

    return optionsArray;
};

export const berryFilterTemplate = (option) => {
    if (!option.value) {
        return option.label;
    }

    return (
        <div key={option.value}>
            <DrcIconLabel
                key={option.value}
                style={{ display: 'flex', marginLeft: '1rem', height: '16px' }}
                icon={DrcIcons.GetSmallBerryIcon(option.value)}
            >
                {option.value}
            </DrcIconLabel>
        </div>
    );
};
