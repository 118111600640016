import { DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import APIEndPoints from '../services/api';
import DownloadUtilities from './DownloadUtilities';
import getFileDataMapping from './fileDataMapping';
import columnNames from './json/columnNames';
import { getColumnsForDownloadingFile, formatValuesInDownloadedFile, formatDbColumnNameToUiName } from './../data/formater';

export const handleFileDownload = async (fileMetadata, token) => {
    let fileType = fileMetadata.DataType.split(' ').join('');
    let berryType = fileMetadata.BerryType;
    let uploadTypeColumnNames = columnNames['Results'][fileType][berryType];

    let data = await DownloadUtilities.GetAllData(
        getFileDataMapping(fileType),
        token,
        // NOTE: we have File.Id for MyFIles and file.ID for ReviewFiles.So using this difference for calling 2 different endpoints to download
        fileMetadata.Id ? APIEndPoints.FILE_BY_ID_NO_PAGE(fileMetadata.Id) : APIEndPoints.REVIEW_FILE_BY_ID_NO_PAGE(fileMetadata.ID),
        (status) => {
            console.log(status);
        },
        'Records',
        'TotalRowCount'
    );

    data = formatValuesInDownloadedFile(data);
    let formatedHeaderFile = formatDbColumnNameToUiName(data, uploadTypeColumnNames);

    if (!data.length) {
        return this.props.notify('No Records To Download', true);
    }
    // Note: modified by and created by column values are creating issue in excel
    // as there is a \n or some other character at end of it.
    const file = {
        cols: getColumnsForDownloadingFile(formatedHeaderFile, uploadTypeColumnNames),
        data: formatedHeaderFile
    };

    DuExcelUtilities.Write(fileMetadata.Name, file.cols, file.data);
};
