import { Middleware } from '@driscollsinc/one-ring';

const sfUserRoleMapping = [
    Middleware.CreateMapping('RoleId', 'RoleId'),
    Middleware.CreateMapping('RoleName', 'RoleName'),
    Middleware.CreateMapping('OktaUserId', 'OktaUserId'),
    Middleware.CreateMapping('BLUE', 'BLUE'),
    Middleware.CreateMapping('BLACK', 'BLACK'),
    Middleware.CreateMapping('RASP', 'RASP'),
    Middleware.CreateMapping('STRAW', 'STRAW'),
    Middleware.CreateMapping('Active', 'Active'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime'),
    Middleware.CreateArrayMapping('Results', 'Results')
];

const sfUserRolePostMapping = [
    Middleware.CreateMapping('RoleId', 'RoleId'),
    Middleware.CreateMapping('Username', 'Username'),
    Middleware.CreateMapping('BLUE', 'BLUE'),
    Middleware.CreateMapping('BLACK', 'BLACK'),
    Middleware.CreateMapping('RASP', 'RASP'),
    Middleware.CreateMapping('STRAW', 'STRAW'),
    Middleware.CreateMapping('CreatedBy', 'CreatedBy'),
    Middleware.CreateMapping('CreatedDateTime', 'CreatedDateTime'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('SFUserRoleMapping', 'GET')) {
        Middleware.RegisterMapping('SFUserRoleMapping', 'GET', sfUserRoleMapping);
        Middleware.RegisterMapping('SFUserRoleMapping', 'POST', sfUserRolePostMapping);
    }
}

const sfUserRole = {
    ConfigureMiddleware
};

export default sfUserRole;
