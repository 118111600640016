import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
const dateFormat = (data) => {
    let formatedData = data.map((obj) => {
        let requestedCreatedDateTime = DuDateUtilities.FormatDateFromIso(obj.CreatedDateTime);
        obj.CreatedDateTime = requestedCreatedDateTime;
        return obj;
    });
    return formatedData;
};

export default dateFormat;
