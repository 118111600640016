import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
// this might not be required just keeping it here for now
export const USER_ROLES_TO_GROUP_MAP = {
    Admin: 'SF_Admin',
    'Master Planner': 'SF_MasterPlanner',
    'Regional Forecaster': 'SF_Regional',
    'National Forecaster': 'SF_National'
};

let tokenUsed = '';
let roleNameSaved = '';

const userRole = (token) => {
    if (tokenUsed === token) {
        return roleNameSaved;
    }

    let allGroups = DuAuthenticationUtilities.GetGroups(token) || [];

    let allAppGroups = (window.config.OKTA_ADMIN_GROUPS || []).concat(window.config.OKTA_REGULAR_GROUPS || []);

    let matchedGroups =
        allGroups.filter((group) => {
            return allAppGroups.includes(group);
        }) || [];

    let roleName = matchedGroups.find((group) => {
        return group.toLowerCase().includes('admin');
    })
        ? 'Admin'
        : matchedGroups.length > 0
        ? matchedGroups[0]
        : '';

    if (roleName.includes('-')) {
        roleName = roleName.substring(roleName.indexOf('-') + 1);

        while (roleName.includes('-')) {
            roleName = roleName.replace('-', ' ');
        }
    }

    roleNameSaved = roleName;
    tokenUsed = token;

    return roleName;
};
export default userRole;
