import { Middleware } from '@driscollsinc/one-ring';

const userNotificationMapping = [
    Middleware.CreateMapping('BLUE', 'BLUE'),
    Middleware.CreateMapping('BLACK', 'BLACK'),
    Middleware.CreateMapping('RASP', 'RASP'),
    Middleware.CreateMapping('STRAW', 'STRAW'),
    Middleware.CreateMapping('Results', 'Results')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('UserNotification', 'GET')) {
        Middleware.RegisterMapping('UserNotification', 'GET', userNotificationMapping);
    }
}

const userNotification = {
    ConfigureMiddleware
};

export default userNotification;
