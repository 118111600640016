import { Middleware } from '@driscollsinc/one-ring';

const roleForecastTypesMapping = [Middleware.CreateMapping('Results', 'Results')];
const roleForecastTypesPostMapping = [
    Middleware.CreateMapping('Datatypes', 'Datatypes'),
    Middleware.CreateMapping('Role', 'Role'),
    Middleware.CreateMapping('ModifiedBy', 'ModifiedBy'),
    Middleware.CreateMapping('ModifiedDateTime', 'ModifiedDateTime')
];

function ConfigureMiddleware() {
    if (!Middleware.CheckMappingExists('RoleForecastUploadTypes', 'GET')) {
        Middleware.RegisterMapping('RoleForecastUploadTypes', 'GET', roleForecastTypesMapping);
        Middleware.RegisterMapping('RoleForecastUploadTypes', 'POST', roleForecastTypesPostMapping);
    }
}

const roleForecastTypes = {
    ConfigureMiddleware
};

export default roleForecastTypes;
